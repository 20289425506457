import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
	children: ReactNode;
}

const TableLayout = ({ children }: Props) => (
	<Box
		sx={{
			boxShadow: '0 1px 20px 0 rgb(0 0 0 / 10%)',
			border: 'none',
			backgroundColor: '#fff',
			padding: 2,
			borderRadius: 1,
			minHeight: 'calc(100vh - 80px)',
		}}
	>
		{children}
	</Box>
);

export default TableLayout;
