import {
	AddRounded,
	EditRounded,
	GetAppRounded,
	SearchRounded,
} from '@mui/icons-material';
import {
	Box,
	Chip,
	IconButton,
	InputAdornment,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	Switch,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { GridValueGetterParams } from '@mui/x-data-grid';
import { getAllAccounts, searchBankAccount } from 'api/bankAccount';
import { getAllallPermissions, getAllCountry, getAllRoles } from 'api/seeder';
import {
	exportUser,
	getAllUsers,
	searchUser,
	toggleActiveUser,
} from 'api/users';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bankAccountSlice } from 'redux/bankAccount/bankSlice';
import { RootState } from 'redux/store';
import { clearData, emptySingleUser, usersSlice } from 'redux/users/usersSlice';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';

interface TabPanelProps {
	children: any;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ paddingTop: '30px' }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const User = () => {
	const dispatch = useDispatch();
	const allCountry = useSelector((state: RootState) => state.country.allCountry);
	const allUsers = useSelector((state: RootState) => state.users.users);
	const pagination = useSelector((state: RootState) => state.users.pagination);
	const loading = useSelector((state: RootState) => state.users.loading);
	const exportLoading = useSelector(
		(state: RootState) => state.users.exportLoading
	);
	// const [page, setPage] = useState(1);
	// const [accountPage, setAccountPage] = useState(1);
	const [query, setQuery] = useState<string>();
	const [accountQuery, setAccountQuery] = useState<string>();
	const [countryFilter, setCountryFilter] = useState('all');
	const [statusFilter, setStatusFilter] = useState(1);

	const allAccounts = useSelector(
		(state: RootState) => state.bankAccount.allAccounts
	);

	const bankLoading = useSelector(
		(state: RootState) => state.bankAccount.bankLoading
	);

	const bankPagination = useSelector(
		(state: RootState) => state.bankAccount.pagination
	);
	const currencyCountries = allCountry;

	const columns: any = [
		{
			field: 'firstName',
			headerName: 'First Name',
			width: 180,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.user?.firstName}>
					<span className='textWrap'>{params?.row?.user?.firstName}</span>
				</Tooltip>
			),
		},
		{
			field: 'lastName',
			headerName: 'Last Name',
			width: 180,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.user?.lastName}>
					<span className='textWrap'>{params?.row?.user?.lastName}</span>
				</Tooltip>
			),
		},
		{
			field: 'email',
			headerName: 'Email',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.user?.email}>
					<span className='textWrap'>{params?.row?.user?.email}</span>
				</Tooltip>
			),
		},
		{
			field: 'mobile',
			headerName: 'Mobile',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.user?.mobile}>
					<span className='textWrap'>{params?.row?.user?.mobile}</span>
				</Tooltip>
			),
		},
		{
			field: 'city',
			headerName: 'City',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.city}>
					<span className='textWrap'>{params?.row?.city}</span>
				</Tooltip>
			),
		},
		{
			field: 'branch',
			headerName: 'Branch',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.branch || '-'}>
					<span className='textWrap'>{params?.row?.branch || '-'}</span>
				</Tooltip>
			),
		},
		{
			field: 'accountName',
			headerName: 'Account Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.accountName}>
					<span className='textWrap'>{params?.row?.accountName}</span>
				</Tooltip>
			),
		},
		{
			field: 'accountNumber',
			headerName: 'Account Number',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.accountNumber}>
					<span className='textWrap'>{params?.row?.accountNumber}</span>
				</Tooltip>
			),
		},
		{
			field: 'bankName',
			headerName: 'Bank Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.bankName}>
					<span className='textWrap'>{params?.row?.bankName}</span>
				</Tooltip>
			),
		},
		{
			field: 'iban',
			headerName: 'IBan',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.iban}>
					<span className='textWrap'>{params?.row?.iban}</span>
				</Tooltip>
			),
		},
		{
			field: 'swiftCode',
			headerName: 'Swift Code',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.swiftCode}>
					<span className='textWrap'>{params?.row?.swiftCode || '-'}</span>
				</Tooltip>
			),
		},
		{
			field: 'country',
			headerName: 'Country',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={
						params.row.country.translation.filter(
							(item: any) => item.languageCode === 'en'
						)[0].name
					}
				>
					<span>
						{
							params.row.country.translation.filter(
								(item: any) => item.languageCode === 'en'
							)[0].name
						}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
	];

	const columns1: any = [
		{
			field: 'firstName',
			headerName: 'First Name',
			width: 180,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.firstName}>
					<span className='textWrap'>{params?.row?.firstName}</span>
				</Tooltip>
			),
		},
		{
			field: 'lastName',
			headerName: 'Last Name',
			width: 180,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.lastName}>
					<span className='textWrap'>{params?.row?.lastName}</span>
				</Tooltip>
			),
		},
		{
			field: 'roles',
			headerName: 'Role',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={params?.row?.roles[0]?.name ? params?.row?.roles[0]?.name : 'NA'}
				>
					<span className='textWrap'>
						{params?.row?.roles[0]?.name ? params?.row?.roles[0]?.name : 'NA'}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'email',
			headerName: 'Email',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.email}>
					<span className='textWrap'>{params?.row?.email.toLowerCase()}</span>
				</Tooltip>
			),
		},
		{
			field: 'mobile',
			headerName: 'Mobile',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.mobile}>
					<span className='textWrap'>{params?.row?.mobile.toLowerCase()}</span>
				</Tooltip>
			),
		},
		{
			field: 'address',
			headerName: 'Address',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.userAddress?.address || '-'}>
					<span className='textWrap'>
						{params?.row?.userAddress?.address || '-'}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'country',
			headerName: 'Country of residence',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={
						params?.row?.userAddress?.country?.translation?.filter(
							(item: any) => item.languageCode === 'en'
						)[0].name || '-'
					}
				>
					<span className='textWrap'>
						{params?.row?.userAddress?.country?.translation?.filter(
							(item: any) => item.languageCode === 'en'
						)[0].name || '-'}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.userAddress?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.userAddress?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.userAddress?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.userAddress?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 160,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/user-management/edit/${params?.row?.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
					<Switch
						checked={!!params.row.status}
						onChange={() => {
							const { id, status } = params.row;
							const sendStatus: number = status === 1 ? 0 : 1;
							// dispatch(toggleStatus(id));
							dispatch(
								toggleActiveUser({ id, sendStatus, statusFilter, query: query || '' })
							);
						}}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			),
		},
	];

	const debouncedFn = useCallback(
		debounce(q => {
			dispatch(searchUser({ q, status: statusFilter }));
		}, 500),
		[statusFilter]
	);

	const debouncedAccountFn = useCallback(
		debounce(q => {
			dispatch(searchBankAccount({ q }));
		}, 500),
		[]
	);

	const handleExport = () => {
		dispatch(
			exportUser({
				status: statusFilter,
				country: countryFilter === 'all' ? '' : countryFilter,
			})
		);
	};

	useEffect(() => {
		if (countryFilter === 'all') {
			dispatch(
				getAllUsers({
					page: pagination?.currentPage,
					statusFilter,
					q: query,
					perPage: pagination?.perPage,
				})
			);
		} else {
			dispatch(
				getAllUsers({
					page: pagination?.currentPage,
					statusFilter,
					q: query,
					perPage: pagination?.perPage,
					country: countryFilter,
				})
			);
		}
		dispatch(emptySingleUser());
		dispatch(clearData());
	}, [
		pagination?.currentPage,
		statusFilter,
		pagination?.perPage,
		countryFilter,
	]);

	useEffect(() => {
		dispatch(getAllAccounts({ accountPage: bankPagination?.currentPage }));
	}, [bankPagination?.currentPage]);

	useEffect(() => {
		dispatch(getAllCountry());
		dispatch(getAllRoles());
		dispatch(getAllallPermissions());
	}, []);
	// eslint-disable-next-line no-alert

	useEffect(() => {
		if (query !== undefined) {
			debouncedFn(query);
		}
	}, [query]);

	useEffect(() => {
		if (accountQuery !== undefined) {
			debouncedAccountFn(accountQuery);
		}
	}, [accountQuery]);

	const [values, setValue] = useState(0);

	const handleChange = (event: any, newValue: number) => {
		setValue(newValue);
	};

	const handleChangeCountry = (e: any) => {
		setCountryFilter(e.target.value);
	};

	return (
		<TableLayout>
			<Header title='User Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Select
							size='small'
							id='country'
							name='country'
							displayEmpty
							value={countryFilter}
							input={<OutlinedInput />}
							onChange={handleChangeCountry}
						>
							<MenuItem value='all'>All</MenuItem>
							{currencyCountries.map((each: any) => (
								<MenuItem key={each.id} value={each.id}>
									{
										each.translation.filter((item: any) => item.languageCode === 'en')[0]
											.name
									}
								</MenuItem>
							))}
						</Select>
						{values === 0 && (
							<Input
								size='small'
								fullWidth
								id='status'
								name='status'
								variant='outlined'
								select
								value={statusFilter}
								onChange={e => {
									dispatch(usersSlice.actions.setCurrentPage(1));
									const sendStatus = Number(e?.target?.value);
									setStatusFilter(sendStatus);
								}}
							>
								<MenuItem value={2}>All</MenuItem>
								<MenuItem value={1}>Active</MenuItem>
								<MenuItem value={0}>Inactive</MenuItem>
							</Input>
						)}
						<Input
							sx={{ width: '16rem' }}
							placeholder='Search user by name, email'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
							onChange={(e: any) =>
								values === 1
									? setAccountQuery(e.target.value)
									: setQuery(e.target.value)
							}
						/>
						{values !== 1 && (
							<>
								<Button
									startIcon={<GetAppRounded />}
									variant='contained'
									sx={{ width: '8rem' }}
									onClick={handleExport}
									loading={exportLoading}
								>
									Export
								</Button>

								<Link to='/user-management/add'>
									<Button startIcon={<AddRounded />} variant='contained'>
										Add
									</Button>
								</Link>
							</>
						)}
					</Stack>
				</Box>
			</Header>
			<Tabs value={values} onChange={handleChange} aria-label='basic tabs example'>
				<Tab label='User Basic Details' style={{ padding: '0px' }} />
				<Tab label='User Account Details' style={{ marginLeft: '5px' }} />
			</Tabs>

			<TabPanel index={0} value={values}>
				<Table
					rows={allUsers}
					columns={columns1}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					rowCount={pagination?.perPage}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(usersSlice.actions.setCurrentPage(1));
						dispatch(usersSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(usersSlice.actions.setCurrentPage(value))
					}
					sx={{ height: '70vh' }}
					loading={loading}
				/>
			</TabPanel>
			<TabPanel index={1} value={values}>
				<Table
					rows={allAccounts}
					columns={columns}
					count={bankPagination?.totalPages}
					page={bankPagination?.currentPage}
					rowCount={bankPagination?.perPage}
					total={bankPagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(bankAccountSlice.actions.setCurrentPage(1));
						dispatch(bankAccountSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(bankAccountSlice.actions.setCurrentPage(value))
					}
					sx={{ height: '70vh' }}
					loading={bankLoading}
				/>
			</TabPanel>
		</TableLayout>
	);
};
export default User;
