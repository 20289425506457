/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import {
	Box,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getAllBrandsWithoutPage } from 'api/brand';
import { getallCategorieswithoutPage } from 'api/categories';
import {
	AddCoupon,
	getConsumerPartnerList,
	getCouponsById,
	UpdateCoupon,
} from 'api/coupon';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearData, emptySingleCoupon } from 'redux/coupon/couponSlice';
import { RootState } from 'redux/store';
import { cashbackRewardType, rewardType } from 'utils/constant';

import FormLable from '../../../components/common/FormLable';
import styles from './index.module.scss';
import validationSchema from './validation';

const useStyles = makeStyles(() => ({
	selectedAll: {
		backgroundColor: 'rgba(186, 84, 245, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(186, 84, 245, 0.12)',
		},
	},
}));

const WithMaterialUI = () => {
	const { id } = useParams();
	const classes = useStyles();

	const [, setStartDate] = useState(new Date());
	const [, setEndDate] = useState(new Date());

	const { singleCoupon, loadingBtn } = useSelector(
		(state: RootState) => state.coupon
	);

	const [selected, setSelected] = useState<string[]>(
		singleCoupon?.id !== ''
			? singleCoupon?.country.map((each: any) => each?.id)
			: []
	);

	const allCategorieswithoutPage = useSelector(
		(state: RootState) => state.category.allCategorieswithoutPage
	);

	const { allbrandWithoutPage, loading: isBrandLoading } = useSelector(
		(state: RootState) => state.brand
	);
	const allCountry = useSelector((state: RootState) => state.country.allCountry);

	const currencyCountries =
		allCountry && allCountry.filter((item: any) => item.code !== 'WW');

	const allPartners = useSelector(
		(state: RootState) => state.partner.allpartners
	);

	const AllCountryIds = allCountry.map((item: any) => item.id);

	const dispatch = useDispatch();
	const [affiliatePartners, setAffiliatePartners] = useState<
		{
			createdAt: string;
			id: string;
			key: string;
			name: string;
			secret: string;
			status: number;
			updatedAt: string;
		}[]
	>([]);
	useEffect(() => {
		dispatch(clearData());
		getConsumerPartnerList().then(partners => {
			setAffiliatePartners(partners);
		});
	}, []);

	useEffect(() => {
		dispatch(getallCategorieswithoutPage({ page: 1 }));
	}, []);

	const formik = useFormik({
		initialValues: {
			// title:
			// 	singleCoupon?.id !== '' ? singleCoupon?.translation?.en[0]?.title : '',
			description:
				singleCoupon?.id !== ''
					? singleCoupon?.translation?.en[0]?.description
					: '',
			description_ara:
				singleCoupon?.id !== ''
					? singleCoupon?.translation?.ara[0]?.description
					: '',
			text: singleCoupon?.id !== '' ? singleCoupon?.translation?.en[0]?.text : '',
			text_ara:
				singleCoupon?.id !== '' ? singleCoupon?.translation?.ara[0]?.text : '',
			title_ara:
				singleCoupon?.id !== '' ? singleCoupon?.translation?.ara[0]?.title : '',

			category: singleCoupon?.id
				? singleCoupon?.category
						?.filter((item: any) => item.status === 1)
						.map((each: any) => each?.id)
				: [],
			brand: singleCoupon?.id ? singleCoupon?.brand?.id : '',
			partnerType: singleCoupon?.id !== '' ? singleCoupon?.partnerType : 1,
			partner: singleCoupon?.id !== '' ? singleCoupon?.partner?.id : '',
			status: singleCoupon?.id !== '' ? (singleCoupon?.status === 1 ? 1 : 0) : 0,
			discount_amount: singleCoupon?.id !== '' ? singleCoupon?.rewardValue : '',
			discount_type: singleCoupon?.id !== '' ? singleCoupon?.rewardType : 1,
			couponType: singleCoupon?.id !== '' ? singleCoupon?.couponType : 1,
			country:
				singleCoupon?.id !== ''
					? singleCoupon?.country.map((each: any) => each?.id)
					: [],
			end_date:
				singleCoupon?.id !== '' ? moment(singleCoupon?.expiryDate).toDate() : '',
			start_date:
				singleCoupon?.startDate && singleCoupon?.startDate
					? moment(singleCoupon?.startDate).toDate()
					: '',
			coupon_code:
				singleCoupon?.id === '' || Number(singleCoupon?.couponType) === 3
					? ''
					: singleCoupon?.couponCode,
			website: singleCoupon?.id !== '' ? singleCoupon?.link : '',
			seoTitle: singleCoupon ? singleCoupon?.seoTitle : '',
			seoMetas: singleCoupon ? singleCoupon?.seoMetas : '',
			seoTitleAra: singleCoupon ? singleCoupon?.seoTitle : '',
			seoMetasAra: singleCoupon ? singleCoupon?.seoMetas : '',
			// text: singleCoupon ? singleCoupon.text : '',
			consumerPartnerId: singleCoupon?.consumerPartner
				? singleCoupon?.consumerPartner?.id
				: '',
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: values => {
			const backendObject = {
				couponCode: values.coupon_code.trim(),
				link: values.website,
				rewardType: values.discount_type,
				rewardValue: values.discount_amount || null,
				brandId: values.brand,
				expiryDate: moment(values.end_date).format('YYYY-MM-DDTHH:mm:ss'),
				startDate: moment(values.start_date).format('YYYY-MM-DDTHH:mm:ss'),
				status: values.status,
				partnerType: values.partnerType,
				seoTitle: values?.seoTitle,
				seoMetas: values?.seoMetas,
				partner:
					values.partnerType === 0
						? allPartners?.find((partner: any) => partner.name === 'Direct').id
						: values?.partner, // uuid
				translation: [
					{
						languageCode: 'en',
						// title: values.title,
						description: values.description,
						text: values.text || null,
					},
					{
						languageCode: 'ara',
						// title: values.title_ara,
						description: values.description_ara,
						text: values.text_ara || null,
					},
				],
				categories: values.category,
				countries: values.country,
				couponType: values.couponType,
				consumerPartnerId: values?.consumerPartnerId,
			};

			if (id) {
				dispatch(UpdateCoupon({ backendObject, id }));
			} else {
				dispatch(AddCoupon(backendObject));
			}
		},
	});

	useEffect(() => {
		if (id && singleCoupon?.id) {
			const countries = singleCoupon?.country.map((item: any) => item.id);
			dispatch(
				getAllBrandsWithoutPage({
					page: 1,
					countries,
					addCoupon: true,
				})
			);

			// formik.setFieldValue('brand', singleCoupon?.brand?.id);
		}
	}, [singleCoupon?.id, id]);

	const handleChange = (event: any) => {
		const { value } = event.target;

		if (value[value.length - 1] === 'all') {
			formik.setFieldValue(
				'country',
				selected.length === allCountry.length ? [] : AllCountryIds
			);
			setSelected(selected.length === allCountry.length ? [] : AllCountryIds);
			return;
		}
		setSelected(value);
		formik.setFieldValue('country', value);
	};

	const isAllSelected =
		allCountry.length > 0 && selected.length === allCountry.length;

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='English' required={false} />
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Arabic' required={false} textAlign='right' />
					</Grid>
				</Grid>
				<Grid container rowSpacing={4} columnSpacing={3}>
					{/* <Grid item xs={6} className='my-grid'>
				
						<FormLable label='Title' />
						<Input
							type='text'
							fullWidth
							id='title'
							name='title'
							value={formik.values.title}
							onChange={formik.handleChange}
							error={formik.touched.title && Boolean(formik.errors.title)}
							helperText={formik.touched.title && formik.errors.title}
						/>
					</Grid> */}
					{/* 
					<Grid item xs={6} className='my-grid'>
						<Input
							fullWidth
							id='title_ara'
							name='title_ara'
							type='text'
							dir='rtl'
							value={formik.values.title_ara}
							onChange={formik.handleChange}
							error={formik.touched.title_ara && Boolean(formik.errors.title_ara)}
							helperText={formik.touched.title_ara && formik.errors.title_ara}
						/>
					</Grid> */}

					<Grid item xs={6} className='my-grid'>
						<FormLable label='Coupon Type' />
						{/* English version field */}

						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.couponType && Boolean(formik.errors.couponType)}
						>
							<Select
								size='small'
								fullWidth
								id='couponType'
								name='couponType'
								displayEmpty
								value={formik.values.couponType}
								input={<OutlinedInput />}
								onChange={e => {
									formik.handleChange(e);
								}}
								error={formik.touched.couponType && Boolean(formik.errors.couponType)}
							>
								<MenuItem selected={formik.values.couponType === 1} value={1}>
									Cashback
								</MenuItem>
								<MenuItem selected={formik.values.couponType === 2} value={2}>
									Coupon
								</MenuItem>
								<MenuItem selected={formik.values.couponType === 3} value={3}>
									Offer
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.couponType && formik.errors.couponType}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6} className='my-grid'>
						{/* Arabic version field */}
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.couponType && Boolean(formik.errors.couponType)}
						>
							<Select
								size='small'
								fullWidth
								id='couponType'
								name='couponType'
								dir='rtl'
								displayEmpty
								value={formik.values.couponType}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.couponType && Boolean(formik.errors.couponType)}
							>
								{/* <MenuItem selected={formik.values.couponType === 1} value={1}>
									كاش باك
								</MenuItem>
								<MenuItem selected={formik.values.couponType === 2} value={2}>
									كوبون
								</MenuItem>
								<MenuItem selected={formik.values.couponType === 3} value={3}>
									بروموشن
								</MenuItem> */}
								<MenuItem selected={formik.values.couponType === 1} value={1}>
									Cashback
								</MenuItem>
								<MenuItem selected={formik.values.couponType === 2} value={2}>
									Coupon
								</MenuItem>
								<MenuItem selected={formik.values.couponType === 3} value={3}>
									Offer
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.couponType && formik.errors.couponType}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6} className='my-grid'>
						<FormLable label='Discount type' />
						<FormControl
							sx={{ width: '100%' }}
							error={
								formik.touched.discount_type && Boolean(formik.errors.discount_type)
							}
						>
							<Select
								size='small'
								fullWidth
								id='discount_type'
								name='discount_type'
								displayEmpty
								value={formik.values.discount_type}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={
									formik.touched.discount_type && Boolean(formik.errors.discount_type)
								}
							>
								{formik.values.couponType === 1
									? cashbackRewardType.map((each, i) => (
											<MenuItem
												key={i}
												// eslint-disable-next-line no-unneeded-ternary
												selected={formik.values.discount_type === i + 1 ? true : false}
												value={i + 1}
											>
												{each}
											</MenuItem>
									  ))
									: rewardType.map((each, i) => (
											<MenuItem
												key={i}
												// eslint-disable-next-line no-unneeded-ternary
												selected={formik.values.discount_type === i + 1 ? true : false}
												value={i + 1}
											>
												{each}
											</MenuItem>
									  ))}
							</Select>
							<FormHelperText>
								{formik.touched.discount_type ? formik.errors.discount_type : ''}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						<FormControl
							sx={{ width: '100%' }}
							error={
								formik.touched.discount_type && Boolean(formik.errors.discount_type)
							}
						>
							<Select
								size='small'
								fullWidth
								dir='rtl'
								id='discount_type'
								name='discount_type'
								displayEmpty
								value={formik.values.discount_type}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={
									formik.touched.discount_type && Boolean(formik.errors.discount_type)
								}
							>
								{formik.values.couponType === 1
									? cashbackRewardType.map((each, i) => (
											<MenuItem
												key={i}
												// eslint-disable-next-line no-unneeded-ternary
												selected={formik.values.discount_type === i + 1 ? true : false}
												value={i + 1}
											>
												{each}
											</MenuItem>
									  ))
									: rewardType.map((each, i) => (
											<MenuItem
												key={i}
												// eslint-disable-next-line no-unneeded-ternary
												selected={formik.values.discount_type === i + 1 ? true : false}
												value={i + 1}
											>
												{each}
											</MenuItem>
									  ))}
							</Select>
							<FormHelperText>
								{formik.touched.discount_type && formik.errors.discount_type}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6} className='my-grid'>
						<FormLable label='Country' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.country && Boolean(formik.errors.country)}
						>
							<Select
								size='small'
								fullWidth
								id='country'
								name='country'
								displayEmpty
								multiple
								value={selected}
								input={<OutlinedInput />}
								onChange={e => {
									handleChange(e);
									let countries;
									if (
										e.target.value.length === 1 &&
										e.target.value[e.target.value.length - 1] === 'all'
									) {
										countries = AllCountryIds;
									} else if (
										e.target.value.length > allCountry.length - 1 &&
										e.target.value[e.target.value.length - 1] === 'all'
									) {
										countries = [];
									} else if (
										e.target.value.length > 1 &&
										e.target.value[e.target.value.length - 1] === 'all'
									) {
										countries = AllCountryIds;
									} else {
										countries = e.target.value;
									}
									dispatch(
										getAllBrandsWithoutPage({
											page: 1,
											countries,
											addCoupon: true,
										})
									);
								}}
								error={formik.touched.country && Boolean(formik.errors.country)}
							>
								{formik.values.discount_type !== 1 && (
									<MenuItem
										key='all'
										value='all'
										classes={{
											root: isAllSelected ? classes.selectedAll : '',
										}}
									>
										Select All
									</MenuItem>
								)}

								{formik.values.discount_type !== 1
									? allCountry.map((each: any) => (
											<MenuItem key={each.id} value={each.id}>
												{
													each.translation.filter(
														(item: any) => item.languageCode === 'en'
													)[0].name
												}
											</MenuItem>
									  ))
									: currencyCountries.map((each: any) => (
											<MenuItem key={each.id} value={each.id}>
												{
													each.translation.filter(
														(item: any) => item.languageCode === 'en'
													)[0].name
												}
											</MenuItem>
									  ))}
							</Select>
							<FormHelperText>
								{formik.touched.country && formik.errors.country}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6} className='my-grid'>
						{/* Arabic version field */}

						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.country && Boolean(formik.errors.country)}
						>
							<Select
								size='small'
								fullWidth
								multiple
								dir='rtl'
								id='country'
								name='country'
								displayEmpty
								value={selected}
								input={<OutlinedInput />}
								onChange={e => {
									handleChange(e);
									let countries;
									if (
										e.target.value.length === 1 &&
										e.target.value[e.target.value.length - 1] === 'all'
									) {
										countries = AllCountryIds;
									} else if (
										e.target.value.length > allCountry.length - 1 &&
										e.target.value[e.target.value.length - 1] === 'all'
									) {
										countries = [];
									} else if (
										e.target.value.length > 1 &&
										e.target.value[e.target.value.length - 1] === 'all'
									) {
										countries = AllCountryIds;
									} else {
										countries = e.target.value;
									}
									dispatch(
										getAllBrandsWithoutPage({
											page: 1,
											countries,
											addCoupon: true,
										})
									);
								}}
								error={formik.touched.country && Boolean(formik.errors.country)}
							>
								{formik.values.discount_type !== 1 && (
									<MenuItem
										key='all'
										value='all'
										classes={{
											root: isAllSelected ? classes.selectedAll : '',
										}}
									>
										Select All
									</MenuItem>
								)}
								{formik.values.discount_type === 1
									? currencyCountries.map((each: any) => (
											<MenuItem key={each.id} value={each.id}>
												{
													each.translation.filter(
														(item: any) => item.languageCode === 'en'
													)[0].name
												}
											</MenuItem>
									  ))
									: allCountry.map((each: any) => (
											<MenuItem key={each.id} value={each.id}>
												{
													each.translation.filter(
														(item: any) => item.languageCode === 'en'
													)[0].name
												}
											</MenuItem>
									  ))}
							</Select>
							<FormHelperText>
								{formik.touched.country && formik.errors.country}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						<FormLable label='Brand' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.brand && Boolean(formik.errors.brand)}
						>
							<Select
								size='small'
								fullWidth
								id='brand'
								name='brand'
								displayEmpty
								value={formik.values.brand}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.brand && Boolean(formik.errors.brand)}
							>
								{selected.length === 0 ? (
									<p style={{ textAlign: 'center' }}>Please select a country first.</p>
								) : isBrandLoading ? (
									<Box sx={{ display: 'grid', placeItems: 'center' }}>
										<Loader />
									</Box>
								) : (
									allbrandWithoutPage &&
									allbrandWithoutPage
										?.filter((category: any) => category.status === 1)
										?.map((each: any) => (
											<MenuItem key={each?.id} value={each?.id}>
												{each?.brandTranslation?.en[0]?.name}
											</MenuItem>
										))
								)}
							</Select>
							<FormHelperText>
								{formik.touched.brand && formik.errors.brand}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						{/* Arabic version field */}

						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.brand && Boolean(formik.errors.brand)}
						>
							<Select
								size='small'
								fullWidth
								dir='rtl'
								id='brand'
								name='brand'
								displayEmpty
								value={formik.values.brand}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.brand && Boolean(formik.errors.brand)}
							>
								{selected.length === 0 ? (
									<p style={{ textAlign: 'center' }}>Please select a country first.</p>
								) : isBrandLoading ? (
									<Box sx={{ display: 'grid', placeItems: 'center' }}>
										<Loader />
									</Box>
								) : (
									allbrandWithoutPage
										?.filter((category: any) => category.status === 1)
										?.map((each: any) => (
											<MenuItem key={each?.id} value={each?.id}>
												{each?.brandTranslation?.en[0]?.name}
											</MenuItem>
										))
								)}
							</Select>
							<FormHelperText>
								{formik.touched.brand && formik.errors.brand}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						<FormLable label='Category' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.category && Boolean(formik.errors.category)}
						>
							<Select
								size='small'
								fullWidth
								id='category'
								name='category'
								displayEmpty
								multiple
								value={formik.values.category}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.category && Boolean(formik.errors.category)}
							>
								{allCategorieswithoutPage
									?.filter((category: any) => category.status === 1)
									?.map((each: any) => (
										<MenuItem key={each?.id} value={each?.id}>
											{each?.translation?.en[0]?.name}
										</MenuItem>
									))}
							</Select>
							<FormHelperText>
								{formik.touched.category && formik.errors.category}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6} className='my-grid'>
						{/* Arabic version field */}

						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.category && Boolean(formik.errors.category)}
						>
							<Select
								size='small'
								fullWidth
								multiple
								dir='rtl'
								id='category'
								name='category'
								displayEmpty
								value={formik.values.category}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.category && Boolean(formik.errors.category)}
							>
								{allCategorieswithoutPage
									?.filter((category: any) => category.status === 1)
									?.map((each: any) => (
										<MenuItem key={each?.id} value={each?.id}>
											{each?.translation?.en[0]?.name}
										</MenuItem>
									))}
							</Select>
							<FormHelperText>
								{formik.touched.category && formik.errors.category}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
						className='my-grid'
						style={{
							display: formik.values.couponType === 3 ? 'none' : '',
						}}
					>
						{/* English version field */}
						<FormLable label='Coupon code' />

						<Input
							fullWidth
							id='coupon_code'
							name='coupon_code'
							value={formik.values.coupon_code}
							onChange={e =>
								formik.setFieldValue('coupon_code', e.target.value.replace(/ /g, ''))
							}
							error={formik.touched.coupon_code && Boolean(formik.errors.coupon_code)}
							helperText={formik.touched.coupon_code && formik.errors.coupon_code}
						/>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						<FormLable label='Partner' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.partnerType && Boolean(formik.errors.partnerType)}
						>
							<Select
								size='small'
								fullWidth
								id='partnerType'
								name='partnerType'
								displayEmpty
								value={formik.values.partnerType}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.partnerType && Boolean(formik.errors.partnerType)}
							>
								<MenuItem value={1}>Affiliate</MenuItem>
								<MenuItem value={0}>Direct</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.partnerType && formik.errors.partnerType}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						{/* Arabic version field */}

						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.partnerType && Boolean(formik.errors.partnerType)}
						>
							<Select
								size='small'
								fullWidth
								dir='rtl'
								id='partnerType'
								name='partnerType'
								displayEmpty
								value={formik.values.partnerType}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.partnerType && Boolean(formik.errors.partnerType)}
							>
								<MenuItem value={1}>Affiliate</MenuItem>
								<MenuItem value={0}>Direct</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.partnerType && formik.errors.partnerType}
							</FormHelperText>
						</FormControl>
					</Grid>

					{formik.values.partnerType === 1 && (
						<Grid item xs={12} className='my-grid'>
							<FormLable label='Affiliate Partner' />
							<FormControl
								sx={{ width: '100%' }}
								error={formik.touched.partner && Boolean(formik.errors.partner)}
							>
								<Select
									size='small'
									fullWidth
									id='partner'
									name='partner'
									displayEmpty
									value={formik.values.partner}
									input={<OutlinedInput />}
									onChange={formik.handleChange}
									error={formik.touched.partner && Boolean(formik.errors.partner)}
								>
									{allPartners
										?.filter((partner: any) => partner.status === 1)
										?.map((each: any) => (
											<MenuItem value={each?.id}>{each.name}</MenuItem>
										))}
								</Select>
								<FormHelperText>
									{formik.touched.partner && formik.errors.partner}
								</FormHelperText>
							</FormControl>
						</Grid>
					)}

					<Grid
						item
						xs={12}
						className='my-grid'
						style={{
							display: formik.values.discount_type === 3 ? 'none' : 'inherit',
						}}
					>
						<FormLable label='Discount amount' />
						{/* English version field */}
						<Input
							type='number'
							fullWidth
							id='discount_amount'
							name='discount_amount'
							value={formik.values.discount_amount}
							onChange={formik.handleChange}
							error={
								formik.touched.discount_amount && Boolean(formik.errors.discount_amount)
							}
							helperText={
								formik.touched.discount_amount && formik.errors.discount_amount
							}
						/>
					</Grid>

					{formik.values.discount_type === 3 && (
						<>
							<Grid item xs={6} className='my-grid'>
								{/* English version field */}
								<FormLable label='Text' required={false} />
								<Input
									type='text'
									multiline
									fullWidth
									id='text'
									name='text'
									value={formik.values.text}
									onChange={formik.handleChange}
									error={formik.touched.text && Boolean(formik.errors.text)}
									helperText={formik.touched.text && formik.errors.text}
								/>
							</Grid>
							<Grid item xs={6} className='my-grid'>
								{/* English version field */}
								<FormLable label='' required={false} />
								<Input
									type='text'
									multiline
									fullWidth
									id='text_ara'
									name='text_ara'
									value={formik.values.text_ara}
									onChange={formik.handleChange}
									error={formik.touched.text_ara && Boolean(formik.errors.text_ara)}
									helperText={formik.touched.text_ara && formik.errors.text_ara}
								/>
							</Grid>
						</>
					)}

					<Grid item xs={6} className='my-grid'>
						{/* English version field */}
						<FormLable label='Description' />
						<Input
							type='text'
							multiline
							fullWidth
							id='description'
							name='description'
							value={formik.values.description}
							onChange={formik.handleChange}
							error={formik.touched.description && Boolean(formik.errors.description)}
							helperText={formik.touched.description && formik.errors.description}
						/>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						{/* Arabic version field */}

						<Input
							fullWidth
							id='description_ara'
							name='description_ara'
							type='text'
							multiline
							dir='rtl'
							value={formik.values.description_ara}
							onChange={formik.handleChange}
							error={
								formik.touched.description_ara && Boolean(formik.errors.description_ara)
							}
							helperText={
								formik.touched.description_ara && formik.errors.description_ara
							}
							className='arabic-error'
						/>
					</Grid>

					<Grid item xs={12} className='my-grid'>
						<FormLable label='Status' />
						{/* English version field */}

						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								size='small'
								fullWidth
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem selected={formik.values.status === 1} value={1}>
									Active
								</MenuItem>
								<MenuItem selected={formik.values.status === 0} value={0}>
									Inactive
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						<FormLable label='Start Date' />
						{/* English version field */}
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.start_date && Boolean(formik.errors.start_date)}
						>
							<DatePicker
								minDate={moment().toDate()}
								className={styles.datePicker}
								selected={
									(formik.values.start_date && new Date(formik.values.start_date)) ||
									null
								}
								onChange={(val: any) => {
									setStartDate(val);
									formik.setFieldValue('start_date', val);
								}}
								value={
									formik.values.start_date
										? moment(formik.values.start_date).format('YYYY-MM-DD hh:mm A')
										: ''
								}
								showTimeSelect
								timeIntervals={10}
								timeFormat='p'
								dateFormat='Pp'
							/>
							<FormHelperText>
								{formik.touched.start_date && formik.errors.start_date}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						<FormLable label='End Date' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.end_date && Boolean(formik.errors.end_date)}
						>
							<DatePicker
								minDate={moment().toDate()}
								selected={
									(formik.values.end_date && new Date(formik.values.end_date)) || null
								}
								className={styles.datePicker}
								onChange={(val: any) => {
									setEndDate(val);
									formik.setFieldValue('end_date', val);
								}}
								value={
									formik.values.end_date
										? moment(formik.values.end_date).format('YYYY-MM-DD hh:mm A')
										: ''
								}
								showTimeSelect
								timeIntervals={10}
								timeFormat='p'
								dateFormat='Pp'
							/>
							<FormHelperText>
								{formik.touched.end_date && formik.errors.end_date}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid item xs={12} className='my-grid'>
						{/* English version field */}
						<FormLable label='Website link' />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='website'
							name='website'
							value={formik.values.website}
							onChange={formik.handleChange}
							error={formik.touched.website && Boolean(formik.errors.website)}
							helperText={formik.touched.website && formik.errors.website}
						/>
					</Grid>

					{formik.values.couponType === 2 && (
						<Grid
							container
							spacing={1}
							sx={{
								marginLeft: '1rem',
							}}
						>
							<Grid item xs={12} className='my-grid'>
								<FormLable label='Qyubic Affiliate Partner' required={false} />
							</Grid>
							<Grid item xs={10} className='my-grid'>
								<Select
									size='small'
									fullWidth
									id='consumerPartnerId'
									name='consumerPartnerId'
									displayEmpty
									value={formik.values.consumerPartnerId}
									input={<OutlinedInput />}
									onChange={formik.handleChange}
									// error={formik.touched.status && Boolean(formik.errors.status)}
								>
									{affiliatePartners?.map(partner => (
										<MenuItem
											key={partner.id}
											selected={formik.values.consumerPartnerId === partner.id}
											value={partner.id}
										>
											{partner.name}
										</MenuItem>
									))}
								</Select>
							</Grid>
							<Grid item xs={2}>
								<Button
									color='primary'
									variant='contained'
									type='button'
									// sx={{ marginLeft: '8px' }}
									onClick={() => formik.setFieldValue('consumerPartnerId', null)}
								>
									Remove
								</Button>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Box>

			<Button
				sx={{ marginTop: '3rem', marginBottom: '1rem' }}
				color='primary'
				variant='contained'
				fullWidth
				type='submit'
				loading={loadingBtn}
			>
				Submit
			</Button>
		</form>
	);
};

const CouponAdd = () => {
	const dispatch = useDispatch();
	const { id, affliateId } = useParams();
	const navigate = useNavigate();

	const loading = useSelector((state: RootState) => state.coupon.loading);

	const isAdded = useSelector((state: RootState) => state.coupon.isAdded);
	const isUpdated = useSelector((state: RootState) => state.coupon.isUpdated);
	useEffect(() => {
		if (id) {
			dispatch(getCouponsById(id));
		}
		return () => {
			dispatch(emptySingleCoupon());
		};
	}, [id]);

	useEffect(() => {
		if (isAdded || isUpdated) {
			if (affliateId) {
				navigate(`/qyubic-affiliate-management/edit/${affliateId}`);
			} else {
				navigate('coupon-management');
			}
		}
	}, [isAdded, isUpdated]);

	return (
		<TableLayout>
			<Container maxWidth='lg' className='addScroll'>
				<Header title={id ? 'Edit Coupon' : 'Add Coupon'} />
				<Box>
					{loading ? (
						<Box
							sx={{
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'absolute',
								backgroundColor: 'white',
								opacity: '0.7',
								zIndex: '999',
								minHeight: 'calc(100vh - 80px)',
							}}
						>
							<Loader />
						</Box>
					) : (
						<WithMaterialUI />
					)}
				</Box>
			</Container>
		</TableLayout>
	);
};
export default CouponAdd;
