/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
import { EditRounded } from '@mui/icons-material';
import {
	Chip,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
	AddConsumerPartner,
	getConsumerPartnerById,
	getConsumerPartnerCouponsById,
	UpdateConsumerPartner,
	UpdateConsumerPartnerSecret
} from 'api/consumerPartner';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
	clearData,
	consumerPartnersSlice
} from 'redux/consumerPartner/consumerPartnerSlice';
import { RootState } from 'redux/store';
import getArabicTime from 'utils/dateConverter';
import { allValues, isEmpty, usePrompt } from 'utils/propts';

import FormLable from '../../../components/common/FormLable';
import validationSchema from './validation';

const WithMaterialUI = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const {
		singleConsumerPartner,
		loadingBtn,
		partnerCoupons,
		partnerCouponsLoading,
		partnerCouponsPagination
	} = useSelector((state: RootState) => state.consumerPartners);
	const [viewPrivateKey, setViewPrivateKey] = useState(false);
	const handleCopyKey = (type: 'public' | 'private') => {
		let key: string | undefined;
		if (type === 'private' && singleConsumerPartner) {
			key = singleConsumerPartner.secret;
		} else {
			key = singleConsumerPartner.key;
		}
		if (key) {
			window.navigator.clipboard.writeText(key);
			toast.success(`${type} Key copied successfully`);
		} else {
			toast.error(`${type} Key not found.Generate new key`);
		}
	};

	const handleGenerateSecret = () => {
		dispatch(UpdateConsumerPartnerSecret(id));
	};

	const formik = useFormik({
		initialValues: {
			name: id && singleConsumerPartner ? singleConsumerPartner.name : '',
			status: id && singleConsumerPartner ? singleConsumerPartner.status : 0,
			key: id && singleConsumerPartner ? singleConsumerPartner.key : '',
			secret: id && singleConsumerPartner ? singleConsumerPartner.secret : ''
		},
		validationSchema,
		onSubmit: values => {
			if (id) {
				const data = values;
				dispatch(UpdateConsumerPartner({ data, id }));
			} else {
				dispatch(AddConsumerPartner({ name: values.name, status: values.status }));
			}
		}
	});
	const [leavePage, setLeavePage] = useState(false);

	useEffect(() => {
		allValues(formik.values)
			? setLeavePage(false)
			: setLeavePage(!isEmpty(formik.values));
	}, [formik.values]);

	usePrompt(leavePage);

	const couponTable: GridColDef[] = [
		{
			field: 'brandName',
			headerName: 'Brand Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.brand?.brandTranslation[0]?.name}>
					<span className='textWrap'>
						{params?.row?.brand?.brandTranslation[0]?.name}
					</span>
				</Tooltip>
			)
		},
		{
			field: 'brandImage',
			headerName: 'Brand Image',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={`${params?.row?.brand?.brandTranslation[0]?.imageSlug?.path}`}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'contain',
							padding: '0.5rem'
						}}
					/>
				</Box>
			)
		},
		{
			field: 'link',
			headerName: 'Website Link',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.link || 'N/A'}>
					<a
						className='textWrap'
						href={params?.row?.link}
						target='_blank'
						rel='noreferrer'
					>
						{params?.row?.link || 'N/A'}
					</a>
				</Tooltip>
			)
		},
		{
			field: 'startDate',
			headerName: 'Start Date',
			width: 200,
			valueGetter: (params: GridValueGetterParams) => `
			${getArabicTime(params?.row?.startDate, 'date')}`
		},
		{
			field: 'expiryDate',
			headerName: 'End Date',
			width: 200,
			valueGetter: (params: GridValueGetterParams) => `
			${getArabicTime(params?.row?.expiryDate, 'date')}`
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			)
		},
		{
			field: 'action',
			headerName: 'Action',
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/coupon-management/edit/${'values'}/${params?.row?.id}/${id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
				</Stack>
			)
		}
	];
	return (
		<Box>
			<form onSubmit={formik.handleSubmit}>
				<Box>
					<FormLable label='Qyubic Affiliate Name' />
					<Grid container spacing={1}>
						<Grid item xs={12} sx={{ marginBottom: '1rem' }}>
							{/* English version field */}
							<Input
								fullWidth
								id='name'
								name='name'
								placeholder='Enter Qyubic Affiliate name'
								value={formik.values.name}
								onChange={formik.handleChange}
								error={formik.touched.name && Boolean(formik.errors.name)}
								helperText={formik.touched.name && formik.errors.name}
							/>
						</Grid>
					</Grid>

					{/* Status */}

					<FormLable label='Status' />
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<FormControl
								sx={{ width: '100%' }}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<Select
									fullWidth
									size='small'
									id='status'
									name='status'
									displayEmpty
									value={formik.values.status}
									input={<OutlinedInput />}
									onChange={formik.handleChange}
									error={formik.touched.status && Boolean(formik.errors.status)}
								>
									<MenuItem selected={formik.values.status === 1} value={1}>
										Active<span style={{ margin: '2px' }}>/</span>صالح
									</MenuItem>
									<MenuItem selected={formik.values.status === 0} value={0}>
										Inactive<span style={{ margin: '2px' }}>/</span>غير صالح
									</MenuItem>
								</Select>
								<FormHelperText>
									{formik.touched.status && formik.errors.status}
								</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
					{id && (
						<>
							<FormLable label='Api Key' />
							<Grid container spacing={1}>
								<Grid item xs={8}>
									{/* English version field */}
									<Input
										disabled
										type='text'
										id='key'
										name='key'
										value={formik.values.key}
									/>
								</Grid>
								<Grid item xs={4}>
									<Button
										color='primary'
										variant='contained'
										type='button'
										sx={{ marginLeft: '8px' }}
										onClick={() => handleCopyKey('public')}
									>
										Copy
									</Button>
								</Grid>
							</Grid>
							<FormLable label='Secret Key' />
							<Grid container spacing={1}>
								<Grid item xs={8}>
									{/* English version field */}
									<Input
										disabled
										type={viewPrivateKey ? 'text' : 'password'}
										id='secret'
										name='secret'
										value={formik.values.secret}
									/>
								</Grid>
								<Grid item xs={4}>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-end',
											gap: '10px'
										}}
									>
										<Button
											color='primary'
											variant='contained'
											type='button'
											onClick={() => setViewPrivateKey(prev => !prev)}
										>
											{viewPrivateKey ? 'Hide' : 'View'}
										</Button>
										<Button
											color='primary'
											variant='contained'
											type='button'
											onClick={() => handleCopyKey('private')}
										>
											Copy
										</Button>
										<Button
											color='primary'
											variant='contained'
											type='button'
											onClick={() => handleGenerateSecret()}
										>
											Generate New
										</Button>
									</Box>
								</Grid>
							</Grid>
						</>
					)}
				</Box>

				<Button
					sx={{
						marginTop: '1rem',
						marginBottom: '1rem'
					}}
					color='primary'
					variant='contained'
					fullWidth
					loading={loadingBtn}
					type='submit'
				>
					Submit
				</Button>
			</form>
			{id && (
				<Box maxWidth='lg' sx={{ marginTop: '10px' }}>
					<Header title='Coupons Assigned to Partner' />
					<Box>
						<Table
							rows={partnerCoupons}
							rowHeight={85}
							columns={couponTable}
							count={partnerCouponsPagination?.totalPages}
							page={partnerCouponsPagination.currentPage}
							rowCount={partnerCouponsPagination?.perPage}
							total={partnerCouponsPagination?.total}
							perPage={partnerCouponsPagination.perPage}
							onPerPage={(value: number) => {
								dispatch(consumerPartnersSlice.actions.setPartnerCouponCurrentPage(1));
								dispatch(consumerPartnersSlice.actions.setPartnerCouponPerPage(value));
							}}
							onChange={(_e: ChangeEvent, value: number) => {
								dispatch(
									consumerPartnersSlice.actions.setPartnerCouponCurrentPage(value)
								);
							}}
							sx={{ height: '70vh' }}
							loading={partnerCouponsLoading}
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};

const ConsumerPartnerAdd = () => {
	const { loading, isAdded, isUpdated, partnerCouponsPagination } = useSelector(
		(state: RootState) => state.consumerPartners
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('/qyubic-affiliate-management');
			dispatch(clearData());
		}
	}, [isAdded, isUpdated]);
	useEffect(() => {
		if (id) {
			dispatch(getConsumerPartnerById(id));
		}
	}, []);
	useEffect(() => {
		if (id) {
			dispatch(
				getConsumerPartnerCouponsById({
					id,
					page: partnerCouponsPagination.currentPage,
					perPage: partnerCouponsPagination.perPage
				})
			);
		}
	}, [partnerCouponsPagination.currentPage, partnerCouponsPagination.perPage]);

	return (
		<TableLayout>
			<Container maxWidth='lg'>
				<Header title={id ? 'Edit Qyubic Affiliate' : 'Add Qyubic Affiliate'} />
				<Box>
					<Box>
						{loading ? (
							<Box
								sx={{
									width: '100%',
									height: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'absolute',
									backgroundColor: 'white',
									opacity: '0.7',
									zIndex: '999',
									minHeight: 'calc(100vh - 80px)'
								}}
							>
								<Loader />
							</Box>
						) : (
							<WithMaterialUI />
						)}
					</Box>
				</Box>
			</Container>
		</TableLayout>
	);
};

export default ConsumerPartnerAdd;
