import Sidebar from 'components/sidebar';
import Topbar from 'components/topbar';
import { Outlet } from 'react-router-dom';

import styles from './common.module.scss';

const CommonLayout = () => (
	<>
		<Topbar />
		<div className={styles.grid}>
			<Sidebar />
			<div className={styles.rightSec}>
				<Outlet />
			</div>
		</div>
	</>
);

export default CommonLayout;
