import { verifyEmail } from 'api/verifyEmail';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { clearData } from 'redux/verifyEmail/verifySlice';

const VerifyEmail = () => {
	const [searchParams] = useSearchParams();
	const { isVerified } = useSelector((state: RootState) => state.verify);
	const token = searchParams.get('token');

	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		dispatch(clearData());

		localStorage.clear();

		if (token) {
			dispatch(verifyEmail({ token }));
		}
		if (isVerified) {
			navigate('/');
		}
	}, [token, isVerified]);

	return null;
};

export default VerifyEmail;
