/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */

import 'antd/dist/antd.css';

// import UploadIcon from '@mui/icons-material/Upload';
import {
	Box,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AddBrand, getBrandById, UpdateBrand } from 'api/brand';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
// import LinearProgressWithLabel from 'components/common/UploadProgress';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
// import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { emptySingleBrand } from 'redux/brand/brandSlice';
import { RootState } from 'redux/store';

import FormLable from '../../../components/common/FormLable';
import UploadImage from '../../../utils/UploadImage';
import validationSchema from './validation';

const useStyles = makeStyles(() => ({
	selectedAll: {
		backgroundColor: 'rgba(186, 84, 245, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(186, 84, 245, 0.12)',
		},
	},
}));

const WithMaterialUI = () => {
	const dispatch = useDispatch();
	const allCountry = useSelector((state: RootState) => state.country.allCountry);

	const AllCountryIds = allCountry.map((item: any) => item.id);
	const classes = useStyles();

	const { id } = useParams();
	const { singleBrand, loadingBtn } = useSelector(
		(state: RootState) => state.brand
	);

	const [logo, setLogo] = useState<string>(
		'' || singleBrand?.brandTranslation?.en?.[0]?.imageSlug?.path
	);
	const handleSlug = (data: string) => {
		setLogo(data);
	};
	const [logoAra, setLogoAra] = useState<string>(
		'' || singleBrand?.brandTranslation?.ara?.[0]?.imageSlug?.path
	);
	const handleSlugAra = (data: string) => {
		setLogoAra(data);
	};

	const [seoImageEn, setSeoImageEn] = useState<string>(
		'' ||
			singleBrand?.seo?.filter((item: any) => item.languageCode === 'en')[0]
				?.imageSlug?.path
	);
	const [seoImageAra, setSeoImageAra] = useState<string>(
		'' ||
			singleBrand?.seo?.filter((item: any) => item.languageCode === 'ara')[0]
				?.imageSlug?.path
	);

	const handleSeoImageEn = (data: string) => {
		setSeoImageEn(data);
	};
	const handleSeoImageAra = (data: string) => {
		setSeoImageAra(data);
	};

	const [selected, setSelected] = useState<string[]>([]);

	useEffect(() => {
		setSelected(
			singleBrand?.id !== ''
				? singleBrand?.country.map((each: any) => each?.id)
				: []
		);
	}, []);

	const formik = useFormik({
		initialValues: {
			brandName:
				(singleBrand && singleBrand?.brandTranslation?.en?.[0]?.name) || '',
			brandName_ara:
				(singleBrand && singleBrand?.brandTranslation?.ara?.[0]?.name) || '',
			brandLogo:
				(singleBrand && singleBrand?.brandTranslation?.en?.[0]?.imageSlug?.slug) ||
				'',

			brandLogoAra:
				(singleBrand && singleBrand?.brandTranslation?.ara?.[0]?.imageSlug?.slug) ||
				'',
			imageAlt:
				(singleBrand && singleBrand?.brandTranslation?.en?.[0]?.imageAlt) || '',

			imageAltAra:
				(singleBrand && singleBrand?.brandTranslation?.ara?.[0]?.imageAlt) || '',
			imageTitle:
				(singleBrand && singleBrand?.brandTranslation?.en?.[0]?.imageTitle) || '',

			imageTitleAra:
				(singleBrand && singleBrand?.brandTranslation?.ara?.[0]?.imageTitle) || '',
			seoImageSlug:
				singleBrand?.seo?.filter((item: any) => item.languageCode === 'en')[0]
					?.imageSlug?.slug || '',

			seoImageSlugAra:
				singleBrand?.seo?.filter((item: any) => item.languageCode === 'ara')[0]
					?.imageSlug?.slug || '',
			description: singleBrand?.brandTranslation?.en?.[0].description || '',

			descriptionAra: singleBrand?.brandTranslation?.ara?.[0].description || '',
			country: singleBrand?.id
				? singleBrand?.country.map((each: any) => each?.id)
				: [],
			status: singleBrand ? (singleBrand?.status === 1 ? 1 : 0) : 0,
			seoTitle: singleBrand
				? singleBrand?.seo?.filter((item: any) => item.languageCode === 'en')[0]
						?.title
				: '',
			// seoKeywords: singleBrand
			//   ? singleBrand?.seo?.filter((item: any) => item.languageCode === 'en')[0]
			//       ?.keywords
			//   : '',
			seoMetas: singleBrand
				? singleBrand?.seo?.filter((item: any) => item.languageCode === 'en')[0]
						?.description
				: '',
			seoTitleAra: singleBrand
				? singleBrand?.seo?.filter((item: any) => item.languageCode === 'ara')[0]
						?.title
				: '',
			// seoKeywordsAra: singleBrand
			//   ? singleBrand?.seo?.filter(
			//       (item: any) => item.languageCode === 'ara',
			//     )[0]?.keywords
			//   : '',
			seoMetasAra: singleBrand
				? singleBrand?.seo?.filter((item: any) => item.languageCode === 'ara')[0]
						?.description
				: '',
			seoImageAlt:
				singleBrand?.seo?.filter((item: any) => item.languageCode === 'en')[0]
					?.imageAlt || '',

			seoImageAltAra:
				singleBrand?.seo?.filter((item: any) => item.languageCode === 'ara')[0]
					?.imageAlt || '',
		},

		validationSchema,
		onSubmit: values => {
			const backendObject: any = {
				// iconSlug: values.brandLogo,
				status: values.status,
				translation: [
					{
						languageCode: 'en',
						name: values.brandName,
						imageSlug: values.brandLogo,
						description: values.description,
						imageAlt: values.imageAlt,
						imageTitle: values.imageTitle,
					},
					{
						languageCode: 'ara',
						name: values.brandName_ara,
						imageSlug: values.brandLogoAra ? values.brandLogoAra : values.brandLogo,
						description: values.descriptionAra,
						imageAlt: values.imageAltAra,
						imageTitle: values.imageTitleAra,
					},
				],
				seo: [
					{
						seoTitle: values.seoTitle,
						// seoKeywords: values.seoKeywords,
						seoMetas: values.seoMetas,
						imageSlug: values.seoImageSlug || values.brandLogo,
						imageAlt: values.seoImageAlt,
						languageCode: 'en',
					},
					{
						seoTitle: values.seoTitleAra,
						// seoKeywords: values.seoKeywordsAra,
						seoMetas: values.seoMetasAra,
						imageAlt: values.seoImageAltAra,
						imageSlug: values.seoImageSlugAra
							? values.seoImageSlugAra
							: values.seoImageSlug,
						languageCode: 'ara',
					},
				],
				countries: values.country,
			};
			if (id) {
				dispatch(UpdateBrand({ backendObject, id }));
			} else {
				dispatch(AddBrand(backendObject));
			}
		},
	});
	const setFormImg = (slug: string) => {
		formik.setFieldValue('brandLogo', slug);
	};
	const setFormImgAra = (slug: string) => {
		formik.setFieldValue('brandLogoAra', slug);
	};

	const setSeoEnImg = (slug: string) => {
		formik.setFieldValue('seoImageSlug', slug);
	};
	const setSeoAraImg = (slug: string) => {
		formik.setFieldValue('seoImageSlugAra', slug);
	};
	// const [leavePage, setLeavePage] = useState(false);

	// useEffect(() => {
	// 	allValues(formik.values)
	// 		? setLeavePage(false)
	// 		: setLeavePage(!isEmpty(formik.values));
	// }, [formik.values]);

	// usePrompt(leavePage);

	const handleChange = (event: any) => {
		const { value } = event.target;

		if (value[value.length - 1] === 'all') {
			formik.setFieldValue(
				'country',
				selected.length === allCountry.length ? [] : AllCountryIds
			);
			setSelected(selected.length === allCountry.length ? [] : AllCountryIds);
			return;
		}
		setSelected(value);
		formik.setFieldValue('country', value);
	};

	const isAllSelected =
		allCountry.length > 0 && selected.length === allCountry.length;
	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				{/* Brand Name */}
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='English' required={false} />
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Arabic' required={false} textAlign='right' />
					</Grid>
				</Grid>

				<FormLable label='Brand Name' />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6}>
						{/* English version field */}
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='brandName'
							name='brandName'
							placeholder='Enter brand name'
							value={formik.values.brandName}
							onChange={formik.handleChange}
							error={formik.touched.brandName && Boolean(formik.errors.brandName)}
							helperText={formik.touched.brandName && formik.errors.brandName}
						/>
					</Grid>
					<Grid item xs={6}>
						{/* Arabic version field */}
						<Input
							fullWidth
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							id='brandName_ara'
							name='brandName_ara'
							placeholder='Enter brand name'
							type='text'
							dir='rtl'
							value={formik.values.brandName_ara}
							onChange={formik.handleChange}
							error={
								formik.touched.brandName_ara && Boolean(formik.errors.brandName_ara)
							}
							helperText={formik.touched.brandName_ara && formik.errors.brandName_ara}
							className='arabic-error'
						/>
					</Grid>
				</Grid>

				{/* Brand Logo */}

				<FormLable label='Brand Logo' />
				<Grid container spacing={1}>
					<Grid item xs={2}>
						<UploadImage
							aspect={2 / 1}
							logo={logo}
							handleSlug={handleSlug}
							entity='brands'
							formikImg={(s: string) => setFormImg(s)}
						/>
						<span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.brandLogo &&
								formik.errors.brandLogo &&
								formik.errors.brandLogo}
						</span>
					</Grid>
					<Grid item xs={3}>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='imageAlt'
							name='imageAlt'
							placeholder='image Alt'
							value={formik.values.imageAlt}
							onChange={formik.handleChange}
						/>
					</Grid>
					<Grid item xs={2} />
					<Grid item xs={2}>
						<UploadImage
							aspect={2 / 1}
							logo={logoAra}
							handleSlug={handleSlugAra}
							entity='brands'
							formikImg={(s: string) => setFormImgAra(s)}
						/>
					</Grid>
					<Grid item xs={3}>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='imageAltAra'
							name='imageAltAra'
							placeholder='Image alt arabic'
							dir='rtl'
							value={formik.values.imageAltAra}
							onChange={formik.handleChange}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='Image Title' required={false} />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='imageTitle'
							multiline
							maxRows={4}
							name='imageTitle'
							placeholder='Image Title'
							value={formik.values.imageTitle}
							onChange={formik.handleChange}
							error={formik.touched.imageTitle && Boolean(formik.errors.imageTitle)}
							helperText={formik.touched.imageTitle && formik.errors.imageTitle}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Image Title Ara' required={false} textAlign='right' />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='imageTitleAra'
							multiline
							maxRows={4}
							name='imageTitleAra'
							type='text'
							dir='rtl'
							placeholder='Image Title'
							value={formik.values.imageTitleAra}
							onChange={formik.handleChange}
							error={
								formik.touched.imageTitleAra && Boolean(formik.errors.imageTitleAra)
							}
							helperText={formik.touched.imageTitleAra && formik.errors.imageTitleAra}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} className='my-grid'>
					<FormLable label='Country' />
					<FormControl
						sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
						error={formik.touched.country && Boolean(formik.errors.country)}
					>
						<Select
							size='small'
							fullWidth
							id='country'
							name='country'
							displayEmpty
							multiple
							value={selected}
							input={<OutlinedInput />}
							onChange={handleChange}
							error={formik.touched.country && Boolean(formik.errors.country)}
						>
							<MenuItem
								key='all'
								value='all'
								classes={{
									root: isAllSelected ? classes.selectedAll : '',
								}}
							>
								Select All
							</MenuItem>

							{allCountry.map((each: any) => (
								<MenuItem key={each.id} value={each.id}>
									{
										each.translation.filter((item: any) => item.languageCode === 'en')[0]
											.name
									}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>
							{formik.touched.country && formik.errors.country}
						</FormHelperText>
					</FormControl>
				</Grid>
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6}>
						<FormLable label='Description' />

						<ReactQuill
							theme='snow'
							value={formik.values.description}
							onChange={v => formik.setFieldValue('description', v)}
						/>
					</Grid>

					<Grid item xs={6}>
						<FormLable label='Description' />

						<ReactQuill
							theme='snow'
							value={formik.values.descriptionAra}
							onChange={v => formik.setFieldValue('descriptionAra', v)}
						/>
					</Grid>
				</Grid>
				<FormLable label='Status' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								fullWidth
								size='small'
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem selected={formik.values.status === 1} value={1}>
									Active
								</MenuItem>
								<MenuItem selected={formik.values.status === 0} value={0}>
									Inactive
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='SEO Title' required={false} />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='seoTitle'
							multiline
							maxRows={4}
							name='seoTitle'
							placeholder='SEO Title'
							value={formik.values.seoTitle}
							onChange={formik.handleChange}
							error={formik.touched.seoTitle && Boolean(formik.errors.seoTitle)}
							helperText={formik.touched.seoTitle && formik.errors.seoTitle}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='SEO Title' required={false} textAlign='right' />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='seoTitleAra'
							multiline
							maxRows={4}
							name='seoTitleAra'
							type='text'
							dir='rtl'
							placeholder='SEO Title'
							value={formik.values.seoTitleAra}
							onChange={formik.handleChange}
							error={formik.touched.seoTitleAra && Boolean(formik.errors.seoTitleAra)}
							helperText={formik.touched.seoTitleAra && formik.errors.seoTitleAra}
						/>
					</Grid>
				</Grid>
				{/* <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormLable
              label="SEO Keywords (comma separated)"
              required={false}
            />

            <Input
              sx={{
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              fullWidth
              id="seoKeywords"
              multiline
              maxRows={4}
              name="seoKeywords"
              placeholder="SEO Keywords"
              value={formik.values.seoKeywords?.trim()}
              onChange={formik.handleChange}
              error={
                formik.touched.seoKeywords && Boolean(formik.errors.seoKeywords)
              }
              helperText={
                formik.touched.seoKeywords && formik.errors.seoKeywords
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormLable
              label="SEO Keywords (comma separated)"
              required={false}
              textAlign="right"
            />
            <Input
              sx={{
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              fullWidth
              id="seoKeywordsAra"
              multiline
              maxRows={4}
              name="seoKeywordsAra"
              type="text"
              dir="rtl"
              placeholder="SEO Keywords"
              value={formik.values.seoKeywordsAra}
              onChange={formik.handleChange}
              error={
                formik.touched.seoKeywords && Boolean(formik.errors.seoKeywords)
              }
              helperText={
                formik.touched.seoKeywords && formik.errors.seoKeywords
              }
            />
          </Grid>
        </Grid> */}

				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='SEO Description' required={false} />

						{/* Arabic version field */}
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='seoMetas'
							multiline
							maxRows={4}
							name='seoMetas'
							placeholder='SEO Description'
							value={formik.values.seoMetas}
							onChange={formik.handleChange}
							error={formik.touched.seoMetas && Boolean(formik.errors.seoMetas)}
							helperText={formik.touched.seoMetas && formik.errors.seoMetas}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='SEO Description' required={false} textAlign='right' />

						{/* Arabic version field */}
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='seoMetasAra'
							multiline
							maxRows={4}
							name='seoMetasAra'
							dir='rtl'
							placeholder='SEO Description'
							value={formik.values.seoMetasAra}
							onChange={formik.handleChange}
							error={formik.touched.seoMetasAra && Boolean(formik.errors.seoMetasAra)}
							helperText={formik.touched.seoMetasAra && formik.errors.seoMetasAra}
						/>
					</Grid>
				</Grid>
				<FormLable label='Seo Image' />
				{/* <small style={{ color: 'GrayText' }}>You can only upload 1:1 image</small> */}
				<Grid container spacing={1}>
					<Grid item xs={2}>
						<UploadImage
							aspect={2 / 1}
							logo={seoImageEn}
							handleSlug={handleSeoImageEn}
							entity='brand-seo'
							formikImg={(s: string) => setSeoEnImg(s)}
						/>
					</Grid>
					<Grid item xs={3}>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='seoImageAlt'
							name='seoImageAlt'
							placeholder='Image Alt'
							value={formik.values.seoImageAlt}
							onChange={formik.handleChange}
						/>
					</Grid>
					<Grid item xs={2} />
					<Grid item xs={2}>
						<UploadImage
							aspect={2 / 1}
							logo={seoImageAra}
							handleSlug={handleSeoImageAra}
							entity='brand-seo'
							formikImg={(s: string) => setSeoAraImg(s)}
						/>
					</Grid>
					<Grid item xs={3}>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='seoImageAltAra'
							name='seoImageAltAra'
							placeholder='Image Alt'
							dir='rtl'
							value={formik.values.seoImageAltAra}
							onChange={formik.handleChange}
						/>
					</Grid>
				</Grid>

				{/* Status */}
			</Box>

			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem',
				}}
				color='primary'
				variant='contained'
				fullWidth
				loading={loadingBtn}
				type='submit'
			>
				Submit
			</Button>
		</form>
	);
};

const BrandAdd = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();

	const singleBrand = useSelector((state: RootState) => state.brand.singleBrand);
	const loading = useSelector((state: RootState) => state.brand.loading);
	const isAdded = useSelector((state: RootState) => state.brand.isAdded);
	const isUpdated = useSelector((state: RootState) => state.brand.isUpdated);
	useEffect(() => {}, [singleBrand]);

	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('brand-management');
		}
	}, [isAdded, isUpdated]);

	useEffect(() => {
		if (id) {
			dispatch(getBrandById(id));
		}
		return () => {
			dispatch({ type: emptySingleBrand });
		};
	}, [id]);
	return (
		<TableLayout>
			<Container maxWidth='lg' className='addScroll'>
				<Header title={id ? 'Edit Brand' : 'Add Brand'} />
				<Box>
					{loading ? (
						<Box
							sx={{
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'absolute',
								backgroundColor: 'white',
								opacity: '0.7',
								zIndex: '999',
								minHeight: 'calc(100vh - 80px)',
							}}
						>
							<Loader />
						</Box>
					) : (
						<WithMaterialUI />
					)}
				</Box>
			</Container>
		</TableLayout>
	);
};
export default BrandAdd;
