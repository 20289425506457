import { createSlice } from '@reduxjs/toolkit';

import {
	AddPartners,
	DeletePartner,
	getAllPartners,
	getPartnerById,
	searchPartners,
	UpdatePartner,
} from '../../api/partners';

const initialState: any = {
	allpartners: [],
	error: '',
	singlePartner: {},
	isAdded: false,
	isUpdated: false,
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const partnerSlice = createSlice({
	name: 'partner',
	initialState,
	reducers: {
		emptySinglePartner: state => {
			state.singlePartner = initialState.singlePartner;
		},
		toggleStatus: (state, action) => {
			state.allpartners = state.allpartners.map((d: any) =>
				d.id === action.payload ? { ...d, status: !d.status } : { ...d }
			);
		},
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getAllPartners.pending, (state, action) => {
				state.loading = !action.meta.arg.isRefresh;
			})
			.addCase(getAllPartners.fulfilled, (state, action) => {
				state.loading = false;
				state.allpartners = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllPartners.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search user
			.addCase(searchPartners.pending, state => {
				state.loading = true;
			})
			.addCase(searchPartners.fulfilled, (state, action) => {
				state.loading = false;
				state.allpartners = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchPartners.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// add brand
			.addCase(AddPartners.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(AddPartners.fulfilled, state => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(AddPartners.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = false;
				state.error = action.error.message;
			})

			// update

			.addCase(UpdatePartner.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdatePartner.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdatePartner.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;

				state.error = action.error.message;
			})

			// get single brand
			.addCase(getPartnerById.pending, state => {
				state.loading = true;
				state.loadingBtn = false;
			})
			.addCase(getPartnerById.fulfilled, (state, action) => {
				state.loading = false;
				state.loadingBtn = false;

				state.singlePartner = action?.payload?.data;
			})
			.addCase(getPartnerById.rejected, (state, action) => {
				state.loading = false;
				state.loadingBtn = false;
				state.error = action.error.message;
			})

			// change status
			.addCase(DeletePartner.pending, state => {
				state.loading = false;
			})
			.addCase(DeletePartner.fulfilled, state => {
				state.loading = false;
			})
			.addCase(DeletePartner.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { emptySinglePartner, toggleStatus, clearData } =
	partnerSlice.actions;

export default partnerSlice.reducer;
