import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { Box, Container } from '@mui/material';
import { Col, Collapse, Row } from 'antd';
import { addSetting, getSetting } from 'api/settings';
import Button from 'components/common/Button';
import FormLable from 'components/common/FormLable';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { FieldArray, Form, Formik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import UploadImage from 'utils/UploadImage';

import validationSchema from './validation';

const { Panel } = Collapse;
type Props = {};

type TSections = {
	title: string;
	titleAra: string;
	description: string;
	descriptionAra: string;
	image: string;
	imageAra: string;
	sequence: number;
};
const newSectionValue: TSections = {
	sequence: 1,
	title: '',
	titleAra: '',
	description: '',
	descriptionAra: '',
	image: '',
	imageAra: '',
};
const initialValues: { sections: TSections[] } = {
	sections: [newSectionValue],
};

const WithMaterialUI = () => {
	const dispatch = useDispatch();
	const { settings } = useSelector((state: RootState) => state.settings);

	const formatJson = (data: TSections[]) => {
		const engObj = data.map(item => ({
			sequence: item.sequence,
			title: item.title,
			description: item.description,
			image: item.image,
		}));
		const araObj = data.map(item => ({
			sequence: item.sequence,
			titleAra: item.titleAra,
			descriptionAra: item.descriptionAra,
			imageAra: item.imageAra,
		}));

		return [
			{
				languageCode: 'en',
				value: JSON.stringify(engObj),
			},
			{
				languageCode: 'ara',
				value: JSON.stringify(araObj),
			},
		];
	};

	const handleSubmit = (values: { sections: TSections[] }) => {
		const formatedData = formatJson(values.sections);
		const aboutUsObj = {
			key: 'AboutUs',
			translation: formatedData,
		};
		dispatch(addSetting({ data: aboutUsObj }));
	};

	useEffect(() => {
		dispatch(getSetting({ key: 'AboutUs' }));
	}, []);

	return (
		<Formik
			initialValues={settings.length > 0 ? { sections: settings } : initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
			enableReinitialize
			render={({ values, handleChange, touched, setFieldValue }) => (
				<Form>
					<FieldArray
						name='sections'
						render={arrayHelpers => (
							<>
								<Collapse defaultActiveKey={0}>
									{values.sections.map((_, index) => {
										const touch: any = touched.sections && touched.sections[index];
										const { errors }: any = arrayHelpers.form;
										const error = errors.sections && errors.sections[index];
										const isError = (key: string) =>
											touch && touch[key] && error && Boolean(error[key]);
										const getHelperText = (key: string) =>
											touch && touch[key] && error && error[key];
										const setImage = (slug: string, type: 'en' | 'ara') => {};
										const handleSlug = (path: string, type: 'en' | 'ara') => {
											if (type === 'en') {
												setFieldValue(`sections[${index}].image`, path);
											} else {
												setFieldValue(`sections[${index}].imageAra`, path);
											}
										};
										return (
											// eslint-disable-next-line react/no-array-index-key
											<Panel header={`Section ${index + 1}`} key={index}>
												<Row gutter={32}>
													<Col span={12}>
														<FormLable label='Sequence' />
														<Input
															multiline
															fullWidth
															id={`sections[${index}].sequence`}
															name={`sections[${index}].sequence`}
															onChange={handleChange}
															value={_.sequence}
															sx={{ marginBottom: 2 }}
															error={isError('sequence')}
															helperText={getHelperText('sequence')}
														/>
													</Col>
													<Col span={12} dir='rtl'>
														<Button
															sx={{
																marginTop: 4,
																marginBottom: 2,
																display: 'flex',
																gap: 1,
															}}
															variant='contained'
															type='button'
															onClick={() => {
																if (values.sections.length > 1) {
																	arrayHelpers.remove(index);
																} else {
																	// show warning for at least one section
																	toast.error('At least one section is required');
																}
															}}
														>
															<MinusCircleFilled />
															Remove Section
														</Button>
													</Col>
													<Col span={12}>
														<FormLable label='Title' />
														<Input
															type='text'
															multiline
															fullWidth
															id={`sections[${index}].title`}
															name={`sections[${index}].title`}
															onChange={handleChange}
															value={_.title}
															error={isError('title')}
															helperText={getHelperText('title')}
															sx={{ marginBottom: 2 }}
														/>
													</Col>
													<Col span={12}>
														<FormLable label='Title (ara)' textAlign='right' />
														<Input
															type='text'
															multiline
															dir='rtl'
															fullWidth
															id={`sections[${index}].titleAra`}
															name={`sections[${index}].titleAra`}
															onChange={handleChange}
															value={_.titleAra}
															error={isError('titleAra')}
															helperText={getHelperText('titleAra')}
															className='arabic-error'
														/>
													</Col>
													<Col span={12}>
														<FormLable label='Description' />
														<Input
															type='text'
															multiline
															fullWidth
															rows={4}
															id={`sections[${index}].description`}
															name={`sections[${index}].description`}
															onChange={handleChange}
															error={isError('description')}
															value={_.description}
															helperText={getHelperText('description')}
															sx={{ marginBottom: 2 }}
														/>
													</Col>
													<Col span={12}>
														<FormLable label='Description (ara)' textAlign='right' />
														<Input
															type='text'
															multiline
															dir='rtl'
															fullWidth
															rows={4}
															id={`sections[${index}].descriptionAra`}
															name={`sections[${index}].descriptionAra`}
															onChange={handleChange}
															value={_.descriptionAra}
															error={isError('descriptionAra')}
															helperText={getHelperText('descriptionAra')}
															className='arabic-error'
														/>
													</Col>
													<Col span={12}>
														<FormLable label='Image' />
														<UploadImage
															aspect={2 / 1}
															logo={values.sections[index].image}
															handleSlug={(s: string) => {
																handleSlug(s, 'en');
															}}
															entity='aboutUs'
															formikImg={(s: string) => {
																setImage(s, 'en');
															}}
														/>
														{isError('image') && (
															<p style={{ fontSize: '12px', color: '#D32F2F' }}>
																{getHelperText('image')}
															</p>
														)}
													</Col>
													<Col span={12}>
														<FormLable label='Image (ara)' textAlign='right' />
														<Box dir='rtl'>
															<UploadImage
																aspect={2 / 1}
																logo={values.sections[index].imageAra}
																handleSlug={(s: string) => {
																	handleSlug(s, 'ara');
																}}
																entity='aboutUs'
																formikImg={(s: string) => setImage(s, 'ara')}
															/>
														</Box>
														{isError('imageAra') && (
															<p
																dir='rtl'
																style={{
																	fontSize: '12px',
																	color: '#D32F2F',
																	marginRight: 12,
																}}
															>
																{getHelperText('imageAra')}
															</p>
														)}
													</Col>
												</Row>
											</Panel>
										);
									})}
								</Collapse>
								<Row gutter={32}>
									<Col span={12}>
										<Button
											sx={{
												marginTop: '3rem',
												marginBottom: '1rem',
												display: 'flex',
												gap: 1,
											}}
											variant='outlined'
											fullWidth
											type='button'
											onClick={() => {
												arrayHelpers.push({
													...newSectionValue,
													sequence: values.sections.length + 1,
												});
											}}
										>
											Add New Section <PlusCircleFilled />
										</Button>
									</Col>
									<Col span={12}>
										<Button
											sx={{ marginTop: '3rem', marginBottom: '1rem' }}
											color='primary'
											variant='contained'
											fullWidth
											type='submit'
											// loading={loadingBtn}
										>
											Submit
										</Button>
									</Col>
								</Row>
							</>
						)}
					/>
				</Form>
			)}
		/>
	);
};
const AboutUs = (props: Props) => {
	const isLoading = false;
	return (
		<TableLayout>
			<Container maxWidth='lg'>
				<Header title='About us Management' />
				<Box>
					{isLoading ? (
						<Box
							sx={{
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'absolute',
								backgroundColor: 'white',
								opacity: '0.7',
								zIndex: '999',
								minHeight: 'calc(100vh - 80px)',
							}}
						>
							<Loader />
						</Box>
					) : (
						<WithMaterialUI />
					)}
				</Box>
			</Container>
		</TableLayout>
	);
};

export default AboutUs;
