import { createSlice } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';

import {
	AddTag,
	getAllTags,
	getTagId,
	searchTags,
	toggleTagStatus,
	UpdateTag,
} from '../../api/tags';

const initialState: any = {
	allTags: [],
	singleTag: {},
	error: '',
	isAdded: false,
	isUpdated: false,
	filterByStatus: -1,
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const tagSlice = createSlice({
	name: 'tagSlice',
	initialState,
	reducers: {
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		emptySingleTag: state => {
			state.singleTag = initialState.singleTag;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all tags
			.addCase(getAllTags.pending, state => {
				state.loading = true;
			})
			.addCase(getAllTags.fulfilled, (state, action) => {
				state.loading = false;

				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						translation: groupBy(each?.translation, 'languageCode'),
					})
				);
				state.allTags = array;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllTags.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			.addCase(getTagId.pending, state => {
				state.loading = true;
			})
			.addCase(getTagId.fulfilled, (state, action) => {
				state.loading = false;

				const data = {
					...action?.payload?.data,
					translation: groupBy(action?.payload?.data?.translation, 'languageCode'),
				};

				state.singleTag = data;
			})
			.addCase(getTagId.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(toggleTagStatus.pending, state => {
				state.loading = false;
			})
			.addCase(toggleTagStatus.fulfilled, state => {
				state.loading = false;
			})
			.addCase(toggleTagStatus.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(AddTag.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(AddTag.fulfilled, state => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(AddTag.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = false;
				state.error = action.error.message;
			})
			.addCase(UpdateTag.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdateTag.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdateTag.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;
				state.error = action.error.message;
			})
			.addCase(searchTags.pending, state => {
				state.loading = true;
			})
			.addCase(searchTags.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						translation: groupBy(each?.translation, 'languageCode'),
					})
				);
				state.allTags = array;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchTags.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { emptySingleTag, clearData } = tagSlice.actions;

export default tagSlice.reducer;
