import * as yup from 'yup';

const validationSchema = yup.object({
	name: yup
		.string()
		.min(2, 'Enter a valid Client Name')
		.required('Client Name is required'),
	status: yup.number().required('Status is required.'),
	imageSlug: yup.string().required('Image is required'),
	imageTitle: yup.string().required('Image is required'),
	imageAlt: yup.string().required('Image is required'),
	imageTitleArabic: yup.string().required('Image is required'),
	imageAltArabic: yup.string().required('Image is required'),
});

export default validationSchema;
