import { createSlice } from '@reduxjs/toolkit';

import {
	changeBankTransferStatus,
	getAllBankTransfer,
	searchBankTransfer,
} from '../../api/bankTransfer';

const initialState: any = {
	allTransfers: [],
	error: '',
	filterByStatus: -1,
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const bankTransferSlice = createSlice({
	name: 'bankTransfer',
	initialState,
	reducers: {
		setFilterByStatus: (state, action) => {
			state.filterByStatus = action.payload.status;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getAllBankTransfer.pending, state => {
				state.loading = true;
			})
			.addCase(getAllBankTransfer.fulfilled, (state, action) => {
				state.loading = false;
				state.allTransfers = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllBankTransfer.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search user
			.addCase(searchBankTransfer.pending, state => {
				state.loading = true;
			})
			.addCase(searchBankTransfer.fulfilled, (state, action) => {
				state.loading = false;
				state.allTransfers = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchBankTransfer.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// change status
			.addCase(changeBankTransferStatus.pending, state => {
				state.loading = true;
			})
			.addCase(changeBankTransferStatus.fulfilled, state => {
				state.loading = false;
			})
			.addCase(changeBankTransferStatus.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { setFilterByStatus } = bankTransferSlice.actions;

export default bankTransferSlice.reducer;
