import PrivateRoute from 'privateRoute';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import routes from 'routes';
import NotFound from 'views/NotFound';
import VerifyEmail from 'views/verifyEmail';

const App = () => (
	<Router>
		<Routes>
			{routes.map(route => (
				<Route path={route.path} key={route.path} element={<route.layout />}>
					{route.children.map(child => (
						<Route
							path={child.path}
							key={child.path}
							element={
								<PrivateRoute>
									<child.component />
								</PrivateRoute>
							}
						/>
					))}
				</Route>
			))}
			<Route path='/verify-email' element={<VerifyEmail />} />
			<Route path='*' element={<NotFound />} />
		</Routes>
		<Toaster position='top-right' />
	</Router>
);

export default App;
