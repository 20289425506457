/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */

import {
	Box,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
	AddNotification,
	getNotificationById,
	UpdateNotification,
} from 'api/notification';
import { getAllCountry } from 'api/seeder';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
	clearData,
	emptySingleNotification,
} from 'redux/notification/notificationSlice';
import { RootState } from 'redux/store';
import UploadImage from 'utils/UploadImage';
import useDateTimeConversion from 'utils/useDateTimeConversion';

import FormLable from '../../../components/common/FormLable';
import styles from './index.module.scss';
import NotificationLinkInput from './NotificationLinkInput';
import validationSchema from './validation';

const useStyles = makeStyles(() => ({
	selectedAll: {
		backgroundColor: 'rgba(186, 84, 245, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(186, 84, 245, 0.12)',
		},
	},
}));

const WithMaterialUI = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const classes = useStyles();
	const {
		isDateToday,
		convertToArabicTime,
		endTime,
		currentDate,
		convertToGMTTime,
		convertGMTToArabicTime,
	} = useDateTimeConversion();

	const allCountry = useSelector(
		(state: RootState) => state.country.allCountry
	).filter((item: any) => item.code !== 'WW');

	const AllCountryIds = allCountry.map((item: any) => item.id);

	// const allbrandWithoutPage = useSelector((state: RootState) => state.brand.allbrandWithoutPage);
	const loadingBtn = useSelector((state: RootState) => state.banner.loadingBtn);
	const singleNotification = useSelector(
		(state: RootState) => state.notification.singleNotification
	);

	// banner image upload progress
	const [imageSlug, setImageEn] = useState<string>(
		singleNotification.id ? singleNotification?.details.en[0].imageSlug.path : ''
	);

	const [imageSlugAra, setImageAra] = useState<string>(
		singleNotification.id ? singleNotification?.details.ara[0].imageSlug.path : ''
	);

	const [selected, setSelected] = useState<string[]>([]);

	useEffect(() => {
		setSelected(
			singleNotification?.id
				? singleNotification?.country?.map((each: any) => each?.id)
				: []
		);
		dispatch({ type: emptySingleNotification });
		dispatch(getAllCountry());
	}, []);

	// image upload progress

	const handleImageEn = (data: string) => {
		setImageEn(data);
	};
	const handleImageAra = (data: string) => {
		setImageAra(data);
	};

	// uploaded path

	const formik = useFormik({
		initialValues: {
			country:
				singleNotification?.id !== ''
					? singleNotification?.country?.map((each: any) => each?.id)
					: [],
			link: singleNotification?.link ? singleNotification?.link : '',
			title: singleNotification.id ? singleNotification?.details.en[0].title : '',
			titleAra: singleNotification.id
				? singleNotification?.details.ara[0].title
				: '',

			// eslint-disable-next-line no-nested-ternary
			status: singleNotification ? (singleNotification?.status === 1 ? 1 : 0) : 0,

			imageSlug: singleNotification.id
				? singleNotification?.details.en[0].imageSlug.slug
				: '',
			imageSlugAra: singleNotification.id
				? singleNotification?.details.ara[0].imageSlug.slug
				: '',
			description: singleNotification.id
				? singleNotification?.details.en[0].message
				: '',
			description_ara: singleNotification.id
				? singleNotification?.details.ara[0].message
				: '',
			schdeduledAt: singleNotification?.scheduledAt
				? convertGMTToArabicTime(singleNotification?.scheduledAt)
				: convertToArabicTime(),
			type: singleNotification?.type || 1,
		},

		validationSchema,
		onSubmit: values => {
			const backendObject: any = {
				type: values.type,
				link: values.link,
				status: values.status,
				countries: values.country,
				scheduledAt: convertToGMTTime(values.schdeduledAt),
				details: [
					{
						languageCode: 'en',
						title: values?.title,
						imageSlug: values.imageSlug,
						message: values.description,
					},
					{
						languageCode: 'ara',
						title: values?.titleAra,
						imageSlug: values.imageSlugAra ? values.imageSlugAra : values.imageSlug,
						message: values.description_ara,
					},
				],
			};

			// eslint-disable-next-line eqeqeq
			if (values.status == 1) {
				if (id) {
					dispatch(UpdateNotification({ id, backendObject }));
				} else {
					dispatch(AddNotification(backendObject));
				}
			} else {
				toast.error('Cannot Send Inactive Notifications.');
			}
		},
	});

	// const [leavePage, setLeavePage] = useState(false);

	// useEffect(() => {
	// 	allValues(formik.values)
	// 		? setLeavePage(false)
	// 		: setLeavePage(!isEmpty(formik.values));
	// }, [formik.values]);

	// usePrompt(leavePage);

	const setEnImg = (slug: string) => {
		formik.setFieldValue('imageSlug', slug);
	};
	const setAraImg = (slug: string) => {
		formik.setFieldValue('imageSlugAra', slug);
	};

	const handleChange = (event: any) => {
		const { value } = event.target;

		if (value[value.length - 1] === 'all') {
			formik.setFieldValue(
				'country',
				selected.length === allCountry.length ? [] : AllCountryIds
			);
			setSelected(selected.length === allCountry.length ? [] : AllCountryIds);
			return;
		}
		setSelected(value);
		formik.setFieldValue('country', value);
	};

	const isAllSelected =
		allCountry.length > 0 && selected?.length === allCountry?.length;

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='English' required={false} />
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Arabic' required={false} textAlign='right' />
					</Grid>
				</Grid>
				<Grid item xs={6} className='my-grid'>
					<FormLable label='Notification Type' />

					<FormControl error={formik.touched.type && Boolean(formik.errors.type)}>
						<Select
							fullWidth
							size='small'
							id='type'
							name='type'
							displayEmpty
							value={formik.values.type}
							input={<OutlinedInput />}
							onChange={e => {
								formik.handleChange(e);
								formik.setFieldValue('link', '');
							}}
							error={formik.touched.type && Boolean(formik.errors.type)}
						>
							<MenuItem selected={formik.values.type === 1} value={1}>
								External
							</MenuItem>
							<MenuItem selected={formik.values.type === 2} value={2}>
								Internal
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<FormLable label='Country' />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6}>
						<FormControl
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							error={formik.touched.country && Boolean(formik.errors.country)}
						>
							<Select
								size='small'
								fullWidth
								id='country'
								name='country'
								displayEmpty
								multiple
								value={selected}
								input={<OutlinedInput />}
								onChange={handleChange}
								error={formik.touched.country && Boolean(formik.errors.country)}
							>
								<MenuItem
									key='all'
									value='all'
									classes={{
										root: isAllSelected ? classes.selectedAll : '',
									}}
								>
									Select All
								</MenuItem>

								{allCountry.map((each: any) => (
									<MenuItem key={each.id} value={each.id}>
										{
											each.translation.filter((item: any) => item.languageCode === 'en')[0]
												.name
										}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{formik.touched.country && formik.errors.country}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						{/* Arabic version field */}

						<FormControl
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							error={formik.touched.country && Boolean(formik.errors.country)}
						>
							<Select
								size='small'
								fullWidth
								dir='rtl'
								id='country'
								name='country'
								multiple
								displayEmpty
								value={selected}
								input={<OutlinedInput />}
								onChange={handleChange}
								error={formik.touched.country && Boolean(formik.errors.country)}
							>
								<MenuItem
									key='all'
									value='all'
									classes={{
										root: isAllSelected ? classes.selectedAll : '',
									}}
								>
									Select All
								</MenuItem>

								{allCountry.map((each: any) => (
									<MenuItem key={each.id} value={each.id}>
										{
											each.translation.filter((item: any) => item.languageCode === 'en')[0]
												.name
										}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{formik.touched.country && formik.errors.country}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
				<FormLable label='Title' required={false} />
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<Input
							sx={{
								marginBottom: '1rem',
							}}
							fullWidth
							id='title'
							multiline
							maxRows={4}
							name='title'
							placeholder='Title'
							value={formik.values.title}
							onChange={formik.handleChange}
							error={formik.touched.title && Boolean(formik.errors.title)}
							helperText={formik.touched.title && formik.errors.title}
						/>
					</Grid>
					<Grid item xs={6}>
						<Input
							sx={{
								marginBottom: '1rem',
							}}
							fullWidth
							id='titleAra'
							multiline
							maxRows={4}
							name='titleAra'
							type='text'
							dir='rtl'
							placeholder='Title'
							value={formik.values.titleAra}
							onChange={formik.handleChange}
							error={formik.touched.titleAra && Boolean(formik.errors.titleAra)}
							helperText={formik.touched.titleAra && formik.errors.titleAra}
						/>
					</Grid>
				</Grid>

				<FormLable label='Description' />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6} className='my-grid'>
						{/* English version field */}

						<Input
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							type='text'
							multiline
							id='description'
							name='description'
							value={formik.values.description}
							onChange={formik.handleChange}
							error={formik.touched.description && Boolean(formik.errors.description)}
							helperText={formik.touched.description && formik.errors.description}
						/>
					</Grid>

					<Grid item xs={6} className='my-grid'>
						{/* Arabic version field */}

						<Input
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							id='description_ara'
							name='description_ara'
							type='text'
							multiline
							dir='rtl'
							value={formik.values.description_ara}
							onChange={formik.handleChange}
							error={
								formik.touched.description_ara && Boolean(formik.errors.description_ara)
							}
							helperText={
								formik.touched.description_ara && formik.errors.description_ara
							}
							className='arabic-error'
						/>
					</Grid>
				</Grid>
				{/* link */}
				<NotificationLinkInput
					selectedType={formik.values.type}
					link={formik.values.link}
					formikInstance={formik}
					selectedCountry={selected}
					onChange={(value: string) => {
						formik.setFieldValue('link', value);
					}}
				/>
				{/* Banner Image */}
				<FormLable label='Image' />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6}>
						{/* English version field */}
						<UploadImage
							aspect={2 / 1}
							logo={imageSlug || ''}
							handleSlug={handleImageEn}
							entity='notification'
							formikImg={(s: string) => setEnImg(s)}
						/>
						<span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.imageSlug &&
								formik.errors.imageSlug &&
								formik.errors.imageSlug}
						</span>
					</Grid>
					<Grid item xs={6}>
						{/* Arabic version field */}
						<UploadImage
							aspect={2 / 1}
							logo={imageSlugAra || ''}
							handleSlug={handleImageAra}
							entity='notification'
							formikImg={(s: string) => setAraImg(s)}
						/>
					</Grid>
				</Grid>

				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6} className='my-grid'>
						<FormLable label='Scheduled Date' />

						{/* English version field */}
						<FormControl
							error={
								formik.touched.schdeduledAt && Boolean(formik.errors.schdeduledAt)
							}
						>
							<DatePicker
								minDate={currentDate}
								minTime={
									isDateToday(formik.values.schdeduledAt)
										? convertToArabicTime()
										: undefined
								}
								maxTime={isDateToday(formik.values.schdeduledAt) ? endTime : undefined}
								className={styles.datePicker}
								selected={
									(formik.values.schdeduledAt && formik.values.schdeduledAt) || null
								}
								onChange={(val: any) => {
									formik.setFieldValue('schdeduledAt', val);
								}}
								showTimeSelect
								timeIntervals={10}
								timeFormat='p'
								dateFormat='Pp'
							/>
							<FormHelperText>
								{formik.touched.schdeduledAt && formik.errors.schdeduledAt}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
				{/* Status */}
				<FormLable label='Status' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								fullWidth
								size='small'
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem selected={formik.values.status === 1} value={1}>
									Active
								</MenuItem>
								<MenuItem selected={formik.values.status === 0} value={0}>
									Inactive
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
			</Box>

			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem',
				}}
				loading={loadingBtn}
				color='primary'
				variant='contained'
				fullWidth
				type='submit'
			>
				Submit
			</Button>
		</form>
	);
};

const NotificationAdd = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector((state: RootState) => state.notification.loading);
	const isAdded = useSelector((state: RootState) => state.notification.isAdded);
	const isUpdated = useSelector(
		(state: RootState) => state.notification.isUpdated
	);

	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('/notification-management');
			dispatch(clearData());
		}
	}, [isAdded, isUpdated]);

	useEffect(() => {
		if (id) {
			dispatch(getNotificationById(id));
		}
	}, [id]);

	return (
		<TableLayout>
			<Container maxWidth='lg' className='addScroll'>
				<Header title={id ? 'Edit Notification' : 'Add Notification'} />
				<Box>
					{loading ? (
						<Box
							sx={{
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'absolute',
								backgroundColor: 'white',
								opacity: '0.7',
								zIndex: '999',
								minHeight: 'calc(100vh - 80px)',
							}}
						>
							<Loader />
						</Box>
					) : (
						<WithMaterialUI />
					)}
				</Box>
			</Container>
		</TableLayout>
	);
};
export default NotificationAdd;
