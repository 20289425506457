import AuthLayout from 'layout/Auth';
import CommonLayout from 'layout/Common';
import AboutUs from 'views/aboutUs/AboutUs';
import Advertise from 'views/advertise';
import BankTransfer from 'views/bankTransfer';
import Banner from 'views/Banner';
import BannerAdd from 'views/Banner/add';
import Brand from 'views/Brand';
import BrandAdd from 'views/Brand/add';
import BreadCrumbs from 'views/breadcrumbs';
import BreadCrumbsAdd from 'views/breadcrumbs/Add';
import Cashback from 'views/Cashback';
import CashbackAdd from 'views/Cashback/add';
import Category from 'views/Category';
import CategoryAdd from 'views/Category/add';
import Chat from 'views/ChatThread';
import Client from 'views/Client';
import ClientAdd from 'views/Client/Add';
import ConsumerPartner from 'views/ConsumerPartner';
import ConsumerPartnerAdd from 'views/ConsumerPartner/Add';
import ContactUs from 'views/contactUs';
import Country from 'views/Country';
import CountryAdd from 'views/Country/Add';
import Coupon from 'views/Coupon';
import CouponAdd from 'views/Coupon/add';
import Dashboard from 'views/Dashboard';
import FAQ from 'views/FAQ';
import Image from 'views/Image';
import Login from 'views/Login';
import NewsLetter from 'views/newsLetter';
import Notification from 'views/notifications';
import NotificationAdd from 'views/notifications/add';
import PageSeo from 'views/pageSeo';
import PageSeoAdd from 'views/pageSeo/add';
import Partner from 'views/Partner';
import PartnerAdd from 'views/Partner/Add';
import Roles from 'views/Roles';
import RolesAdd from 'views/Roles/add';
import Settings from 'views/Settings';
import Tags from 'views/tags';
import TagsAdd from 'views/tags/add';
import TrendingBrand from 'views/TrendingBrand';
import User from 'views/User';
import UserAdd from 'views/User/add';
import VerifyEmail from 'views/verifyEmail';

const routes = [
	{
		layout: AuthLayout,
		path: '/auth',
		isPrivate: false,
		children: [
			{
				component: Login,
				path: 'login',
			},
			{
				component: VerifyEmail,
				path: 'verify-email',
			},
		],
	},
	{
		layout: CommonLayout,
		path: '/',
		isPrivate: true,
		children: [
			{
				component: Dashboard,
				path: '',
			},
			{
				component: User,
				path: 'user-management',
			},
			{
				component: UserAdd,
				path: 'user-management/add',
			},
			{
				component: UserAdd,
				path: 'user-management/edit/:id',
			},
			{
				component: Notification,
				path: 'notification-management',
			},
			{
				component: NotificationAdd,
				path: 'notification-management/add',
			},
			{
				component: NotificationAdd,
				path: 'notification-management/edit/:id',
			},
			{
				component: Category,
				path: 'category-management',
			},
			{
				component: CategoryAdd,
				path: 'category-management/add',
			},
			{
				component: CategoryAdd,
				path: 'category-management/edit/:id',
			},
			{
				component: Brand,
				path: 'brand-management',
			},
			{
				component: BrandAdd,
				path: 'brand-management/add',
			},
			{
				component: BrandAdd,
				path: 'brand-management/edit/:id',
			},
			{
				component: Coupon,
				path: 'coupon-management',
			},
			{
				component: Tags,
				path: 'tag-management',
			},
			{
				component: TagsAdd,
				path: 'tag-management/add',
			},
			{
				component: TagsAdd,
				path: 'tag-management/edit/:id',
			},
			{
				component: CouponAdd,
				path: 'coupon-management/add',
			},
			{
				component: CouponAdd,
				path: 'coupon-management/edit/:couponType/:id',
			},
			{
				component: CouponAdd,
				path: 'coupon-management/edit/:couponType/:id/:affliateId',
			},
			{
				component: TrendingBrand,
				path: 'trending-management',
			},
			{
				component: Image,
				path: 'image-management',
			},
			{
				component: Banner,
				path: 'banner-management',
			},
			{
				component: BannerAdd,
				path: 'banner-management/add',
			},
			{
				component: BannerAdd,
				path: 'banner-management/edit/:id',
			},
			{
				component: Partner,
				path: 'affiliate-management',
			},
			{
				component: PartnerAdd,
				path: 'affiliate-management/edit/:id',
			},
			{
				component: PartnerAdd,
				path: 'affiliate-management/add/',
			},
			{
				component: ConsumerPartner,
				path: 'qyubic-affiliate-management',
			},
			{
				component: ConsumerPartnerAdd,
				path: 'qyubic-affiliate-management/edit/:id',
			},
			{
				component: ConsumerPartnerAdd,
				path: 'qyubic-affiliate-management/add/',
			},
			{
				component: Client,
				path: 'client-management',
			},
			{
				component: ClientAdd,
				path: 'client-management/edit/:id',
			},
			{
				component: ClientAdd,
				path: 'client-management/add/',
			},
			{
				component: Cashback,
				path: 'cashback-management/',
			},
			{
				component: Chat,
				path: 'chat-management/',
			},
			{
				component: CashbackAdd,
				path: 'cashback-management/edit/:id',
			},
			{
				component: BankTransfer,
				path: 'bankTransfer-management/',
			},
			{
				component: Advertise,
				path: 'advertisement-management/',
			},
			{
				component: ContactUs,
				path: 'contacts-management/',
			},
			{
				component: Settings,
				path: 'settings-management/',
			},
			{
				component: FAQ,
				path: 'faq-management/',
			},
			{
				component: Roles,
				path: 'roles-management',
			},
			{
				component: RolesAdd,
				path: 'roles-management/add',
			},
			{
				component: RolesAdd,
				path: 'roles-management/edit/:id',
			},
			{
				component: NewsLetter,
				path: 'news-letters',
			},
			{
				component: PageSeo,
				path: 'page-seo',
			},
			{
				component: PageSeoAdd,
				path: 'page-seo/add',
			},
			{
				component: PageSeoAdd,
				path: 'page-seo/edit/:pageName',
			},
			{
				component: Country,
				path: 'country-management',
			},
			{
				component: CountryAdd,
				path: 'country-management/add',
			},
			{
				component: CountryAdd,
				path: 'country-management/edit/:id',
			},
			{
				component: AboutUs,
				path: 'about-us-management',
			},
			{
				component: BreadCrumbs,
				path: 'bread-crumbs',
			},
			{
				component: BreadCrumbsAdd,
				path: '/bread-crumbs/add',
			},
			{
				component: BreadCrumbsAdd,
				path: '/bread-crumbs/edit/:id',
			},
		],
	},
];

export default routes;
