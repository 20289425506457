import * as yup from 'yup';

// const FILE_SIZE = 5000000;
// const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const validationSchema = yup.object({
	iconSlug: yup.string().required('Icon is required.'),
	status: yup.number().required('Status is required.'),
	name: yup.string().min(2, 'Enter a valid Name').required('Name is required'),
	name_ara: yup.string().min(2, 'أدخل اسمًا صالحًا').required('مطلوب اسم'),
	imageSlug: yup.string().required('Image is required.'),
	imageSlug_ara: yup.string().when('imageSlug', {
		is: '',
		then: yup.string().required('الصورة مطلوبة'),
	}),
	role: yup.string().when('roleType', {
		is: 1,
		then: yup.string().required('Role is required.'),
	}),
	seoMetas: yup
		.string()
		.max(200, 'Description must be at most 200 characters')
		.nullable(),
});

export default validationSchema;
