/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */

import {
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
	TextField
} from '@mui/material';
import { Box } from '@mui/system';
import { Divider } from 'antd';
import { getAllBrandsWithoutPage } from 'api/brand';
import {
	editCashback,
	getCashbackById,
	getConvertedAmount
} from 'api/cashback';
import { getAllCountry } from 'api/country';
import { getCouponsForCashback } from 'api/coupon';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearData } from 'redux/cashback/cashbackSlice';
import { emptySingleCoupon } from 'redux/coupon/couponSlice';
import { RootState } from 'redux/store';
import { cashbackRewardType } from 'utils/constant';

import FormLable from '../../../components/common/FormLable';

interface GetCouponSectionProps {
	currencyCountries: any;
	setSelectedCountry: React.Dispatch<React.SetStateAction<string>>;
}
interface CashbackSectionProps {
	currencyCountries: any;
	selectedCountry: string;
	cashbackCoupons: any;
}

const UserSection = () => {
	const { singleCashback } = useSelector((state: RootState) => state.cashback);

	const formik = useFormik({
		initialValues: {
			name: singleCashback
				? `${singleCashback?.userMeta?.firstName} ${singleCashback?.userMeta?.lastName}`
				: '',
			email: singleCashback ? singleCashback?.userMeta?.email : '',
			mobile: singleCashback ? singleCashback?.userMeta?.mobile : ''
		},
		enableReinitialize: true,
		onSubmit: () => {}
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='User Name' />
						<Input
							sx={{
								marginBottom: '1rem'
							}}
							fullWidth
							id='name'
							name='name'
							disabled
							value={formik.values.name}
							onChange={formik.handleChange}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.touched.name && formik.errors.name}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='User Email' />
						<Input
							sx={{
								marginBottom: '1rem'
							}}
							fullWidth
							id='email'
							name='email'
							disabled
							value={formik.values.email}
							onChange={formik.handleChange}
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={formik.touched.email && formik.errors.email}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='User Mobile' />
						<Input
							sx={{
								marginBottom: '1rem'
							}}
							fullWidth
							id='mobile'
							name='mobile'
							disabled
							value={formik.values.mobile}
							onChange={formik.handleChange}
							error={formik.touched.mobile && Boolean(formik.errors.mobile)}
							helperText={formik.touched.mobile && formik.errors.mobile}
						/>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
};
const GetCouponSection = ({
	currencyCountries,
	setSelectedCountry
}: GetCouponSectionProps) => {
	const dispatch = useDispatch();

	const { singleCashback } = useSelector((state: RootState) => state.cashback);

	const { loadingBtn } = useSelector((state: RootState) => state.coupon);

	const { allbrandWithoutPage, loading: isBrandLoading } = useSelector(
		(state: RootState) => state.brand
	);

	useEffect(() => {
		setSelectedCountry(singleCashback?.country?.id);
	}, [singleCashback]);

	const formik = useFormik({
		initialValues: {
			brand: singleCashback?.id ? singleCashback?.brand?.id : '',
			couponCode: singleCashback?.id ? singleCashback?.coupondCode : '',
			country: singleCashback?.id ? singleCashback?.country?.id : ''
		},
		enableReinitialize: true,
		onSubmit: values => {
			setSelectedCountry(values.country);
			dispatch(
				getCouponsForCashback({
					brands: values.brand,
					countries: values.country,
					couponCode: values.couponCode
				})
			);
		}
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.brand && Boolean(formik.errors.brand)}
						>
							<FormLable label='Brand' />

							<Select
								size='small'
								fullWidth
								disabled
								id='brand'
								name='brand'
								displayEmpty
								value={formik.values.brand}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.brand && Boolean(formik.errors.brand)}
							>
								{isBrandLoading ? (
									<Box sx={{ display: 'grid', placeItems: 'center' }}>
										<Loader />
									</Box>
								) : (
									allbrandWithoutPage?.map((each: any) => (
										<MenuItem key={each?.id} value={each?.id}>
											{each?.brandTranslation?.en[0]?.name}
										</MenuItem>
									))
								)}
							</Select>
							<FormHelperText>
								{formik.touched.brand && formik.errors.brand}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Cashback Code' />
						<Input
							sx={{
								marginBottom: '1rem'
							}}
							fullWidth
							disabled
							id='couponCode'
							name='couponCode'
							value={formik.values.couponCode}
							onChange={formik.handleChange}
							error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
							helperText={formik.touched.couponCode && formik.errors.couponCode}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Country' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.country && Boolean(formik.errors.country)}
						>
							<Select
								size='small'
								fullWidth
								id='country'
								name='country'
								displayEmpty
								value={formik.values.country}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.country && Boolean(formik.errors.country)}
								disabled
							>
								{currencyCountries.map((each: any) => (
									<MenuItem key={each.id} value={each.id}>
										{
											each?.translation?.filter(
												(item: any) => item.languageCode === 'en'
											)[0].name
										}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{formik.touched.country && formik.errors.country}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
			</Box>

			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem'
				}}
				color='primary'
				variant='contained'
				loading={loadingBtn}
				type='submit'
			>
				Fetch Cashback Coupons
			</Button>
		</form>
	);
};
const CashbackSection = ({
	currencyCountries,
	selectedCountry,
	cashbackCoupons
}: CashbackSectionProps) => {
	const dispatch = useDispatch();

	const { singleCashback, loadingBtn, convertedAmount } = useSelector(
		(state: RootState) => state.cashback
	);

	const { id } = useParams();

	const formik = useFormik({
		initialValues: {
			coupons: cashbackCoupons?.length ? cashbackCoupons[0]?.id : '',
			rewardType:
				cashbackCoupons?.length > 0 ? cashbackCoupons[0]?.rewardType : '',
			spentAmout: singleCashback ? singleCashback?.spentAmout : '',
			rewardValue:
				cashbackCoupons?.length > 0 ? cashbackCoupons[0]?.rewardValue : '',
			finalAmount: singleCashback?.finalAmount || '',
			status: singleCashback?.id ? singleCashback?.status : 1,
			comment: singleCashback?.id ? singleCashback?.comment : '',
			currency: singleCashback?.id
				? singleCashback?.currency?.id
					? singleCashback?.currency?.id
					: singleCashback?.country?.id
				: ''
		},
		enableReinitialize: true,
		// validationSchema,
		onSubmit: values => {
			const backendObject = {
				couponId: values.coupons,
				brandId: singleCashback?.brand?.id,
				couponCode: singleCashback?.coupondCode,
				rewardValue: Number(values.rewardValue),
				rewardType: values.rewardType,
				spentAmout: Number(values.spentAmout),
				finalAmount: Number(values.finalAmount),
				currency: values.currency,
				country: selectedCountry,
				status: values.status,
				comment: values.comment
			};
			if (id) dispatch(editCashback({ backendObject, id }));
		}
	});

	useEffect(
		() => () => {
			dispatch(emptySingleCoupon());
			dispatch(clearData());
		},
		[]
	);

	const handleCurrencyChange = () => {
		if (!formik?.values?.spentAmout) {
			formik.setFieldError('spentAmout', 'Order amount is required.');
			return;
		}
		const coupon = cashbackCoupons?.filter(
			(item: any) => item.id === formik.values.coupons
		)[0];

		if (coupon.rewardType === 1) {
			const from = currencyCountries.filter(
				(item: any) => item.id === formik.values.currency
			)[0].currency;
			dispatch(
				getConvertedAmount({
					to: 'USD',
					from: `${from}`,
					amount: formik.values.rewardValue
				})
			);
		}
		if (coupon.rewardType === 2) {
			const from = currencyCountries.filter(
				(item: any) => item.id === formik.values.currency
			)[0].currency;
			const amount = (formik.values.rewardValue * formik.values.spentAmout) / 100;
			dispatch(
				getConvertedAmount({
					to: 'USD',
					from: `${from}`,
					amount: amount.toString()
				})
			);
		}
	};

	useEffect(() => {
		if (singleCashback?.finalAmount || convertedAmount?.result)
			formik.setFieldValue('finalAmount', convertedAmount?.result);
	}, [convertedAmount]);

	const disabledStatus = [5, 6, 7];

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.coupons && Boolean(formik.errors.coupons)}
						>
							<FormLable label='Coupons' />

							<Select
								size='small'
								fullWidth
								id='coupons'
								name='coupons'
								displayEmpty
								value={formik.values.coupons}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.coupons && Boolean(formik.errors.coupons)}
							>
								{cashbackCoupons?.map((each: any) => (
									<MenuItem key={each?.id} value={each?.id}>
										{`${each?.couponCode} (${each.country.map(
											(item: any) =>
												item.translation?.filter((itm: any) => itm.languageCode === 'en')[0]
													?.name
										)}) (Discount ${each.rewardValue}) (${
											each.rewardType === 1 ? 'Currency' : 'Percent'
										})`}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{formik.touched.coupons && formik.errors.coupons}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Discount type' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.rewardType && Boolean(formik.errors.rewardType)}
						>
							<Select
								size='small'
								fullWidth
								id='rewardType'
								name='rewardType'
								displayEmpty
								disabled
								value={formik.values.rewardType}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.rewardType && Boolean(formik.errors.rewardType)}
							>
								{cashbackRewardType.map((each, i) => (
									<MenuItem
										// eslint-disable-next-line react/no-array-index-key
										key={i}
										// eslint-disable-next-line no-unneeded-ternary
										selected={formik.values.rewardType === i + 1 ? true : false}
										value={i + 1}
									>
										{each}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{formik.touched.rewardType ? formik.errors.rewardType : ''}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Order Amount' />
						<FormControl
							sx={{ width: '100%' }}
							error={Boolean(formik.errors.spentAmout)}
						>
							<Input
								fullWidth
								id='spentAmout'
								name='spentAmout'
								type='number'
								value={formik.values.spentAmout}
								onChange={formik.handleChange}
								error={Boolean(formik.errors.spentAmout)}
								helperText={formik.touched.spentAmout && formik.errors.spentAmout}
							/>
							<FormHelperText>{formik.errors.spentAmout}</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Discount Amount' />
						<Input
							sx={{
								marginBottom: '1rem'
							}}
							fullWidth
							id='rewardValue'
							name='rewardValue'
							type='number'
							disabled
							value={formik.values.rewardValue}
							onChange={formik.handleChange}
							error={formik.touched.rewardValue && Boolean(formik.errors.rewardValue)}
							helperText={formik.touched.rewardValue && formik.errors.rewardValue}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Currency' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.currency && Boolean(formik.errors.currency)}
						>
							<Select
								size='small'
								fullWidth
								id='currency'
								name='currency'
								displayEmpty
								value={formik.values.currency}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.currency && Boolean(formik.errors.currency)}
							>
								{currencyCountries.map((each: any) => (
									<MenuItem key={each.id} value={each.id}>
										{each.currency}
									</MenuItem>
								))}
							</Select>
							<FormHelperText>
								{formik.touched.currency && formik.errors.currency}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item>
						<Button
							sx={{
								marginBottom: '1rem'
							}}
							color='primary'
							variant='contained'
							onClick={handleCurrencyChange}
							disabled={singleCashback?.status !== 1}
						>
							Get Final Amount
						</Button>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<FormLable label='Final Amount($)' />
					<Input
						sx={{
							marginBottom: '1rem'
						}}
						fullWidth
						id='finalAmount'
						name='finalAmount'
						value={formik.values.finalAmount}
						onChange={formik.handleChange}
						error={formik.touched.finalAmount && Boolean(formik.errors.finalAmount)}
						helperText={formik.touched.finalAmount && formik.errors.finalAmount}
						disabled={singleCashback?.status !== 1}
					/>
				</Grid>

				{/* Status */}

				<FormLable label='Status' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								fullWidth
								size='small'
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem
									selected={formik.values.status === 1}
									value={1}
									disabled={disabledStatus.includes(formik.values.status)}
								>
									New<span style={{ margin: '2px' }}>/</span>جديد
								</MenuItem>
								<MenuItem
									selected={formik.values.status === 2}
									value={2}
									disabled={disabledStatus.includes(formik.values.status)}
								>
									Pending From Brand<span style={{ margin: '2px' }}>/</span>معلقة من
									الماركة
								</MenuItem>
								<MenuItem
									selected={formik.values.status === 3}
									value={3}
									disabled={disabledStatus.includes(formik.values.status)}
								>
									Approved From Brand<span style={{ margin: '2px' }}>/</span>معتمد من
									الماركة
								</MenuItem>
								<MenuItem
									selected={formik.values.status === 4}
									value={4}
									disabled={disabledStatus.includes(formik.values.status)}
								>
									Rejected From Brand<span style={{ margin: '2px' }}>/</span>مرفوض من
									العلامة التجارية
								</MenuItem>
								<MenuItem selected={formik.values.status === 5} value={5}>
									Pending From Qyubic<span style={{ margin: '2px' }}>/</span>معلقة من
									Qyubic
								</MenuItem>
								<MenuItem selected={formik.values.status === 6} value={6}>
									Approved From Qyubic<span style={{ margin: '2px' }}>/</span>معتمد من
									Qyubic
								</MenuItem>
								<MenuItem selected={formik.values.status === 7} value={7}>
									Rejected From Qyubic<span style={{ margin: '2px' }}>/</span>مرفوض من
									Qyubic
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
				<FormLable label='Comment' />
				<Grid container spacing={1}>
					<TextField
						sx={{
							marginTop: '1rem',
							marginLeft: '8px'
						}}
						fullWidth
						id='comment'
						name='comment'
						multiline
						rows={2}
						maxRows={4}
						placeholder='Comment'
						onChange={formik.handleChange}
						value={formik.values.comment}
						error={formik.touched.comment && Boolean(formik.errors.comment)}
					/>
				</Grid>
			</Box>

			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem'
				}}
				color='primary'
				variant='contained'
				fullWidth
				loading={loadingBtn}
				type='submit'
			>
				Submit
			</Button>
		</form>
	);
};

const CashbackAdd = () => {
	const { loading, isUpdated, currencyLoading } = useSelector(
		(state: RootState) => state.cashback
	);

	const { singleCashback } = useSelector((state: RootState) => state.cashback);

	const { cashbackCoupons, loading: couponLoading } = useSelector(
		(state: RootState) => state.coupon
	);

	const [selectedCountry, setSelectedCountry] = useState('');

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id } = useParams();

	useEffect(() => {
		if (id) {
			dispatch(getCashbackById(id));
			dispatch(getAllBrandsWithoutPage({ page: 1, countries: [] }));
			dispatch(getAllCountry({ page: 1 }));
		}
		if (id && singleCashback?.brand?.id && singleCashback?.country?.id) {
			dispatch(
				getCouponsForCashback({
					brands: singleCashback?.brand?.id,
					countries: singleCashback?.country?.id,
					couponCode: singleCashback?.coupondCode
				})
			);
		}
	}, [id, singleCashback?.id]);

	useEffect(() => {
		if (isUpdated) {
			navigate('/cashback-management');
			dispatch(clearData());
		}
	}, [isUpdated]);

	useEffect(() => {
		if (cashbackCoupons && cashbackCoupons.length === 0) {
			toast.error('No active cashback available', {
				id: `cashbackCoupon${cashbackCoupons.length}`
			});
		}
	}, [cashbackCoupons]);

	const allCountry = useSelector((state: RootState) => state.country.allCountry);

	const currencyCountries =
		allCountry && allCountry.filter((item: any) => item.code !== 'WW');

	return (
		<TableLayout>
			<Container maxWidth='lg'>
				<Header title='Edit Cashback' />
				<Box>
					<>
						{(loading || currencyLoading || couponLoading) && (
							<Box
								sx={{
									width: '100%',
									height: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'absolute',
									backgroundColor: 'white',
									opacity: '0.7',
									zIndex: '999',
									minHeight: 'calc(100vh - 80px)'
								}}
							>
								<Loader />
							</Box>
						)}
						<UserSection />
						<Divider />
						<GetCouponSection
							currencyCountries={currencyCountries}
							setSelectedCountry={setSelectedCountry}
						/>
						<Divider />
						<CashbackSection
							currencyCountries={currencyCountries}
							selectedCountry={selectedCountry}
							cashbackCoupons={cashbackCoupons}
						/>
					</>
				</Box>
			</Container>
		</TableLayout>
	);
};

export default CashbackAdd;
