/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */

import {
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select
} from '@mui/material';
import { Box } from '@mui/system';
import { AddClients, getClientById, UpdateClient } from 'api/clients';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearData } from 'redux/client/clientSlice';
import { RootState } from 'redux/store';
import { allValues, isEmpty, usePrompt } from 'utils/propts';
import UploadImage from 'utils/UploadImage';

import FormLable from '../../../components/common/FormLable';
import validationSchema from './validation';

const WithMaterialUI = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { singleClient, loadingBtn } = useSelector(
		(state: RootState) => state.client
	);

	const [clientImg, setClientImg] = useState<string>(
		'' || singleClient?.imageSlug?.path
	);

	const handleImage = (data: string) => {
		setClientImg(data);
	};

	const formik = useFormik({
		initialValues: {
			name: singleClient ? singleClient.name : '',
			imageTitle: singleClient.imageTitle ?? '',
			imageAlt: singleClient.imageAlt ?? '',
			imageTitleArabic: singleClient.imageTitleArabic ?? '',
			imageAltArabic: singleClient.imageAltArabic ?? '',
			status: singleClient ? singleClient.status : 0,
			imageSlug: singleClient ? singleClient?.imageSlug?.slug : ''
		},
		validationSchema,
		onSubmit: values => {
			if (id) {
				const backendObject = values;
				dispatch(UpdateClient({ backendObject, id }));
			} else {
				dispatch(AddClients(values));
			}
		}
	});
	const [leavePage, setLeavePage] = useState(false);

	const setImg = (slug: string) => {
		formik.setFieldValue('imageSlug', slug);
	};

	useEffect(() => {
		allValues(formik.values)
			? setLeavePage(false)
			: setLeavePage(!isEmpty(formik.values));
	}, [formik.values]);

	usePrompt(leavePage);
	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				<FormLable label='Client Name' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Input
							sx={{
								marginBottom: '1rem'
							}}
							fullWidth
							id='name'
							name='name'
							placeholder='Enter client name'
							value={formik.values.name}
							onChange={formik.handleChange}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.touched.name && formik.errors.name}
						/>
					</Grid>
				</Grid>
				{/* Brand Logo */}

				<FormLable label='Client Image' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<UploadImage
							aspect={2 / 1}
							logo={clientImg}
							handleSlug={handleImage}
							entity='clients'
							formikImg={(s: string) => setImg(s)}
						/>
						<span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.imageSlug &&
								formik.errors.imageSlug &&
								formik.errors.imageSlug}
						</span>
					</Grid>
				</Grid>
				{/* Status */}

				<FormLable label='Status' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								fullWidth
								size='small'
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem selected={formik.values.status === 1} value={1}>
									Active<span style={{ margin: '2px' }}>/</span>صالح
								</MenuItem>
								<MenuItem selected={formik.values.status === 0} value={0}>
									Inactive<span style={{ margin: '2px' }}>/</span>غير صالح
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>

				{/* Image Title */}
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='Image Title' required={false} />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem'
							}}
							fullWidth
							multiline
							id='imageTitle'
							maxRows={4}
							name='imageTitle'
							placeholder='Image Title'
							value={formik.values.imageTitle}
							onChange={formik.handleChange}
							error={formik.touched.imageTitle && Boolean(formik.errors.imageTitle)}
							helperText={formik.touched.imageTitle && formik.errors.imageTitle}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable
							label='Image Title (arabic)'
							required={false}
							textAlign='right'
						/>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem'
							}}
							fullWidth
							multiline
							id='imageTitleArabic'
							maxRows={4}
							name='imageTitleArabic'
							dir='rtl'
							placeholder='Image Title Arabic'
							value={formik.values.imageTitleArabic}
							onChange={formik.handleChange}
							error={
								formik.touched.imageTitleArabic &&
								Boolean(formik.errors.imageTitleArabic)
							}
							helperText={
								formik.touched.imageTitleArabic && formik.errors.imageTitleArabic
							}
						/>
					</Grid>
				</Grid>

				{/* Image Alt */}
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='Image Alt' required={false} />

						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem'
							}}
							fullWidth
							id='imageAlt'
							name='imageAlt'
							multiline
							maxRows={4}
							placeholder='Image alt'
							value={formik.values.imageAlt}
							onChange={formik.handleChange}
							error={formik.touched.imageAlt && Boolean(formik.errors.imageAlt)}
							helperText={formik.touched.imageAlt && formik.errors.imageAlt}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable
							label='Image Alt (arabic)'
							required={false}
							textAlign='right'
						/>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem'
							}}
							fullWidth
							id='imageAltArabic'
							multiline
							maxRows={4}
							name='imageAltArabic'
							type='text'
							dir='rtl'
							placeholder='Image alt arabic'
							value={formik.values.imageAltArabic}
							onChange={formik.handleChange}
							error={
								formik.touched.imageAltArabic && Boolean(formik.errors.imageAltArabic)
							}
							helperText={
								formik.touched.imageAltArabic && formik.errors.imageAltArabic
							}
						/>
					</Grid>
				</Grid>
			</Box>

			<Button
				sx={{
					marginTop: '1.5rem',
					marginBottom: '1rem'
				}}
				color='primary'
				variant='contained'
				fullWidth
				loading={loadingBtn}
				type='submit'
			>
				Submit
			</Button>
		</form>
	);
};

const ClientAdd = () => {
	const { loading } = useSelector((state: RootState) => state.client);
	const { isAdded } = useSelector((state: RootState) => state.client);
	const { isUpdated } = useSelector((state: RootState) => state.client);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('/client-management');
			dispatch(clearData());
		}
	}, [isAdded, isUpdated]);
	useEffect(() => {
		if (id) {
			dispatch(getClientById(id));
		}
	}, []);

	return (
		<TableLayout>
			<Container maxWidth='lg'>
				<Header title={id ? 'Edit client' : 'Add client'} />
				<Box>
					<Box>
						{loading ? (
							<Box
								sx={{
									width: '100%',
									height: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'absolute',
									backgroundColor: 'white',
									opacity: '0.7',
									zIndex: '999',
									minHeight: 'calc(100vh - 80px)'
								}}
							>
								<Loader />
							</Box>
						) : (
							<WithMaterialUI />
							// <Box>abc</Box>
						)}
					</Box>
				</Box>
			</Container>
		</TableLayout>
	);
};

export default ClientAdd;
