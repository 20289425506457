/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
/* eslint-disable import/no-extraneous-dependencies */
import { SearchRounded } from '@mui/icons-material';
import {
	Chip,
	InputAdornment,
	MenuItem,
	Stack,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
	changeAdvertisementStatus,
	getAllAdvertisement,
	searchAdvertisement,
} from 'api/advertise';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { advertisementSlice } from 'redux/advertise/advertiseSlice';
import { RootState } from 'redux/store';
import getArabicTime from 'utils/dateConverter';
import useUrlSearchParams from 'utils/useUrlSearchParams';

interface TabPanelProps {
	children: any;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ paddingTop: '30px' }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const Advertise = () => {
	const { searchParams, setParams } = useUrlSearchParams(
		'filter',
		'couponType',
		'query'
	);
	const [prams, setPrams] = useState({
		status: searchParams?.filter ?? 0,
	});
	const [values, setValue] = useState(
		searchParams?.couponType ? Number(searchParams?.couponType) : 0
	);
	const dispatch = useDispatch();

	const [query, setQuery] = useState(searchParams?.query ?? '');

	const { allAdvertisements, pagination, loading } = useSelector(
		(state: RootState) => state.advertise
	);
	const page = pagination?.currentPage;

	const statusValue = ['Pending', 'Resolved'];

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'User Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={`${params.row.firstName} ${params.row.lastName} `}>
					<span className='textWrap'>{`${params.row.firstName} ${params.row.lastName} `}</span>
				</Tooltip>
			),
		},
		{
			field: 'email',
			headerName: 'User Email',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.email}>
					<span className='textWrap'>{params?.row?.email.toLowerCase()}</span>
				</Tooltip>
			),
		},
		{
			field: 'mobile',
			headerName: 'User Mobile',
			width: 200,
			renderCell: (params: GridValueGetterParams) => `${params.row.mobile}`,
		},
		{
			field: 'brand',
			headerName: 'Brand',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.brand || '-'}>
					<span className='textWrap'>{params.row.brand || '-'}</span>
				</Tooltip>
			),
		},
		{
			field: 'imageSlug',
			headerName: 'Store Logo',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='200px'>
					{params?.row?.imageSlug ? (
						<img
							src={`${params?.row?.imageSlug?.path}`}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = 'logo.png';
							}}
							alt=''
							width='100%'
							height='200px'
							style={{
								backgroundPosition: 'center center',
								objectFit: 'contain',
								padding: '0.5rem',
							}}
						/>
					) : (
						<img
							src='logo.png'
							alt='default logo'
							width='100%'
							height='200px'
							style={{
								backgroundPosition: 'center center',
								objectFit: 'contain',
								padding: '0.5rem',
							}}
						/>
					)}
				</Box>
			),
		},

		{
			field: 'storeName',
			headerName: 'Store Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.storeName || '-'}>
					<span className='textWrap'>{params.row.storeName || '-'}</span>
				</Tooltip>
			),
		},
		{
			field: 'storeLink',
			headerName: 'Store Link',
			width: 200,
			renderCell: (params: GridValueGetterParams) =>
				params.row.storeLink ? (
					<a
						className='textWrap'
						href={params.row.storeLink}
						target='_blank'
						rel='noreferrer'
					>
						{params.row.storeLink}
					</a>
				) : (
					<span>-</span>
				),
		},

		{
			field: 'details',
			headerName: 'Details',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.details || '-'}>
					<span className='textWrap'>{params.row.details || '-'}</span>
				</Tooltip>
			),
		},

		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={`${statusValue[params.row.status - 1]}`}
					color={`${params.row.status === 1 ? 'warning' : 'success'}`}
				/>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Input
					size='small'
					fullWidth
					id='status'
					name='status'
					value={params.row.status}
					variant='outlined'
					select
					onChange={e => {
						const sendStatus = Number(e?.target?.value);
						const id = params?.row.id;
						dispatch(
							changeAdvertisementStatus({
								id,
								sendStatus,
								couponType: values === 0 ? 2 : values === 2 ? 4 : 1,
							})
						);
					}}
				>
					<MenuItem value={1}>Pending</MenuItem>
					<MenuItem value={2}>Resolved</MenuItem>
				</Input>
			),
		},
	];

	useEffect(() => {
		if (values === 0) {
			dispatch(
				getAllAdvertisement({ 
					page:pagination?.currentPage,
					perPage:pagination?.perPage, 
					status: prams.status, 
					couponType: 2, 
					q: query })
			);
		} else if (values === 1) {
			dispatch(
				getAllAdvertisement({
					page:pagination?.currentpage,
					perPage:pagination?.perPage,
					status: prams.status,
					couponType: 1,
					q: query,
				})
			);
		} else if (values === 2) {
			dispatch(
				getAllAdvertisement({
					page:pagination?.currentpage,
					perPage:pagination?.perPage,
					status: prams.status,
					couponType: 4,
					q: query,
				})
			);
		}
	}, [prams, values,pagination.currentPage,pagination.perPage]);

	const debouncedFn = useCallback(
		debounce(q => {
			setParams('query', q);
			dispatch(
				searchAdvertisement({
					q,
					couponType: values === 0 ? 2 : values === 2 ? 4 : 1,
					page,
					status: prams.status,
				})
			);
		}, 500),
		[prams, values]
	);

	const handleChange = (event: any, newValue: number) => {
		setValue(newValue);
		setParams('couponType', newValue);
	};

	return (
		<TableLayout>
			<Header title='Advertisement Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							placeholder='Search by name, email'
							sx={{ width: '18rem' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
							onChange={(e: any) => {
								const q = e.target.value;
								setQuery(q);
								debouncedFn(q);
							}}
							value={query}
						/>

						<Input
							size='small'
							fullWidth
							id='status'
							name='status'
							variant='outlined'
							select
							value={prams.status}
							onChange={e => {
								const sendStatus = Number(e?.target?.value);
								setPrams(() => ({ ...prams, status: sendStatus }));
								setParams('filter', sendStatus);
							}}
						>
							<MenuItem value={0}>All</MenuItem>
							<MenuItem value={1}>Pending</MenuItem>
							<MenuItem value={2}>Resolved</MenuItem>
						</Input>
					</Stack>
				</Box>
			</Header>
			<Tabs value={values} onChange={handleChange} aria-label='coupon tabs'>
				<Tab label='Coupons' style={{ padding: '0px' }} />
				<Tab label='Cashback' style={{ marginLeft: '5px' }} />
				<Tab label='Gift Cards' style={{ marginLeft: '5px' }} />
			</Tabs>

			<TabPanel index={0} value={values}>
				<Table
					rows={allAdvertisements}
					columns={columns}
					rowHeight={100}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					loading={loading}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(advertisementSlice.actions.setCurrentPage(1));
						dispatch(advertisementSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(advertisementSlice.actions.setCurrentPage(value))
					}
					sx={{ height: '70vh' }}
				/>
			</TabPanel>
			<TabPanel index={1} value={values}>
				<Table
					rows={allAdvertisements}
					columns={columns}
					rowHeight={100}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					loading={loading}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(advertisementSlice.actions.setCurrentPage(1));
						dispatch(advertisementSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(advertisementSlice.actions.setCurrentPage(value))
					}
					sx={{ height: '70vh' }}
				/>
			</TabPanel>
			<TabPanel index={2} value={values}>
				<Table
					rows={allAdvertisements}
					columns={columns}
					rowHeight={100}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					loading={loading}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(advertisementSlice.actions.setCurrentPage(1));
						dispatch(advertisementSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(advertisementSlice.actions.setCurrentPage(value))
					}
					sx={{ height: '70vh' }}
				/>
			</TabPanel>
		</TableLayout>
	);
};
export default Advertise;
