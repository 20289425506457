import { Button as MuiButton, ButtonProps } from '@mui/material';

import Loader from '../Loader';

interface IButtonProps extends ButtonProps {
	loading?: boolean;
}

const Button = ({
	children,
	sx = {},
	size = 'medium',
	disabled = false,
	loading,
	...props
}: IButtonProps) => (
	<MuiButton
		sx={{
			background:
				props.variant !== 'outlined'
					? 'linear-gradient(to bottom left,#e14eca,#ba54f5)'
					: '',
			...sx,
		}}
		size={size}
		className='mui-button'
		disabled={loading || disabled}
		{...props}
	>
		{loading ? <Loader /> : children}
	</MuiButton>
);

Button.defaultProps = {
	loading: false,
};

export default Button;
