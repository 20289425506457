import { createAsyncThunk } from '@reduxjs/toolkit';
import { IBrandObject } from 'Interfaces/Brand';
import toast from 'react-hot-toast';
import { go } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllbanners = createAsyncThunk(
	'getAllbanners',
	async ({ page, statusFilter, q, pageNumber, perPage }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/banners', {
				params: {
					page,
					pageNumber,
					status: statusFilter,
					q,
					include: 'categoryMeta,brandMeta,country',
					perPage,
				},
				headers: {
					'x-locale': 'en',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const AddBanner = createAsyncThunk(
	'AddBanner',
	async (backendObject: IBrandObject, thunkAPI) => {
		try {
			const response = await axiosInstance.post(`admin/banners`, backendObject);
			if (response.status === 200) {
				toast.success('Banner Added successfully');
				thunkAPI.dispatch(go(-1));
				return response.data;
			}
			return false;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const UpdateBanner = createAsyncThunk(
	'UpdateBanner',
	async (
		{ backendObject, id }: { backendObject: IBrandObject; id: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.put(
				`admin/banners/${id}`,
				backendObject
			);
			if (response.status === 204) {
				toast.success('Banner Updated successfully');
				thunkAPI.dispatch(go(-1));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const getBannerById = createAsyncThunk(
	'getBannerById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/banners/${id}`, {
				params: {
					include: 'categoryMeta,brandMeta,country',
				},
			});
			if (response.status === 200) {
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const DeleteBanner = createAsyncThunk(
	'DeleteBanner',
	async (
		{
			id,
			sendStatus,
			statusFilter,
			query,
			pageNumber,
		}: {
			id: string;
			sendStatus: number;
			statusFilter: number;
			query: string;
			pageNumber: number;
		},
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.patch(`admin/banners/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success(
					sendStatus
						? 'Banner status changed to Active'
						: 'Banner status changed to Inactive'
				);
				const { banner }: any = thunkAPI.getState();
				const page = banner.pagination.currentPage;
				thunkAPI.dispatch(
					getAllbanners({
						page,
						isRefresh: true,
						statusFilter,
						q: query,
						pageNumber,
					})
				);
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const searchBanner = createAsyncThunk(
	'searchBanner',
	async ({ q, status, pageNumber }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/banners', {
				params: {
					q,
					status,
					pageNumber,
					include: 'categoryMeta,brandMeta,country',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
