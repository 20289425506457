/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IAuth, ILoginObject } from 'Interfaces/Auth';
import toast from 'react-hot-toast';

import axiosInstance from '../../utils/axiosInstance';

const initialState: IAuth = {
	user: {
		id: -1,
		uuid: '',
		firstName: '',
		lastName: '',
		email: '',
		mobile: '',
		password: '',
		isVerified: -1,
		isEmailSubscribed: -1,
		language: null,
		status: -1,
		createdAt: '',
		updatedAt: '',
		accessToken: '',
	},
	userData: null,
	isLoggedIn: false,
	loading: false,
	error: '',
};

export const makeLogin = createAsyncThunk(
	'makeLogin',
	async (loginObject: ILoginObject) => {
		const response = await axiosInstance.post('/admin/auth/signIn', loginObject);
		if (response.status === 200) {
			return response.data;
		}
		return response;
	}
);
// using this to update user's permissions when ever user refreshes page
// so that user doesn't have to logout and login again to see new tabs in side panel
export const updatePermission = createAsyncThunk(
	'updatePermission',
	async (id: string) => {
		const response = await axiosInstance.get(`/admin/users/${id}`);
		if (response.status === 200) {
			return response.data;
		}
		return response;
	}
);

export const authSlice = createSlice({
	name: 'counter',
	initialState,
	reducers: {
		logout: state => {
			state.isLoggedIn = false;
			state.loading = false;
			state.error = '';
			state.user = initialState.user;
			toast.success('Logout Successfully');
		},
		addUserData: (state, action) => {
			state.userData = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(makeLogin.pending, state => {
				state.loading = false;
				state.isLoggedIn = false;
			})
			.addCase(makeLogin.fulfilled, (state, action) => {
				state.loading = true;
				state.user = action.payload.data;
				state.isLoggedIn = true;
				toast.success('Logged in');
			})
			.addCase(makeLogin.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
				state.isLoggedIn = false;
			})
			.addCase(updatePermission.fulfilled, (state, action) => {
				state.userData = { ...state.userData, user: action.payload.data };
			});
	},
});

export const { logout, addUserData } = authSlice.actions;

export default authSlice.reducer;
