/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactChild, ReactChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { getFromLocal } from 'utils/localStorage';

export interface IPrivateRoute {
	children?: ReactChildren | ReactChild | any;
}

const PrivateRoute = ({ children }: IPrivateRoute) => {
	const isLoginRoute = window.location.pathname === '/auth/login';
	const isVerifyEmail = window.location.pathname === '/auth/verify-email';

	if (getFromLocal('user')?.token && (isLoginRoute || isVerifyEmail)) {
		return <Navigate to='/' />;
	}
	if (!getFromLocal('user')?.token && (isLoginRoute || isVerifyEmail)) {
		return children;
	}

	return getFromLocal('user')?.token ? children : <Navigate to='/auth/login' />;
};

PrivateRoute.defaultProps = {
	children: '',
};
export default PrivateRoute;
