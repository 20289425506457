import { AddRounded, EditRounded, SearchRounded } from '@mui/icons-material';
import {
	Box,
	Chip,
	IconButton,
	InputAdornment,
	MenuItem,
	Stack,
	Switch,
	Tooltip,
} from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DeleteCategory, getAllCategory } from 'api/categories';
import { toggleTrending } from 'api/trending';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	categorySlice,
	clearData,
	toggleLoading,
} from 'redux/category/categorySice';
import { RootState } from 'redux/store';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';
import useUrlSearchParams from 'utils/useUrlSearchParams';

const Category = () => {
	const { allCategory, pagination, loading } = useSelector(
		(state: RootState) => state.category
	);
	const { searchParams, setParams } = useUrlSearchParams('filter', 'query');
	const [statusFilter, setStatusFilter] = useState(searchParams?.filter ?? 1);
	const [query, setQuery] = useState<string>(searchParams?.query ?? undefined);

	const dispatch = useDispatch();

	const fetchCategory = (q?: string) => {
		dispatch(
			getAllCategory({
				page: pagination?.currentPage,
				statusFilter,
				q: q ?? query,
				perPage: pagination?.perPage,
			})
		);
		dispatch(clearData());
	};
	useEffect(() => {
		fetchCategory();
	}, [pagination?.currentPage, statusFilter, pagination?.perPage]);
	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Category Name',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.translation?.en[0].name}>
					<span className='textWrap'>{params.row.translation?.en[0].name}</span>
				</Tooltip>
			),
		},
		{
			field: 'slug',
			headerName: 'Category Slug',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.slug}>
					<span className='textWrap'>{params.row.slug}</span>
				</Tooltip>
			),
		},

		{
			field: 'imageSlug',
			headerName: 'Category Image',
			width: 180,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='130px' height='130px'>
					{params?.row?.translation?.en[0]?.imageSlug ? (
						<img
							src={`${params?.row?.translation?.en[0]?.imageSlug?.path}`}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = 'logo.png';
							}}
							alt=''
							width='100%'
							height='100%'
							style={{
								backgroundPosition: 'center center',
								objectFit: 'contain',
								padding: '0.5rem',
							}}
						/>
					) : (
						<img
							src='logo.png'
							alt='default logo'
							width='100%'
							height='130px'
							style={{
								backgroundPosition: 'center center',
								objectFit: 'contain',
								padding: '0.5rem',
							}}
						/>
					)}
				</Box>
			),
		},
		{
			field: 'imageSlugAra',
			headerName: 'Category Image (Arabic)',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='130px' height='130px'>
					{params?.row?.translation?.ara[0]?.imageSlug ? (
						<img
							src={`${params?.row?.translation?.ara[0]?.imageSlug?.path}`}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = 'logo.png';
							}}
							alt=''
							width='100%'
							height='100%'
							style={{
								backgroundPosition: 'center center',
								objectFit: 'contain',
								padding: '0.5rem',
							}}
						/>
					) : (
						<img
							src='logo.png'
							alt='default logo'
							width='100%'
							height='100%'
							style={{
								backgroundPosition: 'center center',
								objectFit: 'contain',
								padding: '0.5rem',
							}}
						/>
					)}
				</Box>
			),
		},
		{
			field: 'iconSlug',
			headerName: 'Category Icon',
			width: 180,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='130px' height='130px' sx={{ borderRadius: '100%' }}>
					{params.row.iconSlug ? (
						<img
							src={`${params?.row.iconSlug?.path}`}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = 'logo.png';
							}}
							alt=''
							width='100%'
							height='100%'
							style={{
								backgroundPosition: 'center center',
								objectFit: 'contain',
								padding: '0.5rem',
							}}
						/>
					) : (
						<img
							src='logo.png'
							alt='default logo'
							width='100%'
							height='100%'
							style={{
								backgroundPosition: 'center center',
								objectFit: 'contain',
								padding: '0.5rem',
							}}
						/>
					)}
				</Box>
			),
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		// {
		// 	field: 'status',
		// 	headerName: 'Status',
		// 	width: 150,
		// 	...disablefilter,
		// 	valueGetter: (params: GridValueGetterParams) =>
		// 		`${params.row.status === 1 ? 'Active' : 'Inactive'}`,
		// },
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			),
		},
		{
			field: 'trending',
			headerName: 'Trending',
			...disablefilter,
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Switch
						checked={params.row?.views[0]?.isTrending === 1}
						onChange={() => {
							const isTrending = params?.row?.views[0]?.isTrending;
							const id = params?.row?.id;
							const isTrend: number = isTrending === 1 ? 0 : 1;
							const x = {
								page: pagination.currentPage,
								entityId: id,
								entityType: 'categories',
								isTrending: isTrend,
								setState: toggleLoading,
								getAll: getAllCategory,
								statusFilter,
								query,
							};
							if (params.row?.status || (isTrending && !params.row?.status)) {
								dispatch(toggleTrending(x));
							} else {
								toast.error('First make the category active ');
							}
						}}
						color='success'
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 160,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/category-management/edit/${params.row.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
					<Switch
						checked={!!params.row.status}
						onChange={() => {
							const { id, status } = params.row;
							const sendStatus: number = status === 1 ? 0 : 1;
							dispatch(
								DeleteCategory({ id, sendStatus, statusFilter, query: query || '' })
							);
							// dispatch(toggleStatus(id));
						}}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			),
		},
	];

	const debouncedFn = useCallback(
		debounce(q => {
			fetchCategory(q);
		}, 500),
		[]
	);

	return (
		<TableLayout>
			<Header title='Category Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							size='small'
							fullWidth
							id='status'
							name='status'
							variant='outlined'
							select
							value={searchParams?.filter ?? statusFilter}
							onChange={e => {
								const sendStatus = Number(e?.target?.value);
								setStatusFilter(() => sendStatus);
								setParams('filter', sendStatus);
							}}
						>
							<MenuItem value={2}>All</MenuItem>
							<MenuItem value={1}>Active</MenuItem>
							<MenuItem value={0}>Inactive</MenuItem>
						</Input>
						<Input
							onChange={(e: any) => {
								const { value } = e.target;
								setQuery(value);
								setParams('query', value);
								debouncedFn(value);
							}}
							sx={{ width: '18rem' }}
							placeholder='Search by category name'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
							value={searchParams?.query ?? query}
						/>
						<Link to='/category-management/add'>
							<Button startIcon={<AddRounded />} variant='contained'>
								Add
							</Button>
						</Link>
						{/* <Button startIcon={<GetAppRounded />} variant='contained'>
							Export
						</Button> */}
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allCategory}
				columns={columns}
				rowHeight={115}
				loading={loading}
				total={pagination?.total}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				perPage={pagination?.perPage}
				onPerPage={(value: number) => {
					dispatch(categorySlice.actions.setCurrentPage(1));
					dispatch(categorySlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(categorySlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
		</TableLayout>
	);
};
export default Category;
