import moment from 'moment-timezone';

const useDateTimeConversion = () => {
	const convertLocaleToArabicTime = (date?: Date | null) => {
		const d = moment(date).tz('Asia/Dubai').format('YYYY-MM-DDTHH:mm:ss');
		return moment(d).toDate();
	};
	const convertGMTToArabicTime = (date?: Date | null) => {
		const d = moment(moment(date).format())
			.tz('Asia/Dubai')
			.format('YYYY-MM-DDTHH:mm:ss');
		return moment(d).toDate();
	};
	const convertToGMTTime = (date?: Date | null) =>
		moment(date).tz('Asia/Dubai', true).utc().format('YYYY-MM-DDTHH:mm:ss');

	const isDateToday = (date?: Date) =>
		moment(date).tz('Asia/Dubai', true).format('YYYY-DD-MM') ===
		moment().tz('Asia/Dubai').format('YYYY-DD-MM');

	const currentDate = moment().tz('Asia/Dubai').toDate();
	const endTime = moment().endOf('day').toDate();
	return {
		convertToArabicTime: convertLocaleToArabicTime,
		isDateToday,
		currentDate,
		endTime,
		convertToGMTTime,
		convertGMTToArabicTime,
	};
};

export default useDateTimeConversion;
