import { createSlice } from '@reduxjs/toolkit';

import {
	AddPageSeo,
	DeletePageSeo,
	getAllSeo,
	getSeoByPage,
	searchSeo,
	UpdatePageSeo,
} from '../../api/pageSeo';

const initialState: any = {
	allSeo: [],
	error: '',
	pageSeo: {},
	isAdded: false,
	isUpdated: false,
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const seoSlice = createSlice({
	name: 'pageSeo',
	initialState,
	reducers: {
		emptyPageSeo: state => {
			state.pageSeo = initialState.pageSeo;
		},
		toggleStatus: (state, action) => {
			state.allSeo = state.allSeo.map((d: any) =>
				d.id === action.payload ? { ...d, status: !d.status } : { ...d }
			);
		},
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getAllSeo.pending, (state, action) => {
				state.loading = !action.meta.arg.isRefresh;
			})
			.addCase(getAllSeo.fulfilled, (state, action) => {
				state.loading = false;
				state.allSeo = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllSeo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search user
			.addCase(searchSeo.pending, state => {
				state.loading = true;
			})
			.addCase(searchSeo.fulfilled, (state, action) => {
				state.loading = false;
				state.allSeo = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchSeo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// add brand
			.addCase(AddPageSeo.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(AddPageSeo.fulfilled, state => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(AddPageSeo.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = false;
				state.error = action.error.message;
			})

			// update

			.addCase(UpdatePageSeo.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdatePageSeo.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdatePageSeo.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;

				state.error = action.error.message;
			})

			// get single brand
			.addCase(getSeoByPage.pending, state => {
				state.loading = true;
				state.loadingBtn = false;
			})
			.addCase(getSeoByPage.fulfilled, (state, action) => {
				state.loading = false;
				state.loadingBtn = false;

				state.pageSeo = action?.payload?.data;
			})
			.addCase(getSeoByPage.rejected, (state, action) => {
				state.loading = false;
				state.loadingBtn = false;
				state.error = action.error.message;
			})

			// change status
			.addCase(DeletePageSeo.pending, state => {
				state.loading = false;
			})
			.addCase(DeletePageSeo.fulfilled, state => {
				state.loading = false;
			})
			.addCase(DeletePageSeo.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { emptyPageSeo, toggleStatus, clearData } = seoSlice.actions;

export default seoSlice.reducer;
