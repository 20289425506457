/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
/* eslint-disable import/no-extraneous-dependencies */
import { SearchRounded } from '@mui/icons-material';
import {
	Chip,
	InputAdornment,
	MenuItem,
	Stack,
	TextField,
	Tooltip,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
	changeBankTransferStatus,
	getAllBankTransfer,
	searchBankTransfer,
} from 'api/bankTransfer';
import Button from 'components/common/Button';
import FormLable from 'components/common/FormLable';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bankTransferSlice } from 'redux/bankTransfer/bankTransfer';
import { RootState } from 'redux/store';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';
import useUrlSearchParams from 'utils/useUrlSearchParams';

import styles from './bankTransfer.module.scss';

const BankTransfer = () => {
	const [reqId, setReqId] = useState('');
	const { searchParams, setParams } = useUrlSearchParams('filter');
	const [status, setStatus] = useState(searchParams?.filter ?? 1);
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [comment, setComment] = useState('');

	const { allTransfers, pagination, loading } = useSelector(
		(state: RootState) => state.bankTransfer
	);

	const statusValue = ['Pending', 'Approved', 'Rejected'];

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'User Name',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={`${params.row.user.firstName} ${params.row.user.lastName} `}
				>
					<span className='textWrap'>
						{`${params.row.user.firstName} ${params.row.user.lastName} `}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'email',
			headerName: 'User Email',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.user.email}>
					<span className='textWrap'>{params?.row?.user.email}</span>
				</Tooltip>
			),
		},
		{
			field: 'mobile',
			headerName: 'User Mobile',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => `${params.row.user.mobile}`,
		},
		{
			field: 'amount',
			headerName: 'Amount',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => `${params.row?.amount}`,
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 150,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={`${statusValue[params.row.status - 1]}`}
					color={`${
						params.row.status === 1
							? 'warning'
							: params.row.status === 2
							? 'success'
							: 'error'
					}`}
				/>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 160,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Input
					size='small'
					fullWidth
					id='status'
					name='status'
					value={params.row.status}
					variant='outlined'
					select
					onChange={e => {
						const sendStatus = Number(e?.target?.value);
						const id = params?.row.id;
						setReqId(id);
						setStatus(sendStatus);
						setParams('filter', sendStatus);

						if (sendStatus === 3) {
							setOpen(true);
						} else {
							dispatch(changeBankTransferStatus({ id, sendStatus }));
							dispatch(getAllBankTransfer({ page: pagination.currentPage }));
						}
					}}
				>
					<MenuItem value={1}>Pending</MenuItem>
					<MenuItem value={2}>Approved</MenuItem>
					<MenuItem value={3}>Rejected</MenuItem>
				</Input>
			),
		},
		{
			field: 'comment',
			headerName: 'Comment',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.comment}>
					<span className='textWrap'>{params?.row?.comment}</span>
				</Tooltip>
			),
		},
	];

	useEffect(() => {
		dispatch(
			getAllBankTransfer({
				page: pagination?.currentPage,
				status,
				perPage: pagination?.perPage,
			})
		);
	}, [pagination?.currentPage, status, pagination?.perPage, status]);

	const debouncedFn = useCallback(
		debounce(q => {
			dispatch(searchBankTransfer({ q }));
		}, 500),
		[]
	);

	const handleClose = () => {
		setOpen(false);
		setComment('');
	};

	const handleReject = () => {
		dispatch(
			changeBankTransferStatus({ id: reqId, sendStatus: status, comment })
		);
		dispatch(getAllBankTransfer({ page: pagination?.currentPage }));
		setOpen(false);
		setComment('');
	};

	return (
		<TableLayout>
			<Header title='Bank Transfer Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							placeholder='Search by name, email'
							sx={{ width: '18rem' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
							onChange={(e: any) => {
								const q = e.target.value;
								debouncedFn(q);
							}}
						/>

						<Input
							size='small'
							fullWidth
							id='status'
							name='status'
							variant='outlined'
							select
							value={status}
							onChange={e => {
								const sendStatus = Number(e?.target?.value);
								setStatus(sendStatus);
								setParams('filter', sendStatus);
							}}
						>
							<MenuItem value={0}>All</MenuItem>
							<MenuItem value={1}>Pending</MenuItem>
							<MenuItem value={2}>Approved</MenuItem>
							<MenuItem value={3}>Rejected</MenuItem>
						</Input>
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allTransfers}
				columns={columns}
				rowHeight={100}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				loading={loading}
				total={pagination?.total}
				perPage={pagination?.perPage}
				onPerPage={(value: number) => {
					dispatch(bankTransferSlice.actions.setCurrentPage(1));
					dispatch(bankTransferSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(bankTransferSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<div className={styles.modalContainer}>
					<div style={{ position: 'absolute', right: 16, top: 16 }}>
						<Button
							style={{
								all: 'unset',
								cursor: 'pointer',
								color: '#084370',
								transform: 'rotate(45deg)',
								fontSize: '2.2rem',
								left: '10%',
							}}
							onClick={handleClose}
						>
							+
						</Button>
					</div>
					<FormLable label='Comment' required={false} />
					<TextField
						sx={{
							marginTop: '1rem',
						}}
						fullWidth
						id='comment'
						name='comment'
						multiline
						rows={2}
						maxRows={4}
						placeholder='Comment'
						onChange={e => setComment(e.target.value)}
						value={comment}
						// error={!!error}
					/>
					{/* <span style={{ color: '#df0300', fontSize: '0.75rem' }}>{error}</span> */}
					<Button
						sx={{
							marginTop: '1rem',
							marginBottom: '1rem',
						}}
						color='primary'
						variant='contained'
						fullWidth
						type='submit'
						onClick={handleReject}
						loading={loading}
					>
						Submit
					</Button>
				</div>
			</Modal>
		</TableLayout>
	);
};
export default BankTransfer;
