/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';

import {
	addCategory,
	editCategory,
	getallCategorieswithoutPage,
	getAllCategory,
	getCategoryById,
	searchCategory,
} from '../../api/categories';

const initialState: any = {
	allCategory: [],
	isAdded: false,
	isUpdated: false,
	allCategorieswithoutPage: [],
	loading: false,
	loadingBtn: false,
	error: '',
	singleCategory: {
		id: '',
		iconSlug: '',
		status: 0,
		translation: {
			en: [
				{
					name: '',
					imageSlug: '',
					languageCode: '',
				},
			],
			ara: [
				{
					name: '',
					imageSlug: '',
					languageCode: '',
				},
			],
		},
	},
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const categorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {
		emptySingleCategory: state => {
			state.singleCategory = initialState.singleCategory;
		},
		toggleLoading: state => {
			state.loading = !state.loading;
		},
		// toggleStatus: (state, action) => {
		// 	state.allCategory = state.allCategory.map((d: any) =>
		// 		d.id === action.payload
		// 			? { ...d, status: !d.status, views: [{ ...d.views, isTrending: 0 }] }
		// 			: { ...d }
		// 	);
		// },
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// all category
			.addCase(getAllCategory.pending, (state, action) => {
				state.loading = !action.meta.arg.isRefresh;
			})
			.addCase(getAllCategory.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						translation: groupBy(each?.translation, 'languageCode'),
					})
				);
				state.allCategory = array;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllCategory.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(getallCategorieswithoutPage.pending, state => {
				state.loading = true;
			})
			.addCase(getallCategorieswithoutPage.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						translation: groupBy(each?.translation, 'languageCode'),
					})
				);
				state.allCategorieswithoutPage = array;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getallCategorieswithoutPage.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search category

			.addCase(searchCategory.pending, state => {
				state.loading = true;
			})
			.addCase(searchCategory.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						translation: groupBy(each?.translation, 'languageCode'),
					})
				);
				state.allCategory = array;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchCategory.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// get category by id
			.addCase(getCategoryById.pending, state => {
				state.loading = true;
			})
			.addCase(getCategoryById.fulfilled, (state, action) => {
				state.loading = false;
				state.singleCategory = {
					...action.payload.data,
					translation: groupBy(action?.payload?.data.translation, 'languageCode'),
				};
			})
			.addCase(getCategoryById.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// add category
			.addCase(addCategory.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(addCategory.fulfilled, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(addCategory.rejected, (state, action) => {
				state.loadingBtn = false;
				state.error = action.error.message;
				state.isAdded = false;
			})

			// update category

			.addCase(editCategory.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(editCategory.fulfilled, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = true;
				// window.history.back();
			})
			.addCase(editCategory.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;
				state.error = action.error.message;
			});
	},
});

export const { emptySingleCategory, toggleLoading, clearData } =
	categorySlice.actions;
// export const { emptySingleCategory, toggleLoading, toggleStatus } =
// 	categorySlice.actions;

export default categorySlice.reducer;
