import { createSlice } from '@reduxjs/toolkit';
import {
	addFaq,
	addSetting,
	getFaq,
	getSetting,
	getSettings,
} from 'api/settings';
import { groupBy } from 'lodash';

interface ISettings {
	settings: any;
	allFaqData: any;
	allFaqDataAra: any;
	loading: boolean;
	privacyBtnLoading: boolean;
	faqBtnLoading: boolean;
	getFaqLoading: boolean;
	termsBtnLoading: boolean;
	faqData: any;
	faqDataAra: any;
}

const initialState: ISettings = {
	settings: [],
	loading: false,
	faqData: {},
	faqDataAra: {},
	allFaqData: {},
	allFaqDataAra: {},
	getFaqLoading: false,
	privacyBtnLoading: false,
	faqBtnLoading: false,
	termsBtnLoading: false,
};

const apiFormattedData = (data: any) => {
	if (data.length > 0) {
		const parsed = data[0]?.translation?.map((d: { value: string }) =>
			JSON.parse(d.value)
		);
		const engTrans = parsed[0];
		const araTrans = parsed[1];
		const result: any[] = [];
		engTrans.forEach((section: any, index: any) => {
			result.push({ ...section, ...araTrans[index] });
		});
		// return result sorted by sequence
		return result.sort((a, b) => a.sequence - b.sequence);
	}
	return [];
};

export const settingSlice = createSlice({
	name: 'setting',

	initialState,
	reducers: {
		clearData: state => {
			state.privacyBtnLoading = initialState.privacyBtnLoading;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getSettings.pending, state => {
				state.loading = false;
			})
			.addCase(getSettings.fulfilled, (state, action) => {
				state.loading = true;
				const array: any = [];
				// eslint-disable-next-line no-unused-expressions
				action?.payload?.data.length > 0 &&
					action?.payload?.data?.forEach((each: any) =>
						array.push({
							...each,
							translation: groupBy(each?.translation, 'languageCode'),
						})
					);
				state.settings = groupBy(array, 'key');
			})
			.addCase(getSettings.rejected, state => {
				state.loading = false;
				state.settings = [];
			})
			.addCase(addSetting.pending, (state, action) => {
				if (action.meta.arg.data.key === 'Privacy') {
					state.privacyBtnLoading = true;
				}

				if (action.meta.arg.data.key === 'Terms') {
					state.termsBtnLoading = true;
				}
			})
			.addCase(addSetting.fulfilled, (state, action) => {
				state.privacyBtnLoading = false;
				state.faqBtnLoading = false;
				state.termsBtnLoading = false;
			})
			.addCase(addSetting.rejected, state => {
				state.privacyBtnLoading = false;
				state.faqBtnLoading = false;
				state.termsBtnLoading = false;
			})
			.addCase(addFaq.pending, (state, action) => {
				state.faqBtnLoading = true;
			})
			.addCase(addFaq.fulfilled, (state, action) => {
				state.faqBtnLoading = false;
				state.faqData = action.payload.data;
				state.faqDataAra = action.payload.data;
			})
			.addCase(addFaq.rejected, state => {
				state.faqBtnLoading = false;
				state.faqData = {};
				state.faqDataAra = {};
			})
			.addCase(getFaq.pending, state => {
				state.getFaqLoading = true;
			})
			.addCase(getFaq.fulfilled, (state, action) => {
				state.getFaqLoading = false;
				state.allFaqData = action?.payload?.data[0]?.translation?.filter(
					(item: any) => item.languageCode === 'en'
				)[0]?.value;
				state.allFaqDataAra = action?.payload?.data[0]?.translation?.filter(
					(item: any) => item.languageCode === 'ara'
				)[0]?.value;
			})
			.addCase(getFaq.rejected, (state, action) => {
				state.getFaqLoading = false;
				state.allFaqData = {};
				state.allFaqDataAra = {};
			})
			.addCase(getSetting.pending, state => {
				state.loading = false;
			})
			.addCase(getSetting.fulfilled, (state, action) => {
				state.loading = true;
				state.settings = apiFormattedData(action?.payload?.data);
			})
			.addCase(getSetting.rejected, state => {
				state.loading = false;
				state.settings = [];
			});
	},
});

export const { clearData } = settingSlice.actions;

export default settingSlice.reducer;
