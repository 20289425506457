import * as yup from 'yup';

const validationSchema = yup.object({
	name: yup
		.string()
		.min(2, 'Enter a valid Role Name')
		.required('Role Name is required'),
	status: yup.number().required('Status is required.'),
	permissions: yup.array().min(1, 'select  at least one permissions'),

	//
});

export default validationSchema;
