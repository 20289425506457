import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

import { ApiEndPoints } from './constant';

export const getAllConsumerPartners = createAsyncThunk(
	'getAllConsumerPartners',
	async ({ page, perPage }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(
				ApiEndPoints.adminConsumerPartners,
				{
					params: { page, perPage },
					headers: {
						'x-locale': 'en',
					},
				}
			);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const AddConsumerPartner = createAsyncThunk(
	'AddConsumerPartner',
	async (data: any, thunkAPI) => {
		try {
			const response = await axiosInstance.post(
				ApiEndPoints.adminConsumerPartners,
				data
			);
			if (response.status === 201) {
				toast.success('Consumer Partner added successfully');
				thunkAPI.dispatch(push('/qyubic-affiliate-management'));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const UpdateConsumerPartner = createAsyncThunk(
	'UpdateConsumerPartner',
	async ({ data, id }: { data: any; id: string }, thunkAPI) => {
		try {
			const response = await axiosInstance.put(
				`${ApiEndPoints.adminConsumerPartners}/${id}`,
				data
			);
			if (response.status === 200) {
				toast.success('Consumer Partner updated successfully');
				thunkAPI.dispatch(push('/qyubic-affiliate-management'));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const UpdateConsumerPartnerSecret = createAsyncThunk(
	'UpdateConsumerPartnersecret',
	async (id: any, thunkAPI) => {
		try {
			const response = await axiosInstance.put(
				`${ApiEndPoints.adminConsumerPartners}/${id}/secret`
			);
			if (response.status === 204) {
				toast.success('Secret updated successfully');
				thunkAPI.dispatch(push('/qyubic-affiliate-management'));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const getConsumerPartnerById = createAsyncThunk(
	'getConsumerPartnerById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(
				`${ApiEndPoints.adminConsumerPartners}/${id}`
			);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
export const getConsumerPartnerCouponsById = createAsyncThunk(
	'getConsumerPartnerCouponsById',
	async (
		{ id, page, perPage }: { id: string; page: number; perPage: number },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.get(
				`${ApiEndPoints.adminConsumerPartners}/${id}/coupons`,
				{
					params: { page, perPage },
				}
			);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const searchConsumerPartners = createAsyncThunk(
	'searchConsumerPartners',
	async ({ q }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(
				`${ApiEndPoints.adminConsumerPartners}?q=${q}`
			);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
