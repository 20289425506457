import * as yup from 'yup';

export const validationSchema = yup.object({
	topic: yup.string().required('Topic is required'),
	question: yup.string().required('Question is required'),
	answer: yup.string().required('Answer is required'),
});

export const araValidationSchema = yup.object({
	topicAra: yup.string().required('Topic is required'),
	question: yup.string().required('مطلوب سؤال'),
	answer: yup.string().required('مطلوب الإجابة'),
});
