import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

// eslint-disable-next-line import/prefer-default-export
export default createAsyncThunk('getDashboardData', async () => {
	try {
		const response = await axiosInstance.get('admin/dashboard', {
			headers: {
				'x-locale': 'en',
			},
		});

		return response.data;
	} catch (e) {
		toast.error(getGenericErrorMessage(e));
	}
	return null;
});
