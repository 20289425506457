/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
/* eslint-disable import/no-extraneous-dependencies */
import { AddRounded, EditRounded, SearchRounded } from '@mui/icons-material';
import {
	Chip,
	IconButton,
	InputAdornment,
	Stack,
	Switch,
	Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DeletePageSeo, getAllSeo, searchSeo } from 'api/pageSeo';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
// import moment from 'moment';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { seoSlice } from 'redux/pageSeo/seoSlice';
import { RootState } from 'redux/store';

//

const pages = [
	{ name: 'Home', value: 'home' },
	{ name: 'About us', value: 'aboutUs' },
	{ name: 'Contact Us', value: 'contactUs' },
	{ name: 'Advertise With Us', value: 'advertiseWithUs' },
	{ name: 'FAQ', value: 'faq' },
	{ name: 'Privacy policy', value: 'privacyPolicy' },
	{ name: 'Terms & Conditions', value: 'tnc' },
	{ name: 'Sitemap', value: 'sitemap' },
	{ name: 'Brands', value: 'brands' },
	{ name: 'Categories', value: 'categories' }
];

const PageSeo = () => {
	const [query, setQuery] = useState('');

	const dispatch = useDispatch();

	const { allSeo, pagination, loading } = useSelector(
		(state: RootState) => state.pageSeo
	);

	const columns: GridColDef[] = [
		{
			field: 'title',
			headerName: 'Title',
			width: 200,
			renderCell: (params: GridValueGetterParams) =>
				`${
					params.row.translation.filter((item: any) => item.languageCode === 'en')[0]
						.title
				}`
		},
		{
			field: 'keywords',
			headerName: 'Keywords',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={
						params.row.translation.filter(
							(item: any) => item.languageCode === 'en'
						)[0].keywords
					}
				>
					<span className='textWrap'>{`${
						params.row.translation.filter(
							(item: any) => item.languageCode === 'en'
						)[0].keywords
					}`}</span>
				</Tooltip>
			)
		},
		{
			field: 'description',
			headerName: 'Description',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={
						params.row.translation.filter(
							(item: any) => item.languageCode === 'en'
						)[0].description
					}
				>
					<span className='textWrap'>{`${
						params.row.translation.filter(
							(item: any) => item.languageCode === 'en'
						)[0].description
					}`}</span>
				</Tooltip>
			)
		},
		{
			field: 'pageName',
			headerName: 'Page Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) =>
				`${pages.filter(item => item.value === params.row?.pageName)[0].name}`
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			)
		},
		{
			field: 'action',
			headerName: 'Action',
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/page-seo/edit/${params?.row?.pageName}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
					<Switch
						checked={!!params.row.status}
						onChange={() => {
							const { status } = params.row;
							const sendStatus: number = status === 1 ? 0 : 1;

							dispatch(DeletePageSeo({ pageName: params?.row?.pageName, sendStatus }));
						}}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			)
		}
	];

	useEffect(() => {
		dispatch(
			getAllSeo({
				page: pagination?.currentPage,
				perPage: pagination?.perPage,
				status: 0
			})
		);
	}, [pagination?.currentPage, pagination?.perPage]);

	const debouncedFn = useCallback(
		debounce(q => {
			dispatch(searchSeo({ q }));
		}, 500),
		[]
	);

	useEffect(() => {
		if (query) {
			debouncedFn(query);
		} else {
			dispatch(
				getAllSeo({
					page: pagination?.currentPage,
					perPage: pagination?.perPage,
					status: 0
				})
			);
		}
	}, [query]);

	return (
		<TableLayout>
			<Header title='Page Seo'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							placeholder='Search by page name'
							sx={{ width: '18rem' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								)
							}}
							onChange={(e: any) => setQuery(e.target.value)}
						/>
						<Link to='/page-seo/add'>
							<Button startIcon={<AddRounded />} variant='contained'>
								Add
							</Button>
						</Link>
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allSeo}
				columns={columns}
				rowHeight={100}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				loading={loading}
				total={pagination?.total}
				onPerPage={(value: number) => {
					dispatch(seoSlice.actions.setCurrentPage(1));
					dispatch(seoSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(seoSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
		</TableLayout>
	);
};
export default PageSeo;
