/* eslint-disable consistent-return */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Stack } from '@mui/material';
import signIn from 'api/auth';
import Button from 'components/common/Button';
// import ErrorMsg from 'components/common/ErrorMsg';
import Input from 'components/common/Input';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addUserData } from 'redux/auth/authSlice';
import { saveToLocal } from 'utils/localStorage';
import * as yup from 'yup';

import styles from './login.module.scss';

const validationSchema = yup.object({
	email: yup.string().email('Invalid email').required('Email is required.'),
	password: yup.string().required('Password is required.'),
});

const Login = () => {
	const [loading, setLoading] = useState(false);
	const [viewPassword, setViewPassword] = useState(false);
	const navigate = useNavigate();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const dispatch = useDispatch();

	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			return;
		}

		const token = await executeRecaptcha();

		return token;
	}, [executeRecaptcha]);

	useEffect(() => {
		handleReCaptchaVerify();
	}, [handleReCaptchaVerify]);

	const handleSignIn = async (values: any) => {
		setLoading(true);

		const token = await handleReCaptchaVerify();

		const res: any = await signIn({ ...values, captcha: token });
		if (res.success) {
			saveToLocal('user', res.data);
			dispatch(addUserData(res.data));
			toast.success('Successfully loggedIn!');
			navigate('/');
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const { handleChange, errors, touched, values, handleBlur, handleSubmit } =
		useFormik({
			initialValues: {
				email: '',
				password: '',
			},
			validationSchema,
			onSubmit: val => {
				handleSignIn(val);
			},
		});

	return (
		<Box
			sx={{ width: { xs: '100%', md: '60%' }, maxWidth: 600 }}
			className={styles.LoginBox}
		>
			<img
				src='/logo.png'
				alt=''
				width='180'
				height='180'
				loading='lazy'
				style={{ objectFit: 'contain' }}
			/>

			<form
				onSubmit={handleSubmit}
				style={{
					width: '100%',
				}}
			>
				<Stack spacing={5} width='100%' mt={6}>
					<Box>
						<Input
							fullWidth
							id='email'
							name='email'
							placeholder='Enter email'
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.email && Boolean(errors.email)}
							helperText={touched.email && errors.email}
						/>

						{/* <ErrorMsg name='email' /> */}
					</Box>
					<Box>
						<Input
							fullWidth
							id='password'
							name='password'
							type={!viewPassword ? 'password' : 'text'}
							placeholder='Enter password'
							value={values.password}
							onBlur={handleBlur}
							onChange={handleChange}
							error={touched.password && Boolean(errors.password)}
							helperText={touched.password && errors.password}
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton onClick={() => setViewPassword(!viewPassword)}>
											{viewPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
						{/* <ErrorMsg name='password' /> */}
					</Box>

					<Button variant='contained' loading={loading} type='submit'>
						Login
					</Button>
				</Stack>
			</form>
		</Box>
	);
};

export default Login;
