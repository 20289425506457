// eslint-disable-next-line import/prefer-default-export
export class SocketEvents {
	static CHAT_CONNECT = 'CHAT:CONNECT';

	static CHAT_JOIN_ROOM = 'CHAT:JOIN:ROOM';

	static CHAT_LAST_READ = 'CHAT:LAST:READ';

	static CHAT_DISCONNECT = 'CHAT:DISCONNECT';

	static CHAT_MESSAGE = 'CHAT:MESSAGE';
}
