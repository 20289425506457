/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
import {
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select
} from '@mui/material';
import { Box } from '@mui/system';
import { AddTag, getTagId, UpdateTag } from 'api/tags';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { clearData } from 'redux/tags/tagSlice';

import FormLable from '../../../components/common/FormLable';
import validationSchema from './validation';

const couponTypes = [
	{ label: 'Coupon', value: 2 },
	{ label: 'Cashback', value: 1 },
	{ label: 'Offer', value: 3 }
];

const WithMaterialUI = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { singleTag, loadingBtn } = useSelector(
		(state: RootState) => state.tags
	);

	const {allbrandWithoutPage, loading: isBrandLoading } = useSelector(
		(state: RootState) => state.brand
	);

	const formik = useFormik({
		initialValues: {
			name: singleTag?.translation?.en[0]?.tag
				? singleTag?.translation?.en[0]?.tag
				: '',
			nameAra: singleTag?.translation?.ara[0]?.tag
				? singleTag?.translation?.ara[0]?.tag
				: '',
			status: singleTag ? singleTag.status : 0,
			brandId: singleTag ? singleTag.brands : [],
			couponType: singleTag ? singleTag.couponType : [],
			color: singleTag ? singleTag.color : '#000000'
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: values => {
			const backendObject = {
				brandIds: values.brandId,
				couponTypes: values.couponType,
				color: values.color ?? '#000000',
				translation: [
					{
						languageCode: 'en',
						tag: values.name
					},
					{
						languageCode: 'ara',
						tag: values.nameAra
					}
				],
				status: values.status
			};
			if (id) {
				dispatch(UpdateTag({ backendObject, id }));
			} else {
				dispatch(AddTag(backendObject));
			}
		}
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				{/* Brand Name */}

				<FormLable label='Tag' />
				<Grid container spacing={1}>
					<Grid item xs={6}>
						{/* English version field */}
						<FormControl
							sx={{ width: '100%', marginBottom: '1rem' }}
							error={formik.touched.name && Boolean(formik.errors.name)}
						>
							<Input
								fullWidth
								id='name'
								name='name'
								placeholder='Enter Tag'
								value={formik.values.name}
								onChange={formik.handleChange}
								error={formik.touched.name && Boolean(formik.errors.name)}
								helperText={formik.touched.name && formik.errors.name}
							/>
							<FormHelperText>
								{formik.touched.name && formik.errors.name}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={6}>
						{/* English version field */}
						<FormControl
							sx={{ width: '100%', marginBottom: '1rem' }}
							error={formik.touched.nameAra && Boolean(formik.errors.nameAra)}
						>
							<Input
								fullWidth
								id='nameAra'
								name='nameAra'
								dir='rtl'
								placeholder='Enter Tag'
								value={formik.values.nameAra}
								onChange={formik.handleChange}
								error={formik.touched.nameAra && Boolean(formik.errors.nameAra)}
								helperText={formik.touched.nameAra && formik.errors.nameAra}
								className='arabic-error'
							/>
							<FormHelperText>
								{formik.touched.nameAra && formik.errors.nameAra}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
				<FormLable label='Tag Color' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						{/* English version field */}

						<Input
							fullWidth
							id='color'
							type='color'
							name='color'
							value={formik.values.color}
							onChange={formik.handleChange}
							helperText={formik.touched.color && formik.errors.color}
						/>
					</Grid>
				</Grid>

				<FormLable label='Brand' />
				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							error={formik.touched.brandId && Boolean(formik.errors.brandId)}
						>
							<Select
								size='small'
								fullWidth
								id='brandId'
								name='brandId'
								displayEmpty
								multiple
								value={formik.values.brandId || []}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.brandId && Boolean(formik.errors.brandId)}
							>
								{isBrandLoading ? (
									<Box sx={{ display: 'grid', placeItems: 'center' }}>
										<Loader />
									</Box>
								) : allbrandWithoutPage
									?.filter((item: any) => item.status === 1)
									.map((each: any) => (
										<MenuItem key={each?.id} value={each?.id}>
											{each?.brandTranslation?.en[0]?.name}
										</MenuItem>
									))}
							</Select>
							<FormHelperText>
								{formik.touched.brandId && formik.errors.brandId}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
				<Grid item xs={6} className='my-grid'>
					<FormLable label='Coupon Type' />
					{/* English version field */}

					<FormControl
						sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
						error={formik.touched.couponType && Boolean(formik.errors.couponType)}
					>
						<Select
							size='small'
							fullWidth
							id='couponType'
							name='couponType'
							displayEmpty
							multiple
							value={formik.values.couponType || []}
							input={<OutlinedInput />}
							onChange={formik.handleChange}
							error={formik.touched.couponType && Boolean(formik.errors.couponType)}
						>
							{couponTypes.map((item: any) => (
								<MenuItem key={item.value} value={item.value}>
									{item.label}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>
							{formik.touched.couponType && formik.errors.couponType}
						</FormHelperText>
					</FormControl>
				</Grid>

				{/* Status */}

				<FormLable label='Status' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%', marginBottom: '1rem' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								fullWidth
								size='small'
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem selected={formik.values.status === 1} value={1}>
									Active
								</MenuItem>
								<MenuItem selected={formik.values.status === 0} value={0}>
									Inactive
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
			</Box>

			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem'
				}}
				color='primary'
				variant='contained'
				fullWidth
				loading={loadingBtn}
				type='submit'
			>
				Submit
			</Button>
		</form>
	);
};

const TagAdd = () => {
	const { loading, isAdded, isUpdated } = useSelector(
		(state: RootState) => state.tags
	);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('/tag-management');
			dispatch(clearData());
		}
	}, [isAdded, isUpdated]);
	useEffect(() => {
		if (id) {
			dispatch(getTagId(id));
		}
	}, [id]);

	return (
		<TableLayout>
			<Container maxWidth='lg'>
				<Header title={id ? 'Edit Tag' : 'Add Tag'} />
				<Box>
					<Box>
						{loading ? (
							<Box
								sx={{
									width: '100%',
									height: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'absolute',
									backgroundColor: 'white',
									opacity: '0.7',
									zIndex: '999',
									minHeight: 'calc(100vh - 80px)'
								}}
							>
								<Loader />
							</Box>
						) : (
							<WithMaterialUI />
							// <Box>abc</Box>
						)}
					</Box>
				</Box>
			</Container>
		</TableLayout>
	);
};

export default TagAdd;
