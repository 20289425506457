import * as yup from 'yup';

const validationSchema = yup.object({
	name: yup
		.string()
		.min(2, 'Enter a valid Partner Name')
		.required('Partner Name is required'),
	status: yup.number().required('Status is required.'),
});

export default validationSchema;
