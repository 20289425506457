/* eslint-disable no-alert */
import type { Blocker, History, Transition } from 'history';
import { ContextType, useCallback, useContext, useEffect } from 'react';
import {
	Navigator as BaseNavigator,
	UNSAFE_NavigationContext as NavigationContext,
} from 'react-router-dom';

interface Navigator extends BaseNavigator {
	block: History['block'];
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
	navigator: Navigator;
};

export function useBlocker(blocker: Blocker, when = true) {
	const { navigator } = useContext(
		NavigationContext
	) as NavigationContextWithBlock;

	useEffect(() => {
		if (!when) {
			return undefined;
		}

		const unblock = navigator.block((tx: Transition) => {
			const autoUnblockingTx = {
				...tx,
				retry() {
					// Automatically unblock the transition so it can play all the way
					// through before retrying it. TODO: Figure out how to re-enable
					// this block if the transition is cancelled for some reason.
					unblock();
					tx.retry();
				},
			};

			blocker(autoUnblockingTx);
		});

		return unblock;
	}, [navigator, blocker, when]);
}
const message:
	| string
	| ((
			location: Transition['location'],
			action: Transition['action']
	  ) => string) = `You have unsaved changes on this page. Press OK, if you want to leave this page and discard your changes or Press CANCEL to stay on this page?`;

export function usePrompt(when = true) {
	const blocker = useCallback(
		(tx: Transition) => {
			let response;
			if (typeof message === 'function') {
				response = message(tx.location, tx.action);
				if (typeof response === 'string') {
					response = window.confirm(response);
				}
			} else {
				response = window.confirm(message);
			}
			if (response) {
				tx.retry();
			}
		},
		[message]
	);
	return useBlocker(blocker, when);
}

export const isEmpty = (obj: object) =>
	Object.values(obj).every(x => x === null || x === '' || x === 'en' || x === 0);

export const allValues = (obj: object) =>
	Object.values(obj).every(x => x !== null && x !== '');

// export function  (str: string) {
// 	return str.replace(
// 		/\w\S*/g,
// 		txt => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
// 	);
// }
