import * as yup from 'yup';

const validationSchema = yup.object({
	name: yup
		.string()
		.min(2, 'Enter a valid Country Name')
		.required('Country Name is required'),
	name_ara: yup
		.string()
		.min(2, 'Enter a valid Country Name')
		.required('Country Name is required'),
	code: yup.string().min(2).max(3).required('Country Code is required'),
	mobileCode: yup.string().required('Mobile Code is required'),
	currency: yup.string().required('Currency is required'),
	status: yup.number().required('Status is required.'),
	flagSlug: yup.string().required('Image is required'),
});

export default validationSchema;
