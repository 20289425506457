/* eslint-disable import/no-extraneous-dependencies */
import { AddRounded, EditRounded, SearchRounded } from '@mui/icons-material';
import {
	Chip,
	IconButton,
	InputAdornment,
	Stack,
	Switch,
	Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DeleteClient, getAllClients, searchClients } from 'api/clients';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { clientSlice, emptySingleClient } from 'redux/client/clientSlice';
// import { toggleStatus } from 'redux/client/clientSlice';
import { RootState } from 'redux/store';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';

const Client = () => {
	// const [page, setPage] = useState(1);
	const dispatch = useDispatch();

	const { allclients, pagination } = useSelector(
		(state: RootState) => state.client
	);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Client name',
			width: 200,

			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.name}>
					<span className='textWrap'>{params.row.name}</span>
				</Tooltip>
			)
		},
		{
			field: 'imageSlug',
			...disablefilter,
			headerName: 'Client Image',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={params.row.imageSlug ? `${params?.row.imageSlug?.path}` : 'logo.png'}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'contain',
							padding: '0.5rem'
						}}
					/>
				</Box>
			)
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			)
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			)
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			)
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 160,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/client-management/edit/${params.row.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
					<Switch
						checked={!!params.row.status}
						onChange={() => {
							const { id, status } = params.row;
							const sendStatus: number = status === 1 ? 0 : 1;
							// dispatch(toggleStatus(id));
							dispatch(DeleteClient({ id, sendStatus }));
						}}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			)
		}
	];

	useEffect(() => {
		dispatch(
			getAllClients({
				page: pagination?.currentPage,
				perPage: pagination?.perPage
			})
		);
		dispatch(emptySingleClient());
	}, [pagination?.currentPage, pagination?.perPage]);

	const { loading } = useSelector((state: RootState) => state.client);

	const debouncedFn = useCallback(
		debounce(q => {
			dispatch(searchClients({ q }));
		}, 500),
		[]
	);
	return (
		<TableLayout>
			<Header title='Our Clients'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							placeholder='Search by client name'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								)
							}}
							sx={{ width: '18rem' }}
							onChange={(e: any) => {
								const q = e.target.value;
								debouncedFn(q);
							}}
						/>
						<Link to='/client-management/add'>
							<Button startIcon={<AddRounded />} variant='contained'>
								Add
							</Button>
						</Link>
						{/* <Button startIcon={<GetAppRounded />} variant='contained'>
							Export
						</Button> */}
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allclients}
				columns={columns}
				rowHeight={85}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				loading={loading}
				total={pagination?.total}
				perPage={pagination?.perPage}
				onPerPage={(value: number) => {
					dispatch(clientSlice.actions.setCurrentPage(1));
					dispatch(clientSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(clientSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
		</TableLayout>
	);
};
export default Client;
