/* eslint-disable no-plusplus */
// eslint-disable-next-line simple-import-sort/imports
import {
	AddRounded,
	// DeleteRounded,
	EditRounded,
	SearchRounded,
} from '@mui/icons-material';
import {
	Box,
	Chip,
	IconButton,
	InputAdornment,
	MenuItem,
	Stack,
	Switch,
	Tooltip,
} from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DeleteBrand, getAllBrand } from 'api/brand';
import { getAllCountry } from 'api/seeder';
import { toggleTrending } from 'api/trending';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	brandSlice,
	clearData,
	emptySingleBrand,
	toggleLoading,
	// toggleStatus,
} from 'redux/brand/brandSlice';
import { RootState } from 'redux/store';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';

import useUrlSearchParams from 'utils/useUrlSearchParams';
import styles from './index.module.scss';

const Brand = () => {
	const dispatch = useDispatch();
	// const [page, setPage] = useState(1);
	const { searchParams, setParams } = useUrlSearchParams('filter', 'query');
	const [statusFilter, setStatusFilter] = useState(searchParams?.filter ?? 1);
	const { allbrand, pagination, loading } = useSelector(
		(state: RootState) => state.brand
	);

	const [query, setQuery] = useState<string>(searchParams?.query ?? undefined);

	const fetchBrands = (q?: string) => {
		dispatch(
			getAllBrand({
				page: pagination?.currentPage,
				statusFilter,
				q: q ?? query,
				perPage: pagination?.perPage,
			})
		);
	};
	useEffect(() => {
		fetchBrands();
	}, [pagination?.currentPage, statusFilter, pagination?.perPage]);

	useEffect(() => {
		dispatch({ type: emptySingleBrand });
		dispatch(getAllCountry());
		dispatch(clearData());
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'name',
			...disablefilter,
			headerName: 'Brand Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.brandTranslation?.en?.[0].name}>
					<span className='textWrap'>
						{params.row.brandTranslation?.en?.[0].name}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'slug',
			...disablefilter,
			headerName: 'Brand Slug',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.slug}>
					<span className='textWrap'>{params.row.slug}</span>
				</Tooltip>
			),
		},
		{
			field: 'imageSlug',
			...disablefilter,
			headerName: 'Brand Image',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='300px' height='100px'>
					<img
						src={
							params.row.brandTranslation
								? `${params?.row?.brandTranslation?.en?.[0]?.imageSlug?.path}`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'contain',
							padding: '0.5rem',
						}}
					/>
				</Box>
			),
		},
		{
			field: 'imageSlugAra',
			...disablefilter,
			headerName: 'Brand Image (Arabic)',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='300px' height='100px'>
					<img
						src={
							params.row.brandTranslation
								? `${params?.row?.brandTranslation?.ara?.[0]?.imageSlug?.path}`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'contain',
							padding: '0.5rem',
						}}
					/>
				</Box>
			),
		},
		{
			field: 'country',
			headerName: 'Country',
			width: 200,
			renderCell: (params: GridValueGetterParams) => {
				const countryArray = params?.row?.country;
				const arr = [];
				for (let i = 0; i < countryArray.length; i++) {
					arr.push(
						countryArray[i]?.translation?.filter(
							(item: any) => item.languageCode === 'en'
						)[0]?.name
					);
				}
				return (
					<Tooltip title={arr.join(', ')}>
						<div className={styles.workBreak}>{arr.join(', ') || '-'}</div>
					</Tooltip>
				);
			},
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			),
		},
		{
			field: 'trending',
			headerName: 'Trending',
			...disablefilter,
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Switch
						checked={params.row?.views[0]?.isTrending === 1}
						onChange={() => {
							const id = params?.row?.id;
							const isTrending = params?.row?.views[0]?.isTrending;
							const isTrend: number = isTrending === 1 ? 0 : 1;
							const x = {
								page: pagination?.currentPage,
								entityId: id,
								entityType: 'brands',
								isTrending: isTrend,
								setState: toggleLoading,
								getAll: getAllBrand,
								query,
								statusFilter,
							};
							if (params.row?.status || (isTrending && !params.row?.status)) {
								dispatch(toggleTrending(x));
							} else {
								toast.error('First make the category active ');
							}
						}}
						color='success'
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			...disablefilter,
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/brand-management/edit/${params.row.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>

					<Switch
						checked={!!params.row.status}
						onChange={() => {
							const { id, status } = params.row;
							const sendStatus: number = status === 1 ? 0 : 1;
							// dispatch(toggleStatus(id));
							dispatch(
								DeleteBrand({ id, sendStatus, statusFilter, query: query || '' })
							);
						}}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			),
		},
	];

	const debouncedFn = useCallback(
		debounce(q => fetchBrands(q), 500),
		[]
	);

	return (
		<TableLayout>
			<Header title='Brand Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							size='small'
							fullWidth
							id='status'
							name='status'
							variant='outlined'
							select
							value={searchParams?.filter ?? statusFilter}
							onChange={e => {
								const sendStatus = Number(e?.target?.value);
								setStatusFilter(() => sendStatus);
								setParams('filter', sendStatus);
							}}
						>
							<MenuItem value={2}>All</MenuItem>
							<MenuItem value={1}>Active</MenuItem>
							<MenuItem value={0}>Inactive</MenuItem>
						</Input>
						<Input
							onChange={(e: any) => {
								setQuery(e.target.value);
								debouncedFn(e.target.value);
								setParams('query', e.target.value);
							}}
							sx={{ width: '18rem' }}
							value={searchParams?.query ?? query}
							placeholder='Search by brand name'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
						/>
						<Link to='/brand-management/add'>
							<Button startIcon={<AddRounded />} variant='contained'>
								Add
							</Button>
						</Link>
						{/* <Button startIcon={<GetAppRounded />} variant='contained'>
							Export
						</Button> */}
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allbrand}
				columns={columns}
				rowHeight={85}
				loading={loading}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				total={pagination?.total}
				perPage={pagination?.perPage}
				onPerPage={(value: number) => {
					dispatch(brandSlice.actions.setCurrentPage(1));
					dispatch(brandSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(brandSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
		</TableLayout>
	);
};
export default Brand;
