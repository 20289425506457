import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllCountry = createAsyncThunk(
	'getAllCountry',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get('admin/countries');
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return rejectWithValue(e?.response?.status);
		}
	}
);

export const getAllRoles = createAsyncThunk(
	'getAllRoles',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get('admin/roles');
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return rejectWithValue(e?.response?.status);
		}
	}
);
export const getAllallPermissions = createAsyncThunk(
	'getAllallPermissions',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get('admin/permissions');
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return rejectWithValue(e?.response?.status);
		}
	}
);
