import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllTags = createAsyncThunk(
	'getAllTags',
	async ({
		status,
		page,
		perPage,
		q
	}: {
		status: number;
		page: number;
		perPage: number;
		q?: string;
	}) => {
		try {
			const response = await axiosInstance.get('admin/tags', {
				params: {
					status,
					page,
					perPage,
					q,
				},
				headers: {
					'x-locale': 'en',
				},
			});

			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
			return e;
		}
	}
);

export const getTagId = createAsyncThunk(
	'getTagById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/tags/${id}`);
			if (response.status === 200) {
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const AddTag = createAsyncThunk(
	'AddTag',
	async (backendObject: any, thunkAPI) => {
		try {
			const response = await axiosInstance.post(`admin/tags`, backendObject);
			if (response.status === 200) {
				toast.success('Tag added successfully');
				thunkAPI.dispatch(push('/tag-management'));

				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const UpdateTag = createAsyncThunk(
	'UpdateTag',
	async (
		{ backendObject, id }: { backendObject: any; id: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.put(`admin/tags/${id}`, backendObject);
			if (response.status === 200) {
				toast.success('Tag updated successfully');
				thunkAPI.dispatch(push('/tag-management'));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const toggleTagStatus = createAsyncThunk(
	'toggleTagStatus',
	async (
		{
			id,
			sendStatus,
			statusFilter,
			perPage,
		}: { id: string; sendStatus: number; statusFilter: number; perPage: number },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.patch(`admin/tags/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success(
					sendStatus
						? 'Tag status changed to Active'
						: 'Tag status changed to Inactive'
				);
				// const { client }: any = thunkAPI.getState();
				// const page = client.pagination.currentPage;
				thunkAPI.dispatch(getAllTags({ status: statusFilter, page: 1, perPage }));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const searchTags = createAsyncThunk(
	'searchTags',
	async ({ q, status }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/tags', {
				params: {
					q,
					status,
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
