import { createAsyncThunk } from '@reduxjs/toolkit';
import { IBrandObject } from 'Interfaces/Brand';
import toast from 'react-hot-toast';
import { go } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllNotifications = createAsyncThunk(
	'getAllNotifications',
	async ({ page, statusFilter, q, pageNumber, perPage }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/notification', {
				params: {
					page,
					pageNumber,
					status: statusFilter,
					q,
					perPage,
				},
				headers: {
					'x-locale': 'en',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const AddNotification = createAsyncThunk(
	'AddNotification',
	async (backendObject: IBrandObject, thunkAPI) => {
		try {
			const response = await axiosInstance.post(
				`admin/notification`,
				backendObject
			);
			if (response.status === 200) {
				toast.success('Notification Added successfully');
				thunkAPI.dispatch(go(-1));
				return response.data;
			}
			return false;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const UpdateNotification = createAsyncThunk(
	'UpdateNotification',
	async (
		{ backendObject, id }: { backendObject: IBrandObject; id: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.put(
				`admin/notification/${id}`,
				backendObject
			);
			if (response.status === 200) {
				toast.success('Notification Updated successfully');
				thunkAPI.dispatch(go(-1));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const getNotificationById = createAsyncThunk(
	'getNotificationById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/notification/${id}`);
			if (response.status === 200) {
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const searchNotification = createAsyncThunk(
	'searchNotification',
	async ({ q, status, pageNumber }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/notification', {
				params: {
					q,
					status,
					pageNumber,
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const DeleteNotification = createAsyncThunk(
	'DeleteNotification',
	async (
		{
			id,
			sendStatus,
			statusFilter,
			query,
		}: {
			id: string;
			sendStatus: number;
			statusFilter: number;
			query: string;
		},
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.patch(`admin/notification/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success(
					sendStatus
						? 'Notification status changed to Active'
						: 'Notification status changed to Inactive'
				);
				const { banner }: any = thunkAPI.getState();
				const page = banner.pagination.currentPage;
				thunkAPI.dispatch(
					getAllNotifications({
						page,
						isRefresh: true,
						statusFilter,
						q: query,
					})
				);
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);
