/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
/* eslint-disable import/no-extraneous-dependencies */
import { SearchRounded } from '@mui/icons-material';
import { Chip, InputAdornment, MenuItem, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { changeContactStatus, getAllContacts } from 'api/contact';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contactSlice } from 'redux/contact/contactSlice';
import { RootState } from 'redux/store';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';
import useUrlSearchParams from 'utils/useUrlSearchParams';

const ContactUs = () => {
	const { searchParams, setParams } = useUrlSearchParams('filter', 'query');
	const [prams, setPrams] = useState({
		status: searchParams?.filter ?? 0,
		q: searchParams?.query ?? undefined,
	});
	const dispatch = useDispatch();

	const { allContacts, pagination, loading } = useSelector(
		(state: RootState) => state.contact
	);

	const statusValue = ['Pending', 'Resolved'];

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'User Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={`${params.row.firstName} ${params.row.lastName} `}>
					<span className='textWrap'>{`${params.row.firstName} ${params.row.lastName} `}</span>
				</Tooltip>
			),
		},
		{
			field: 'email',
			headerName: 'User Email',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.email}>
					<span className='textWrap'>{params?.row?.email.toLowerCase()}</span>
				</Tooltip>
			),
		},
		{
			field: 'mobile',
			headerName: 'User Mobile',
			width: 200,
			renderCell: (params: GridValueGetterParams) => `${params.row.mobile || '-'}`,
		},
		{
			field: 'companyName',
			headerName: 'Company Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.companyName || '-'}>
					<span className='textWrap'>{params.row.companyName || '-'}</span>
				</Tooltip>
			),
		},
		{
			field: 'websiteLink',
			headerName: 'Website Link',
			width: 200,
			renderCell: (params: GridValueGetterParams) =>
				`${params.row?.websiteLink || '-'}`,
		},
		{
			field: 'instagram',
			headerName: 'Instagram',
			width: 200,
			renderCell: (params: GridValueGetterParams) =>
				`${params.row?.instagram || '-'}`,
		},
		{
			field: 'shopper',
			headerName: 'Shopper/Influencer/Brand',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row?.shopper || '-'}>
					<span className='textWrap'>{params.row?.shopper || '-'}</span>
				</Tooltip>
			),
		},

		{
			field: 'designation',
			headerName: 'Designation',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.designation || '-'}>
					<span className='textWrap'>{params.row.designation || '-'}</span>
				</Tooltip>
			),
		},
		{
			field: 'message',
			headerName: 'Message',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.message}>
					<span className='textWrap'>{params.row.message || '-'}</span>
				</Tooltip>
			),
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 150,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={`${statusValue[params.row.status - 1]}`}
					color={`${
						params.row.status === 1
							? 'warning'
							: params.row.status === 2
							? 'success'
							: 'primary'
					}`}
				/>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 160,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Input
					size='small'
					fullWidth
					id='status'
					name='status'
					value={params.row.status}
					variant='outlined'
					select
					onChange={e => {
						const sendStatus = Number(e?.target?.value);
						const id = params?.row.id;
						dispatch(changeContactStatus({ id, sendStatus }));
						dispatch(
							getAllContacts({ status: prams.status, page: pagination.currentPage })
						);
					}}
				>
					<MenuItem value={1}>Pending</MenuItem>
					<MenuItem value={2}>Resolved</MenuItem>
				</Input>
			),
		},
	];
	const fetchContact = (q?: string) => {
		dispatch(
			getAllContacts({
				q: q ?? prams.q,
				status: prams.status,
				page: pagination?.currentPage,
				perPage: pagination?.perPage,
			})
		);
	};

	useEffect(() => {
		fetchContact();
	}, [pagination?.currentPage, pagination?.perPage, prams.status]);

	const debouncedFn = useCallback(
		debounce(q => {
			fetchContact(q);
		}, 500),
		[]
	);

	return (
		<TableLayout>
			<Header title='Contact Us Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							placeholder='Search by name, email'
							sx={{ width: '18rem' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
							onChange={(e: any) => {
								const q = e.target.value;
								debouncedFn(q);
								setPrams(() => ({ ...prams, q }));
								setParams('query', q);
							}}
							value={prams.q}
						/>

						<Input
							size='small'
							fullWidth
							id='status'
							name='status'
							variant='outlined'
							select
							value={prams.status}
							onChange={e => {
								const sendStatus = Number(e?.target?.value);
								setPrams(() => ({ ...prams, status: sendStatus }));
								setParams('filter', sendStatus);
							}}
						>
							<MenuItem value={0}>All</MenuItem>
							<MenuItem value={1}>Pending</MenuItem>
							<MenuItem value={2}>Resolved</MenuItem>
						</Input>
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allContacts}
				columns={columns}
				rowHeight={100}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				loading={loading}
				total={pagination?.total}
				perPage={pagination?.perPage}
				onPerPage={(value: number) => {
					dispatch(contactSlice.actions.setCurrentPage(1));
					dispatch(contactSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(contactSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
		</TableLayout>
	);
};
export default ContactUs;
