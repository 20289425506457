import { KeyboardBackspace } from '@mui/icons-material';
import { Avatar, Box, Button, Menu, MenuItem, Stack } from '@mui/material';
// import Input from 'components/common/Input';
import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeFromLocal } from 'utils/localStorage';

import styles from './index.module.scss';

const Topbar = () => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box className={styles.topBar}>
			<Button
				startIcon={<KeyboardBackspace />}
				size='small'
				onClick={() => navigate(-1)}
			>
				Go Back
			</Button>
			<a href='/' className={styles.logoWrapper}>
				<img src='/qyubic_logo.svg' alt='logo' className={styles.logo} />
			</a>
			<Stack direction='row' spacing={3} alignItems='center'>
				{/* <Input variant='standard' placeholder='Search' fullWidth={false} /> */}
				<Avatar
					sx={{ cursor: 'pointer' }}
					onClick={handleClick}
					alt='user avatar'
					src='./assets/no-user-image.jpeg'
				/>

				<Menu
					anchorEl={anchorEl}
					id='account-menu'
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				>
					<MenuItem
						onClick={() => {
							removeFromLocal('user');
							navigate('/auth/login');
						}}
					>
						Logout
					</MenuItem>
				</Menu>
			</Stack>
		</Box>
	);
};

export default Topbar;
