import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getTrendingCoupons = createAsyncThunk(
	'getTrendingCoupons',
	async ({ couponType, page, perPage }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(
				`admin/views?entityType=coupons&couponType=${couponType}`,
				{
					params: {
						include: 'country,seo',
						page,
						perPage,
						paginate: true,
					},
				}
			);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const getTrendingBrands = createAsyncThunk(
	'getTrendingBrands',
	async ({ page, perPage }: any, { rejectWithValue }) => {
		try {
			const response = await axiosInstance.get('admin/views?entityType=brands', {
				params: {
					include: 'country,seo',
					page,
					perPage,
					paginate: true,
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return rejectWithValue(e?.response?.status);
		}
	}
);

export const getTrendingCategories = createAsyncThunk(
	'getTrendingCategories',
	async ({ page, perPage }: any, { rejectWithValue }: any) => {
		try {
			const response = await axiosInstance.get(
				'admin/views?entityType=categories',
				{
					params: {
						page,
						perPage,
						paginate: true,
					},
				}
			);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return rejectWithValue(e?.response?.status);
		}
	}
);

export const toggleTrending = createAsyncThunk(
	'toggleTrending',
	async (
		{
			page,
			entityId,
			entityType,
			isTrending,
			// setState,
			getAll,
			tab,
			statusFilter,
			query,
			sortBy,
			partnerFilter,
		}: {
			page?: number;
			entityId: string;
			entityType: string;
			isTrending: number;
			// setState: any;
			getAll: any;
			tab?: number;
			statusFilter?: number;
			query?: string;
			sortBy?: string;
			partnerFilter?: number;
		},
		thunkAPI
	) => {
		try {
			// thunkAPI.dispatch(setState());
			const response = await axiosInstance.patch(`/admin/views/`, {
				entityType,
				entityId,
				isTrending,
			});
			if (response.status === 204) {
				toast.success(
					isTrending === 1
						? `${
								entityType === 'categories' ? 'category' : entityType.slice(0, -1)
						  } successfully added to trending list`
						: `${
								entityType === 'categories' ? 'category' : entityType.slice(0, -1)
						  } successfully removed from trending list`
				);

				// thunkAPI.dispatch(setState());
				thunkAPI.dispatch(
					getAll({
						page,
						couponType: tab,
						statusFilter,
						partnerFilter,
						q: query,
						sortBy,
					})
				);
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);
