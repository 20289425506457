import { createSlice } from '@reduxjs/toolkit';
import {
	getTrendingBrands,
	getTrendingCategories,
	getTrendingCoupons,
	toggleTrending,
} from 'api/trending';
import { groupBy } from 'lodash';

const initialState: any = {
	coupons: [],
	brands: [],
	categories: [],
	loading: false,
	pagination: {
		currentPage: 1,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const trendingSlice = createSlice({
	name: 'trending',
	initialState,
	reducers: {
		toggleLoading: state => {
			state.loading = !state.loading;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder

			// brands
			.addCase(getTrendingBrands.pending, state => {
				state.loading = true;
			})
			.addCase(getTrendingBrands.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						brandTranslation: groupBy(each?.brands?.brandTranslation, 'languageCode'),
					})
				);
				state.pagination = action?.payload?.meta?.pagination;
				state.brands = array;
			})
			.addCase(getTrendingBrands.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// categories
			.addCase(getTrendingCategories.pending, state => {
				state.loading = true;
			})
			.addCase(getTrendingCategories.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						translation: groupBy(each?.categories?.translation, 'languageCode'),
					})
				);
				state.pagination = action?.payload?.meta?.pagination;
				state.categories = array;
			})
			.addCase(getTrendingCategories.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// Coupons

			.addCase(getTrendingCoupons.pending, state => {
				state.loading = true;
			})
			.addCase(getTrendingCoupons.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						translation: groupBy(each?.coupons?.translation, 'languageCode'),
					})
				);
				state.pagination = action?.payload?.meta?.pagination;
				state.coupons = array;
			})
			.addCase(getTrendingCoupons.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			.addCase(toggleTrending.pending, state => {
				state.loading = true;
			})
			.addCase(toggleTrending.fulfilled, (state, action) => {
				state.loading = false;
			})
			.addCase(toggleTrending.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { toggleLoading } = trendingSlice.actions;

export default trendingSlice.reducer;
