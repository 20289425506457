/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unstable-nested-components */
import { AddRounded, EditRounded, SearchRounded } from '@mui/icons-material';
import {
	Box,
	Chip,
	debounce,
	IconButton,
	InputAdornment,
	MenuItem,
	Stack,
	Switch,
	Tooltip,
} from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { getAllBrandsWithoutPage } from 'api/brand';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'redux/store';
import { emptySingleTag, tagSlice } from 'redux/tags/tagSlice';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';
import useUrlSearchParams from 'utils/useUrlSearchParams';

import { getAllTags, toggleTagStatus } from '../../api/tags';

const couponTypes = [
	{ label: 'Coupon', value: 2 },
	{ label: 'Cashback', value: 1 },
	{ label: 'Offer', value: 3 },
];

const Tags = () => {
	const { searchParams, setParams } = useUrlSearchParams('filter', 'query');
	const [statusFilter, setStatusFilter] = useState(searchParams?.filter ?? 1);

	const [query, setQuery] = useState<string>(searchParams?.query ?? undefined);

	const dispatch = useDispatch();

	const { allTags, pagination, tagLoading } = useSelector(
		(state: RootState) => state.tags
	);

	const columns: GridColDef[] = [
		{
			field: 'tag',
			headerName: 'Tag',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.translation.en[0].tag}>
					<span className='textWrap'>{params?.row?.translation.en[0].tag}</span>
				</Tooltip>
			),
		},
		{
			field: 'brands',
			headerName: 'Brands',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.brandsTranslation.join(', ')}>
					<span className='textWrap'>
						{params?.row?.brandsTranslation.join(', ')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'couponTypes',
			headerName: 'Coupon Types',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={couponTypes
						.filter((item: any) => params?.row?.couponType.includes(item.value))
						.map((item: any) => item.label)
						.join(', ')}
				>
					<span className='textWrap'>
						{couponTypes
							.filter((item: any) => params?.row?.couponType.includes(item.value))
							.map((item: any) => item.label)
							.join(', ')}
					</span>
				</Tooltip>
			),
		},

		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			...disablefilter,
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/tag-management/edit/${params.row.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
					<Switch
						checked={!!params.row.status}
						onChange={() => {
							const { id, status } = params.row;
							const sendStatus: number = status === 1 ? 0 : 1;
							// dispatch(toggleStatus(id));
							dispatch(
								toggleTagStatus({
									id,
									sendStatus,
									statusFilter,
									perPage: pagination.perPage,
								})
							);
						}}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			),
		},
	];
	const fetchTags = (q?: string) => {
		dispatch(
			getAllTags({
				status: statusFilter,
				page: pagination?.currentPage,
				perPage: pagination?.perPage,
				q: q ?? query,
			})
		);
	};

	const debouncedFn = useCallback(
		debounce(q => {
			fetchTags(q);
		}, 500),
		[]
	);

	useEffect(() => {
		dispatch(emptySingleTag());
		dispatch(getAllBrandsWithoutPage({ page: 1, countries: [] }));
	}, []);
	useEffect(() => {
		fetchTags();
	}, [pagination?.currentPage, statusFilter, pagination?.perPage]);
	return (
		<TableLayout>
			<Header title='Tag Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							size='small'
							fullWidth
							id='status'
							name='status'
							variant='outlined'
							select
							value={statusFilter}
							onChange={e => {
								const sendStatus = Number(e?.target?.value);
								setStatusFilter(sendStatus);
								setParams('filter', sendStatus);
							}}
						>
							<MenuItem value={2}>All</MenuItem>
							<MenuItem value={1}>Active</MenuItem>
							<MenuItem value={0}>Inactive</MenuItem>
						</Input>
						<Input
							sx={{ width: '18rem' }}
							fullWidth
							onChange={(e: any) => {
								const { value } = e.target;
								setQuery(value);
								setParams('query', value);
								debouncedFn(value);
							}}
							placeholder='Search by tag'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
							value={query}
						/>
						<Link to='/tag-management/add'>
							<Button startIcon={<AddRounded />} variant='contained'>
								Add
							</Button>
						</Link>
						{/* <Button startIcon={<GetAppRounded />} variant='contained'>
							Export
						</Button> */}
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allTags}
				columns={columns}
				rowHeight={100}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				loading={tagLoading}
				total={pagination?.total}
				onPerPage={(value: number) => {
					dispatch(tagSlice.actions.setCurrentPage(1));
					dispatch(tagSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(tagSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
		</TableLayout>
	);
};
export default Tags;
