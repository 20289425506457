import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

import styles from './header.module.scss';

export interface IHeader {
	title: string;
	children?: ReactNode;
}
const Header = ({ title, children }: IHeader) => (
	<Box className={styles.header}>
		<Typography variant='h6'>{title}</Typography>
		{children}
	</Box>
);

Header.defaultProps = {
	children: '',
};

export default Header;
