import { createSlice } from '@reduxjs/toolkit';
import { INotification } from 'Interfaces/notification';
import { groupBy } from 'lodash';

import {
	AddNotification,
	DeleteNotification,
	getAllNotifications,
	getNotificationById,
	searchNotification,
	UpdateNotification,
} from '../../api/notification';

const initialState: INotification = {
	allNotifications: [],
	error: '',
	isAdded: false,
	isUpdated: false,
	singleNotification: {},
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		emptySingleNotification: state => {
			state.singleNotification = initialState.singleNotification;
		},
		toggleLoading: state => {
			state.loading = !state.loading;
		},
		toggleStatus: (state, action) => {
			state.allNotifications = state.allNotifications.map((d: any) =>
				d.id === action.payload ? { ...d, status: !d.status } : { ...d }
			);
		},
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			//  get all Banner
			.addCase(getAllNotifications.pending, (state, action) => {
				state.loading = !action.meta.arg.isRefresh;
			})
			.addCase(getAllNotifications.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						details: groupBy(each?.details, 'languageCode'),
					})
				);
				state.allNotifications = array;
				state.pagination = action?.payload?.meta?.pagination;
			})
			.addCase(getAllNotifications.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			.addCase(searchNotification.pending, state => {
				state.loading = true;
			})
			.addCase(searchNotification.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						details: groupBy(each?.details, 'languageCode'),
					})
				);
				state.allNotifications = array;
				state.pagination = action?.payload?.meta?.pagination;
			})
			.addCase(searchNotification.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search banner

			// add banner

			.addCase(AddNotification.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(AddNotification.fulfilled, state => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(AddNotification.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = false;
				state.error = action.error.message;
			})

			//  update banner

			.addCase(UpdateNotification.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdateNotification.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdateNotification.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;
				state.error = action.error.message;
			})

			// get banner
			.addCase(getNotificationById.pending, state => {
				state.loading = true;
			})
			.addCase(getNotificationById.fulfilled, (state, action) => {
				state.loading = false;

				const array = {
					...action?.payload?.data,
					details: groupBy(action?.payload?.data?.details, 'languageCode'),
				};

				state.singleNotification = array;
			})
			.addCase(getNotificationById.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// change status

			.addCase(DeleteNotification.pending, state => {
				state.loading = false;
			})
			.addCase(DeleteNotification.fulfilled, state => {
				state.loading = false;
			})
			.addCase(DeleteNotification.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const {
	emptySingleNotification,
	toggleLoading,
	toggleStatus,
	clearData,
} = notificationSlice.actions;

export default notificationSlice.reducer;
