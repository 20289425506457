export const saveToLocal = (key: string, value: any) => {
	if (typeof window === 'undefined') return;
	localStorage.setItem(key, JSON.stringify(value));
};

export const removeFromLocal = (key: string) => {
	if (typeof window === 'undefined') return;
	localStorage.removeItem(key);
};

export const getFromLocal = (key: string) => {
	if (typeof window === 'undefined') return null;
	const data = localStorage.getItem(key);
	if (data) return JSON.parse(data);
	return undefined;
};
