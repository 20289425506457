import { createSlice } from '@reduxjs/toolkit';

import {
	changeContactStatus,
	getAllContacts,
	searchContact,
} from '../../api/contact';

const initialState: any = {
	allContacts: [],
	error: '',
	filterByStatus: -1,
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const contactSlice = createSlice({
	name: 'contact',
	initialState,
	reducers: {
		setFilterByStatus: (state, action) => {
			state.filterByStatus = action.payload.status;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getAllContacts.pending, state => {
				state.loading = true;
			})
			.addCase(getAllContacts.fulfilled, (state, action) => {
				state.loading = false;
				state.allContacts = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllContacts.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search user
			.addCase(searchContact.pending, state => {
				state.loading = true;
			})
			.addCase(searchContact.fulfilled, (state, action) => {
				state.loading = false;
				state.allContacts = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchContact.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			.addCase(changeContactStatus.pending, state => {
				state.loading = true;
			})
			.addCase(changeContactStatus.fulfilled, state => {
				state.loading = false;
			})
			.addCase(changeContactStatus.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { setFilterByStatus } = contactSlice.actions;

export default contactSlice.reducer;
