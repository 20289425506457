import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

// eslint-disable-next-line import/prefer-default-export
export const verifyEmail = createAsyncThunk(
	'verifyEmail',
	async ({ token }: any) => {
		try {
			// const fd = new FormData();
			const response = await axiosInstance.post('/customer/auth/verify-email', {
				token,
			});

			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);
