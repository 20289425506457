import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllClients = createAsyncThunk(
	'getAllClients',
	async ({ page, perPage }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/clients', {
				params: { page, perPage },
				headers: {
					'x-locale': 'en',
				},
			});

			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const AddClients = createAsyncThunk(
	'AddClients',
	async (backendObject: any, thunkAPI) => {
		try {
			const response = await axiosInstance.post(`admin/clients`, backendObject);
			if (response.status === 201) {
				toast.success('Client added successfully');
				thunkAPI.dispatch(push('/client-management'));

				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const UpdateClient = createAsyncThunk(
	'UpdateClient',
	async (
		{ backendObject, id }: { backendObject: any; id: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.put(
				`admin/clients/${id}`,
				backendObject
			);
			if (response.status === 200) {
				toast.success('Clients updated successfully');
				thunkAPI.dispatch(push('/client-management'));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const DeleteClient = createAsyncThunk(
	'DeleteClient',
	async ({ id, sendStatus }: { id: string; sendStatus: number }, thunkAPI) => {
		try {
			const response = await axiosInstance.patch(`admin/clients/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success(
					sendStatus
						? 'Client status changed to Active'
						: 'Client status changed to Inactive'
				);
				const { client }: any = thunkAPI.getState();
				const page = client.pagination.currentPage;
				thunkAPI.dispatch(getAllClients({ page, isRefresh: true }));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const getClientById = createAsyncThunk(
	'getClientById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/clients/${id}`);
			if (response.status === 200) {
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const searchClients = createAsyncThunk(
	'searchClients',
	async ({ q }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/clients?q=${q}`);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
