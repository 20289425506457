import * as yup from 'yup';

const validationSchema = yup.object({
	category: yup.array().min(1, 'Select atleast one category'),
	brand: yup.string().required('Select atleast one brand'),
	// title: yup.string().required('Title is required'),
	// title_ara: yup.string().required('العنوان مطلوب'),
	description: yup
		.string()
		.required('Description is required')
		.max(200, 'Description cannot be more than 200 characters'),
	description_ara: yup
		.string()
		.required('الوصف مطلوب')
		.max(200, 'لا يمكن أن يكون الوصف أكثر من 200 حرفًا'),
	partnerType: yup.number().required('Partner is required.'),
	// partnerName: yup.string().required('Affiliate Partner is required.'),
	partner: yup.string().when('partnerType', {
		is: (o: number) => o === 1,
		then: yup.string().required('Affiliate Partner is required.'),
	}),
	status: yup.number().required('Status is required.'),
	couponType: yup.number().required('Coupon type is required'),
	discount_type: yup.number().required('Discount type is required.'),
	discount_amount: yup.number().when('discount_type', {
		is: (o: number) => o === 3,
		then: yup.number().optional().nullable(),
		otherwise: yup
			.number()
			.test(
				'len',
				'Max 05 length of number required',
				val => (val ?? '')?.toString().length <= 5
			)
			.typeError('Discount amount is required.')
			.required('Discount amount is required.'),
	}),
	country: yup.array().min(1, 'select atleast one country'),
	seoKeywords: yup.string().nullable(),
	end_date: yup.string().required('End date is required.'),

	start_date: yup.string().required('Start date is required.'),

	coupon_code: yup.string().when('couponType', {
		is: (o: number) => o === 1 || o === 2,

		then: yup
			.string()
			.required('Required')
			.min(2, 'Coupon code should have minimum 2 characters')
			.max(10, 'Coupon code can have maximum 10 characters')
			.matches(/^(\S+$)/g, 'Space not allowed'),
		otherwise: yup.string().matches(/^(\S+$)/g, 'Space not allowed'),
	}),
	website: yup
		.string()
		.required('Website is required.')
		.matches(
			/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/,
			'Link must start with http:// or https://'
		),
	text: yup.string().when('discount_type', {
		is: (o: number) => o === 3,
		then: yup.string().max(50).required('Text is required'),
		otherwise: yup.string().max(50).nullable(),
	}),
	text_ara: yup.string().when('discount_type', {
		is: (o: number) => o === 3,
		then: yup.string().max(50).required('arabic text is required'),
		otherwise: yup.string().max(50).nullable(),
	}),
});

export default validationSchema;
