import toast from 'react-hot-toast';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

const signIn = async (user: { email: string; password: string }) => {
	try {
		const response = await axiosInstance.post('/admin/auth/signIn', user);
		return response.data;
	} catch (e: any) {
		toast.error(getGenericErrorMessage(e));
		return e.response.data;
	}
};

export default signIn;
