/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
import {
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select
} from '@mui/material';
import { Box } from '@mui/system';
import { AddPartners, getPartnerById, UpdatePartner } from 'api/partners';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearData } from 'redux/partner/partnerSlice';
import { RootState } from 'redux/store';
import { allValues, isEmpty, usePrompt } from 'utils/propts';

import FormLable from '../../../components/common/FormLable';
import validationSchema from './validation';

const WithMaterialUI = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const { singlePartner, loadingBtn } = useSelector(
		(state: RootState) => state.partner
	);

	const formik = useFormik({
		initialValues: {
			name: id && singlePartner ? singlePartner.name : '',
			status: id && singlePartner ? singlePartner.status : 0
		},
		validationSchema,
		onSubmit: values => {
			if (id) {
				const backendObject = values;
				dispatch(UpdatePartner({ backendObject, id }));
			} else {
				dispatch(AddPartners(values));
			}
		}
	});
	const [leavePage, setLeavePage] = useState(false);

	useEffect(() => {
		allValues(formik.values)
			? setLeavePage(false)
			: setLeavePage(!isEmpty(formik.values));
	}, [formik.values]);

	usePrompt(leavePage);
	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				<FormLable label='Partner Name' />
				<Grid container spacing={1}>
					<Grid item xs={12} sx={{ marginBottom: '1rem' }}>
						<Input
							fullWidth
							id='name'
							name='name'
							placeholder='Enter Partner name'
							value={formik.values.name}
							onChange={formik.handleChange}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.touched.name && formik.errors.name}
						/>
					</Grid>
				</Grid>

				{/* Status */}

				<FormLable label='Status' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								fullWidth
								size='small'
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem selected={formik.values.status === 1} value={1}>
									Active<span style={{ margin: '2px' }}>/</span>صالح
								</MenuItem>
								<MenuItem selected={formik.values.status === 0} value={0}>
									Inactive<span style={{ margin: '2px' }}>/</span>غير صالح
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
			</Box>

			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem'
				}}
				color='primary'
				variant='contained'
				fullWidth
				loading={loadingBtn}
				type='submit'
			>
				Submit
			</Button>
		</form>
	);
};

const PartnerAdd = () => {
	const { loading } = useSelector((state: RootState) => state.partner);
	const { isAdded } = useSelector((state: RootState) => state.partner);
	const { isUpdated } = useSelector((state: RootState) => state.partner);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('/affiliate-management');
			dispatch(clearData());
		}
	}, [isAdded, isUpdated]);
	useEffect(() => {
		if (id) {
			dispatch(getPartnerById(id));
		}
	}, []);

	return (
		<TableLayout>
			<Container maxWidth='lg'>
				<Header title={id ? 'Edit Partner' : 'Add Partner'} />
				<Box>
					<Box>
						{loading ? (
							<Box
								sx={{
									width: '100%',
									height: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'absolute',
									backgroundColor: 'white',
									opacity: '0.7',
									zIndex: '999',
									minHeight: 'calc(100vh - 80px)'
								}}
							>
								<Loader />
							</Box>
						) : (
							<WithMaterialUI />
							// <Box>abc</Box>
						)}
					</Box>
				</Box>
			</Container>
		</TableLayout>
	);
};

export default PartnerAdd;
