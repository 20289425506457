import {
	Chat as ChatIcon,
	EditRounded,
	SearchRounded
} from '@mui/icons-material';
import { Box, IconButton, InputAdornment, Stack, Switch } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { GridValueGetterParams } from '@mui/x-data-grid';
import {
	getAllChatThreads,
	searchUserThread,
	updateChatStatus
} from 'api/chatThreads';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import moment from 'moment';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { chatThreadsSlice } from 'redux/chat/chatThreadsSlice';
import { RootState } from 'redux/store';

import ChatModal from './ChatBox/ChatModal';

const Chat = () => {
	const dispatch = useDispatch();
	const allChatThreds = useSelector(
		(state: RootState) => state.chatThreads.chatThreads
	);
	const pagination = useSelector(
		(state: RootState) => state.chatThreads.pagination
	);
	const loading = useSelector((state: RootState) => state.chatThreads.loading);
	const [query, setQuery] = useState<string>();
	const [threadDetails, setThreadDetails] = useState<any>();

	const columns: any = [
		{
			field: 'firstName',
			headerName: 'First Name',
			width: 180,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.user?.firstName}>
					<span className='textWrap'>{params?.row?.user?.firstName}</span>
				</Tooltip>
			)
		},
		{
			field: 'lastName',
			headerName: 'Last Name',
			width: 180,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.user?.lastName}>
					<span className='textWrap'>{params?.row?.user?.lastName}</span>
				</Tooltip>
			)
		},
		{
			field: 'email',
			headerName: 'Email',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.user?.email}>
					<span className='textWrap'>{params?.row?.user?.email}</span>
				</Tooltip>
			)
		},
		{
			field: 'mobile',
			headerName: 'Mobile',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.user?.mobile}>
					<span className='textWrap'>{params?.row?.user?.mobile}</span>
				</Tooltip>
			)
		},
		{
			field: 'country',
			headerName: 'Country',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.user?.userAddress?.country?.code}>
					<span className='textWrap'>
						{params?.row?.user?.userAddress?.country?.code}
					</span>
				</Tooltip>
			)
		},
		{
			field: 'lastMessage',
			headerName: 'Last Message',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.meta?.lastMessage?.message ?? '-'}>
					<span className='textWrap'>
						{params?.row?.meta?.lastMessage?.message ?? '-'}
					</span>
				</Tooltip>
			)
		},
		{
			field: 'lastMessageOn',
			headerName: 'Last Message On',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.meta?.lastMessage?.createdAt ?? '-'}>
					<span className='textWrap'>
						{params?.row?.meta?.lastMessage?.createdAt
							? moment(params?.row?.meta?.lastMessage?.createdAt).format(
									'DD MMM, yyyy'
							  )
							: '-'}
					</span>
				</Tooltip>
			)
		},
		{
			field: 'active',
			headerName: 'Chat Status',
			width: 200,
			renderCell: (params: GridValueGetterParams, index: number) => (
				<Switch
					checked={!!params?.row?.status}
					onChange={() => {
						dispatch(
							updateChatStatus({
								threadId: params?.row?.id,
								status: params?.row?.status === 1 ? 0 : 1
							})
						);
						dispatch(
							chatThreadsSlice.actions.updateThreadStatus({
								id: params?.row?.id,
								status: params?.row?.status === 1 ? 0 : 1
							})
						);
					}}
					color='success'
					inputProps={{ 'aria-label': 'controlled' }}
				/>
			)
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Box>
					<IconButton
						sx={{ marginRight: '1em' }}
						type='button'
						onClick={() => setThreadDetails(params?.row)}
					>
						<ChatIcon />
					</IconButton>
					<Link to={`/user-management/edit/${params?.row?.user.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
				</Box>
			)
		}
	];

	useEffect(() => {
		dispatch(
			getAllChatThreads({
				page: pagination?.currentPage,
				perPage: pagination?.perPage
			})
		);
	}, [pagination?.currentPage, pagination?.perPage]);

	const debouncedFn = useCallback(
		debounce(q => {
			dispatch(searchUserThread({ q }));
		}, 500),
		[]
	);

	useEffect(() => {
		if (query !== undefined) {
			debouncedFn(query);
		}
	}, [query]);
	const onModalClose = () => {
		setThreadDetails(undefined);
	};

	return (
		<>
			<TableLayout>
				<Header title='Chat Management'>
					<Box>
						<Stack direction='row' spacing={2} alignItems='center'>
							<Input
								onChange={(e: any) => setQuery(e.target.value)}
								sx={{ width: '18rem' }}
								placeholder='Search by user'
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<SearchRounded />
										</InputAdornment>
									)
								}}
							/>
						</Stack>
					</Box>
				</Header>

				<Table
					rows={allChatThreds}
					columns={columns}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					rowCount={pagination?.perPage}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(chatThreadsSlice.actions.setCurrentPage(1));
						dispatch(chatThreadsSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(chatThreadsSlice.actions.setCurrentPage(value))
					}
					sx={{ height: '70vh' }}
					loading={loading}
				/>
			</TableLayout>
			{threadDetails !== undefined ? (
				<ChatModal threadDetails={threadDetails} onClose={() => onModalClose()} />
			) : null}
		</>
	);
};
export default Chat;
