import { createSlice } from '@reduxjs/toolkit';
import {
	addUser,
	getAllUsers,
	getUserById,
	searchUser,
	toggleActiveUser,
	UpdateUser,
} from 'api/users';
import { IUsers } from 'Interfaces/Users';

const initialState: IUsers = {
	users: [],
	isAdded: false,
	isUpdated: false,
	loading: false,
	loadingBtn: false,
	error: '',
	singleUser: {},
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
	exportLoading: false,
};

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		emptySingleUser: state => {
			state.singleUser = initialState.singleUser;
		},
		// toggleStatus: (state, action) => {
		// 	state.users = state.users.map((d: any) =>
		// 		d.id === action.payload ? { ...d, status: !d.status } : { ...d }
		// 	);
		// },
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
		setUserExportLoading: (state, { payload }) => {
			state.exportLoading = payload;
		},
	},
	extraReducers(builder) {
		builder
			// all users
			.addCase(getAllUsers.pending, (state, action) => {
				state.loading = !action.meta.arg.isRefresh;
			})
			.addCase(getAllUsers.fulfilled, (state, action) => {
				state.loading = false;
				state.users = action.payload?.data;
				state.pagination = action?.payload?.meta?.pagination;
			})
			.addCase(getAllUsers.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search user

			.addCase(searchUser.pending, state => {
				state.loading = true;
			})
			.addCase(searchUser.fulfilled, (state, action) => {
				state.loading = false;
				state.users = action.payload?.data;
				state.pagination = action?.payload?.meta?.pagination;
			})
			.addCase(searchUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// add user
			.addCase(addUser.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(addUser.fulfilled, state => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(addUser.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = false;
				state.error = action.error.message;
			})

			// update users

			.addCase(UpdateUser.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdateUser.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdateUser.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;
				state.error = action.error.message;
			})

			// toggle status
			.addCase(toggleActiveUser.pending, state => {
				state.loading = false;
			})
			.addCase(toggleActiveUser.fulfilled, state => {
				state.loading = false;
			})
			.addCase(toggleActiveUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// get user by id
			.addCase(getUserById.pending, state => {
				state.loading = true;
			})
			.addCase(getUserById.fulfilled, (state, action) => {
				state.loading = false;
				state.singleUser = action.payload.data;
			})
			.addCase(getUserById.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { emptySingleUser, clearData } = usersSlice.actions;
// export const { emptySingleUser, toggleStatus } = usersSlice.actions;

export default usersSlice.reducer;
