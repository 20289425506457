import { createSlice } from '@reduxjs/toolkit';

import {
	AddClients,
	DeleteClient,
	getAllClients,
	getClientById,
	searchClients,
	UpdateClient,
} from '../../api/clients';

const initialState: any = {
	allclients: [],
	isAdded: false,
	isUpdated: false,
	error: '',
	singleClient: {},
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const clientSlice = createSlice({
	name: 'client',
	initialState,
	reducers: {
		emptySingleClient: state => {
			state.singleClient = initialState.singleClient;
		},
		toggleStatus: (state, action) => {
			state.allclients = state.allclients.map((d: any) =>
				d.id === action.payload ? { ...d, status: !d.status } : { ...d }
			);
		},
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getAllClients.pending, (state, action) => {
				state.loading = !action.meta.arg.isRefresh;
			})
			.addCase(getAllClients.fulfilled, (state, action) => {
				state.loading = false;
				state.allclients = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllClients.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search user
			.addCase(searchClients.pending, state => {
				state.loading = true;
			})
			.addCase(searchClients.fulfilled, (state, action) => {
				state.loading = false;
				state.allclients = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchClients.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// add brand
			.addCase(AddClients.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(AddClients.fulfilled, state => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(AddClients.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = false;
				state.error = action.error.message;
			})

			// update

			.addCase(UpdateClient.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdateClient.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdateClient.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;
				state.error = action.error.message;
			})

			// get single brand
			.addCase(getClientById.pending, state => {
				state.loading = true;
			})
			.addCase(getClientById.fulfilled, (state, action) => {
				state.loading = false;
				state.singleClient = action?.payload?.data;
			})
			.addCase(getClientById.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// change status
			.addCase(DeleteClient.pending, state => {
				state.loading = false;
			})
			.addCase(DeleteClient.fulfilled, state => {
				state.loading = false;
			})
			.addCase(DeleteClient.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { emptySingleClient, toggleStatus, clearData } =
	clientSlice.actions;

export default clientSlice.reducer;
