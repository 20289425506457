/* eslint-disable no-unused-expressions */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import {
	Box,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
	Typography,
} from '@mui/material';
import Container from '@mui/material/Container';
import { getAllRoles } from 'api/roles';
import { addUser, getUserById, UpdateUser } from 'api/users';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import MapContainer from 'components/common/MapContainer';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { clearData } from 'redux/users/usersSlice';
import * as yup from 'yup';

import FormLable from '../../../components/common/FormLable';
// import { allValues, isEmpty, usePrompt } from '../../../utils/propts';
// import styles from './index.module.scss';

const validationSchema = yup.object({
	firstName: yup
		.string()
		.min(2, 'Enter a valid name')
		.required('First name required.'),
	lastName: yup
		.string()
		.min(2, 'Enter a valid name')
		.required('Last name required.'),
	email: yup
		.string()
		.email('Enter a valid email')
		.required('Email is required.'),
	mobile: yup.string().required('Mobile is required.'),
	// .min(8, 'Enter a valid mobile number')
	// .max(15, 'Enter a valid mobile number'),
	address: yup.string().required('Address required.'),
	lat: yup.number().required('Latitude required.'),
	long: yup.number().required('Longitude required.'),
	status: yup.number().required('Status is required.'),
	roleType: yup.number().required('Role type is required.'),
	role: yup.string().when('roleType', {
		is: 1,
		then: yup.string().required('Role is required.'),
	}),
	countryId: yup.string().required('Country is required.'),
});

const WithMaterialUI = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { singleUser, loadingBtn } = useSelector(
		(state: RootState) => state.users
	);

	const [lat, setLat] = useState('');
	const [long, setLong] = useState('');

	useEffect(() => {
		dispatch(getAllRoles({}));
	}, []);

	const allCountry = useSelector((state: RootState) => state.country.allCountry);
	const allRoles = useSelector((state: RootState) => state.role.allRoles);

	const formik = useFormik({
		initialValues: {
			firstName: singleUser?.firstName || '',
			lastName: singleUser?.lastName || '',
			email: singleUser?.email || '',
			mobile: singleUser?.mobile || '',
			address: singleUser?.userAddress?.address || '',
			language: 'en',
			status: id && singleUser?.status ? singleUser?.status : 0 || 0,
			// eslint-disable-next-line no-unsafe-optional-chaining
			roleType: (singleUser?.roles && singleUser?.roles[0]?.type) || 0,
			role: (singleUser?.roles && singleUser?.roles[0]?.id) || '',
			countryId: singleUser?.userAddress?.country?.id || '',
			long: singleUser?.userAddress?.long || long,
			lat: singleUser?.userAddress?.lat || lat,
		},
		validationSchema,
		onSubmit: values => {
			if (id) {
				const updateObject: any = {
					firstName: values.firstName,
					lastName: values.lastName,
					// email: values.email,
					// mobile: values.mobile,
					language: 'en',
					address: values.address,
					roleType: values.roleType,
					roleId: values.role,
					countryId: values.countryId,
					status: values.status,
					long: values.long,
					lat: values.lat,
				};
				dispatch(UpdateUser({ updateObject, id }));
			} else {
				const backendObject: any = {
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					mobile: values.mobile,
					language: 'en',
					address: values.address,
					roleType: values.roleType,
					roleId: values.role,
					countryId: values.countryId,
					status: values.status,
					long: values.long,
					lat: values.lat,
				};
				dispatch(addUser(backendObject));
			}
		},
	});

	function success(position: any) {
		setLat(position.coords.latitude.toString());
		setLong(position.coords.longitude.toString());

		formik.setFieldValue('lat', position.coords.latitude.toString());
		formik.setFieldValue('long', position.coords.longitude.toString());
	}

	function error(err: any) {
		setLat('28.77');
		setLong('56.22');

		formik.setFieldValue('lat', '28.77');
		formik.setFieldValue('long', '56.22');
	}

	useEffect(() => {
		if (singleUser?.userAddress?.long && singleUser?.userAddress?.lat) {
			setLong(singleUser?.userAddress?.long);
			setLat(singleUser?.userAddress?.lat);

			return;
		}

		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(success, error);
		}
	}, [navigator, singleUser]);

	// const [leavePage, setLeavePage] = useState(false);

	// useEffect(() => {
	// 	allValues(formik.values)
	// 		? setLeavePage(false)
	// 		: setLeavePage(!isEmpty(formik.values));
	// }, [formik.values]);

	// usePrompt(leavePage);

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box sx={{ paddingBottom: '26px' }}>
				<Grid container rowSpacing={4} columnSpacing={3}>
					<Grid item xs={4}>
						{/* English version field */}
						<Typography
							sx={{
								marginTop: '.5rem',
								marginBottom: '.5rem',
							}}
							variant='subtitle2'
						>
							First Name
						</Typography>
						<Input
							fullWidth
							id='firstName'
							name='firstName'
							type='text'
							onBlur={formik.handleBlur}
							value={formik.values.firstName}
							onChange={formik.handleChange}
							error={formik.touched.firstName && Boolean(formik.errors.firstName)}
							helperText={formik.touched.firstName && formik.errors.firstName}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* English version field */}
						<Typography
							sx={{
								marginTop: '.5rem',
								marginBottom: '.5rem',
							}}
							variant='subtitle2'
						>
							Last Name
						</Typography>
						<Input
							fullWidth
							id='lastName'
							name='lastName'
							onBlur={formik.handleBlur}
							value={formik.values.lastName}
							onChange={formik.handleChange}
							error={formik.touched.lastName && Boolean(formik.errors.lastName)}
							helperText={formik.touched.lastName && formik.errors.lastName}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* English version field */}
						<Typography
							sx={{
								marginTop: '.5rem',
								marginBottom: '.5rem',
							}}
							variant='subtitle2'
						>
							Email
						</Typography>
						<Input
							fullWidth
							id='email'
							name='email'
							disabled={id ? true : false}
							value={formik.values.email}
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={formik.touched.email && formik.errors.email}
						/>
					</Grid>
					<Grid item xs={4}>
						{/* English version field */}
						<Typography
							sx={{
								marginTop: '.5rem',
								marginBottom: '.5rem',
							}}
							variant='subtitle2'
						>
							Mobile
						</Typography>

						<PhoneInput
							containerStyle={{
								width: '100%',
							}}
							inputStyle={
								formik?.errors?.mobile
									? {
											fontWeight: 400,
											fontSize: '1rem',
											lineHeight: '1.4375em',
											padding: '8.5px',
											paddingLeft: '40px',
											height: '2.5em',
											// border: '1px solid #d32f2f',
											width: '100%',
									  }
									: {
											width: '100%',
											fontWeight: 400,
											fontSize: '1rem',
											lineHeight: '1.4375em',
											padding: '8.5px',
											paddingLeft: '40px',
											height: '2.5em',
									  }
							}
							// className='phone-input'
							country='ae'
							disabled={id ? true : false}
							value={formik.values.mobile}
							inputProps={{ id: 'mobile', name: 'mobile' }}
							countryCodeEditable={false}
							onChange={(_value, country: CountryData, _e, formattedValue) => {
								const mobile = `${formattedValue.slice(
									0,
									country?.dialCode.length + 1
								)}-${formattedValue.slice(country?.dialCode?.length + 1)}`;
								formik.setValues(values => ({
									...values,
									mobile,
								}));
							}}
							autoFormat={false}
							preferredCountries={['ae', 'in']}
						/>

						{formik.touched.mobile && formik.errors.mobile && (
							<span className='formik-error'>{formik.errors.mobile}</span>
						)}
					</Grid>
					<Grid item xs={4}>
						{/* English version field */}
						<Typography
							sx={{
								marginTop: '.5rem',
								marginBottom: '.5rem',
							}}
							variant='subtitle2'
						>
							Address
						</Typography>
						<Input
							fullWidth
							id='address'
							name='address'
							onBlur={formik.handleBlur}
							multiline
							value={formik.values.address}
							onChange={formik.handleChange}
							error={formik.touched.address && Boolean(formik.errors.address)}
							helperText={formik.touched.address && formik.errors.address}
						/>
					</Grid>

					{/* lat */}

					<Grid item xs={4}>
						<FormLable label='Latitude' />
						<Input
							fullWidth
							id='lat'
							name='lat'
							disabled
							type='number'
							onBlur={formik.handleBlur}
							value={formik.values.lat}
							onChange={formik.handleChange}
							error={formik.touched.lat && Boolean(formik.errors.lat)}
							helperText={formik.touched.lat && formik.errors.lat}
						/>
					</Grid>
					{/* log */}

					<Grid item xs={4}>
						<FormLable label='Longitude' />
						<Input
							fullWidth
							disabled
							id='long'
							name='long'
							type='number'
							onBlur={formik.handleBlur}
							value={formik.values.long}
							onChange={formik.handleChange}
							error={formik.touched.long && Boolean(formik.errors.long)}
							helperText={formik.touched.long && formik.errors.long}
						/>
					</Grid>

					<Grid item xs={4}>
						<FormLable label='Country' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.countryId && Boolean(formik.errors.countryId)}
						>
							<Select
								size='small'
								fullWidth
								id='countryId'
								name='countryId'
								displayEmpty
								value={formik.values.countryId}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.countryId && Boolean(formik.errors.countryId)}
							>
								{allCountry
									?.filter((item: any) => item.code !== 'WW')
									?.map((each: any) => (
										<MenuItem
											key={each.id}
											selected={formik?.values?.countryId === each.id}
											value={each.id}
										>
											{
												each.translation.filter(
													(item: any) => item.languageCode === 'en'
												)[0].name
											}
										</MenuItem>
									))}
							</Select>
							<FormHelperText
								sx={{
									marginLeft: '0px',
								}}
							>
								{formik.touched.countryId && formik.errors.countryId}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<FormLable label='Role Type' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.roleType && Boolean(formik.errors.roleType)}
							disabled={id ? true : false}
						>
							<Select
								size='small'
								fullWidth
								id='roleType'
								name='roleType'
								displayEmpty
								value={formik.values.roleType}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.roleType && Boolean(formik.errors.roleType)}
							>
								{[
									{ id: 1, name: 'Admin' },
									{ id: 0, name: 'Customer' },
								].map((each: any) => (
									<MenuItem key={each?.id} value={each?.id}>
										{each.name}
									</MenuItem>
								))}
							</Select>
							<FormHelperText
								sx={{
									marginLeft: '0px',
								}}
							>
								{formik.touched.roleType && formik.errors.roleType}
							</FormHelperText>
						</FormControl>
					</Grid>

					<Grid
						item
						xs={4}
						style={{ display: formik.values.roleType === 0 ? 'none' : '' }}
					>
						<FormLable label='Role' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.role && Boolean(formik.errors.role)}
							disabled={id ? true : false}
						>
							<Select
								size='small'
								fullWidth
								id='role'
								name='role'
								displayEmpty
								value={formik.values.role}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.role && Boolean(formik.errors.role)}
							>
								{allRoles
									.filter((item: any) => item.status === 1)
									.map((each: any) => (
										<MenuItem key={each?.id} value={each?.id}>
											{each.name}
										</MenuItem>
									))}
							</Select>
							<FormHelperText
								sx={{
									marginLeft: '0px',
								}}
							>
								{formik.touched.role && formik.errors.role}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={4}>
						<FormLable label='Status' />
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								size='small'
								fullWidth
								id='status'
								name='status'
								displayEmpty
								onBlur={formik.handleBlur}
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem
									selected={formik.values.status === 1 ? true : false}
									value={1}
								>
									Active
								</MenuItem>
								<MenuItem
									selected={formik.values.status === 0 ? true : false}
									value={0}
								>
									Inactive
								</MenuItem>
							</Select>
							<FormHelperText
								sx={{
									marginLeft: '0px',
								}}
							>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
				{/* Grid map */}

				<Grid
					style={{
						marginTop: '0rem',
					}}
					container
					rowSpacing={4}
					columnSpacing={3}
				>
					<Grid item xs={12}>
						<FormLable label='Select Location' />
						{lat && long ? (
							<MapContainer
								height='330px'
								center={{
									lat,
									lng: long,
								}}
								zoom={4}
								on
								onCenterChange={(position: any) => {
									formik.setFieldValue('lat', position.lat);
									formik.setFieldValue('long', position.lng);
									formik.setFieldValue('address', position.add);
								}}
							/>
						) : (
							<div
								style={{ display: 'grid', placeItems: 'center', minHeight: '20rem' }}
							>
								<Loader />
							</div>
						)}
					</Grid>
				</Grid>

				{/* btn */}
				<Grid
					container
					rowSpacing={4}
					columnSpacing={3}
					style={{
						marginTop: '20rem',
					}}
				>
					<Grid item xs={12}>
						<Button
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							color='primary'
							variant='contained'
							fullWidth
							loading={loadingBtn}
							type='submit'
						>
							Submit
						</Button>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
};
const UserAdd = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector((state: RootState) => state.users.loading);
	const isAdded = useSelector((state: RootState) => state.users.isAdded);
	const isUpdated = useSelector((state: RootState) => state.users.isUpdated);

	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('/user-management');
		}
		() => {
			dispatch(clearData());
		};
	}, [isAdded, isUpdated]);

	useEffect(() => {
		if (id) {
			// fetch brand details
			dispatch(getUserById(id));
		}
	}, [id]);

	return (
		<TableLayout>
			<Container maxWidth='lg' className='addScroll'>
				<Header title={id ? 'Edit User' : 'Add User'} />
				<Box>
					{loading ? (
						<Box
							sx={{
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								position: 'absolute',
								backgroundColor: 'white',
								opacity: '0.7',
								zIndex: '999',
								minHeight: 'calc(100vh - 80px)',
							}}
						>
							<Loader />
						</Box>
					) : (
						<WithMaterialUI />
					)}
				</Box>
			</Container>
		</TableLayout>
	);
};
export default UserAdd;
