import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { push } from 'react-router-redux';
import getGenericErrorMessage from 'utils/error';

import axiosInstance from '../utils/axiosInstance';

export const addBreadCrumb = createAsyncThunk(
	'addBreadCrumb',
	async (data: any) => {
		try {
			const response = await axiosInstance.post('admin/bread-crumbs', data);
			toast.success('Added successfully');
			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
			return Promise.reject(e);
		}
	}
);
export const updateBreadCrumb = createAsyncThunk(
	'updateBreadCrumb',
	async ({ id, data }: any, thunkAPI) => {
		try {
			const payload = {
				link: data.link,
				status: data.status,
				linkType: data.linkType,
				countries: data.countries || null,
				translation: [
					{
						languageCode: 'en',
						linkName: data.name,
					},
					{
						languageCode: 'ara',
						linkName: data.nameAra,
					},
				],
			};
			const response = await axiosInstance.put(
				`admin/bread-crumbs/${id}`,
				payload
			);
			if (response.status === 200) {
				toast.success('Updated successfully');
				thunkAPI.dispatch(push('/bread-crumbs'));
				return { id, status: data.status };
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);
export const getBreadCrumbs = createAsyncThunk(
	'getBreadCrumbs',
	async ({ page, perPage }: any) => {
		try {
			const response = await axiosInstance.get('admin/bread-crumbs', {
				params: {
					page,
					perPage,
				},
			});
			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);
export const getBreadCrumbById = createAsyncThunk(
	'getBreadCrumbById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/bread-crumbs/${id}`);
			if (response.status === 200) {
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);
