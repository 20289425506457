/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/prop-types */
// import { Input } from 'antd'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React from 'react';
import Geocode from 'react-geocode';
import Autocomplete from 'react-google-autocomplete';

import { appConfig } from '../../../utils/constant';

Geocode.setApiKey(appConfig.google.maps.apiKey);
Geocode.enableDebug();
class MapContainer extends React.Component {
	constructor(props) {
		super(props);
		const { center } = this.props;
		this.state = {
			address: '',
			mapPosition: {
				lat: center.lat,
				lng: center.lng,
			},
			markerPosition: {
				lat: center.lat,
				lng: center.lng,
			},
		};
	}

	componentDidMount() {
		const { mapPosition } = this.state;
		Geocode.fromLatLng(mapPosition.lat, mapPosition.lng).then(
			response => {
				const address = response.results[0].formatted_address || '';
				this.setState({ address });
			},
			error => {
				console.error(error);
			}
		);
	}

	onPlaceSelected = place => {
		const { onCenterChange } = this.props;
		const address = place.formatted_address;
		const latValue = place.geometry.location.lat();
		const lngValue = place.geometry.location.lng();

		this.setState(
			{
				address: address || '',
				markerPosition: {
					lat: latValue,
					lng: lngValue,
				},
				mapPosition: {
					lat: latValue,
					lng: lngValue,
				},
			},
			() => onCenterChange({ lat: latValue, lng: lngValue, add: address })
		);
	};

	handleMarkerDragend = (mapProps, map) => {
		const { onCenterChange } = this.props;
		const newLat = map.position.lat();
		const newLng = map.position.lng();

		Geocode.fromLatLng(newLat, newLng).then(
			response => {
				const address = response.results[0].formatted_address || '';
				this.setState({ address }, () =>
					onCenterChange({ lat: newLat, lng: newLng, add: address })
				);
			},
			error => {
				console.error(error);
			}
		);
	};

	getLatLong = () => this.state.mapPosition;

	// eslint-disable-next-line class-methods-use-this
	onInfoWindowClose = () => {};

	render() {
		const { google, height, zoom } = this.props;
		const { address, mapPosition, markerPosition } = this.state;

		return (
			<div>
				<Autocomplete
					style={{
						width: '100%',
						height: '40px',
						paddingLeft: '16px',
						marginTop: '2px',
					}}
					debounce={200}
					onPlaceSelected={this.onPlaceSelected}
					value={address}
					onChange={e => this.setState({ address: e.target.value })}
					types={['geocode', 'establishment']}
				/>
				<Map
					google={google}
					zoom={zoom}
					initialCenter={mapPosition}
					center={mapPosition}
					style={{ height }}
					mapTypeControl='true'
					zoomControl='true'
					zoomControlOptions={{
						position: this.props.google.maps.ControlPosition.TOP_RIGHT,
					}}
				>
					<Marker
						onDragend={this.handleMarkerDragend}
						position={markerPosition}
						draggable
						name='Current location'
					/>
				</Map>
				{/* <div
					style={{
						padding: '5px',
						fontSize: '12px',
					}}
				>
					<div>lat-{this?.getLatLong?.lat}</div>
					<div>long-{this?.getLatLong.lng}</div>
				</div> */}
			</div>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: appConfig.google.maps.apiKey,
})(MapContainer);
