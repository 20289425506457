/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unstable-nested-components */
import { AddRounded, EditRounded, SearchRounded } from '@mui/icons-material';
import {
	Box,
	Chip,
	IconButton,
	InputAdornment,
	MenuItem,
	Stack,
	Switch,
	Tooltip,
} from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DeleteNotification, getAllNotifications } from 'api/notification';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
	emptySingleNotification,
	notificationSlice,
} from 'redux/notification/notificationSlice';
import { RootState } from 'redux/store';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';
import useUrlSearchParams from 'utils/useUrlSearchParams';

import styles from './index.module.scss';

const Notification = () => {
	const { searchParams, setParams } = useUrlSearchParams('filter', 'query');
	const [statusFilter, setStatusFilter] = useState(searchParams?.filter ?? 1);

	const [query, setQuery] = useState<string>(searchParams?.query ?? undefined);

	const dispatch = useDispatch();
	const { allNotifications, loading, pagination } = useSelector(
		(state: RootState) => state.notification
	);
	const fetchNotifications = (q?: string) => {
		dispatch(
			getAllNotifications({
				page: pagination?.currentPage,
				statusFilter,
				q: q ?? query,
				perPage: pagination?.perPage,
			})
		);
		dispatch({ type: emptySingleNotification });
	};
	useEffect(() => {
		fetchNotifications();
	}, [pagination?.currentPage, statusFilter, pagination?.perPage]);

	const columns: GridColDef[] = [
		{
			field: 'title',
			headerName: 'Title',
			...disablefilter,
			width: 180,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.details?.en[0]?.title}>
					<span className='textWrap'>{params.row.details?.en[0]?.title}</span>
				</Tooltip>
			),
		},
		{
			field: 'titleAra',
			headerName: 'Title (Arabic)',
			...disablefilter,
			width: 180,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.details?.ara[0]?.title}>
					<span className='textWrap'>{params.row.details?.ara[0]?.title}</span>
				</Tooltip>
			),
		},
		{
			field: 'message',
			headerName: 'Message',
			...disablefilter,
			width: 180,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.details?.en[0]?.message}>
					<span className='textWrap'>{params.row.details?.en[0]?.message}</span>
				</Tooltip>
			),
		},
		{
			field: 'messageAra',
			headerName: 'Message (Arabic)',
			...disablefilter,
			width: 180,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.details?.ara[0]?.message}>
					<span className='textWrap'>{params.row.details?.ara[0]?.message}</span>
				</Tooltip>
			),
		},
		{
			field: 'imageSlug',
			...disablefilter,
			headerName: 'Notification Image',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={
							Object.keys(params?.row?.details).length !== 0
								? `${params.row.details?.en[0]?.imageSlug?.path}`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'contain',
							padding: '0.5rem',
						}}
					/>
				</Box>
			),
		},
		{
			field: 'imageSlugAra',
			...disablefilter,
			headerName: 'Notification Image (Arabic)',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={
							Object.keys(params?.row?.details).length !== 0
								? `${params.row.details?.ara[0]?.imageSlug?.path}`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'contain',
							padding: '0.5rem',
						}}
					/>
				</Box>
			),
		},
		{
			field: 'type',
			headerName: 'Link Type',
			...disablefilter,
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.type}>
					<span className='textWrap'>
						{params?.row?.type === 1
							? 'External'
							: params?.row?.type === 2
							? 'Internal'
							: ''}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'link',
			headerName: 'Link',
			...disablefilter,
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.link}>
					<span className='textWrap'>
						{params?.row?.link.toLowerCase() === '/'
							? '/home'
							: params?.row?.link.toLowerCase()}
					</span>
				</Tooltip>
			),
		},

		{
			field: 'country',
			headerName: 'Country',
			width: 200,
			renderCell: (params: GridValueGetterParams) => {
				const countryArray = params?.row?.country;
				const arr = [];
				for (let i = 0; i < countryArray.length; i++) {
					arr.push(
						countryArray[i]?.translation?.filter(
							(item: any) => item.languageCode === 'en'
						)[0]?.name
					);
				}

				return (
					<Tooltip title={arr.length ? `${arr.join(', ')}` : '-'}>
						<div className={styles.workBreak}>
							{arr.length ? `${arr.join(', ')}` : '-'}
						</div>
					</Tooltip>
				);
			},
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'updatedEntryDate',
			headerName: 'Scheduled Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.scheduledAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.scheduledAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'updatedEntryTime',
			headerName: 'Scheduled Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.scheduledAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.scheduledAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			...disablefilter,
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/notification-management/edit/${params.row.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
					<Switch
						checked={!!params.row.status}
						onChange={() => {
							const { id, status } = params.row;
							const sendStatus: number = status === 1 ? 0 : 1;
							dispatch(
								DeleteNotification({ id, sendStatus, statusFilter, query: query || '' })
							);
						}}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			),
		},
	];

	const debouncedFn = useCallback(
		debounce(q => {
			fetchNotifications(q);
		}, 500),
		[]
	);

	return (
		<TableLayout>
			<Header title='Notification Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							size='small'
							fullWidth
							id='status'
							name='status'
							variant='outlined'
							select
							value={statusFilter}
							onChange={e => {
								const sendStatus = Number(e?.target?.value);
								setStatusFilter(sendStatus);
								setParams('filter', sendStatus);
							}}
						>
							<MenuItem value={2}>All</MenuItem>
							<MenuItem value={1}>Active</MenuItem>
							<MenuItem value={0}>Inactive</MenuItem>
						</Input>
						<Input
							sx={{ width: '18rem' }}
							fullWidth
							onChange={(e: any) => {
								const { value } = e.target;
								setQuery(value);
								setParams('query', value);
								debouncedFn(value);
							}}
							placeholder='Search by title'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
							value={query}
						/>
						<Link to='/notification-management/add'>
							<Button startIcon={<AddRounded />} variant='contained'>
								Add
							</Button>
						</Link>
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allNotifications}
				columns={columns}
				// checkboxSelection
				rowHeight={100}
				loading={loading}
				total={pagination?.total}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				perPage={pagination?.perPage}
				onPerPage={(value: number) => {
					dispatch(notificationSlice.actions.setCurrentPage(1));
					dispatch(notificationSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(notificationSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '75vh' }}
			/>
		</TableLayout>
	);
};
export default Notification;
