import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
// import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

import getDashboardData from './dashboard';

export const getAllContacts = createAsyncThunk(
	'getAllContacts',
	async (prams: any) => {
		try {
			const response = await axiosInstance.get('admin/contact-us', {
				params: prams,
				headers: {
					'x-locale': 'en',
				},
			});

			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const changeContactStatus = createAsyncThunk(
	'changeContactStatus',
	async ({ id, sendStatus }: { id: string; sendStatus: number }, thunkAPI) => {
		try {
			const response = await axiosInstance.patch(`admin/contact-us/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success('Request Resolved successfully');
				thunkAPI.dispatch(getDashboardData());
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const searchContact = createAsyncThunk(
	'searchContact',
	async ({ q }: any) => {
		try {
			const response = await axiosInstance.get(`admin/contact-us?q=${q}`);
			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);
