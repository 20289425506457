/* eslint-disable no-nested-ternary */
import { Send } from '@mui/icons-material';
import { Modal } from 'antd';
import Loader from 'components/common/Loader';
import moment from 'moment';
import React from 'react';
import { getFromLocal } from 'utils/localStorage';

import styles from '../chat.module.scss';
import useChatBox from './useChatBox';

const ChatModal = ({ threadDetails, onClose }: any) => {
	const user = getFromLocal('user');
	const {
		scrollBoxRef,
		handleSendMessage,
		chats,
		getFormattedDate,
		firstChatRef,
		isLoading,
	} = useChatBox(threadDetails?.id);
	return (
		<Modal
			title={`${threadDetails.user.firstName} ${threadDetails.user.lastName}`}
			visible
			centered
			className={styles.modal}
			footer={
				<form onSubmit={handleSendMessage} className={styles.form}>
					<input
						name='message'
						placeholder='Enter message'
						className={styles.formInput}
					/>
					<button type='submit' className={styles.formSubmit}>
						<Send />
					</button>
				</form>
			}
			closable
			bodyStyle={{ paddingBlock: '2px' }}
			onCancel={() => onClose()}
		>
			<div className={styles.chatContainer}>
				{isLoading && (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{' '}
						<Loader />
					</div>
				)}
				{chats &&
					chats
						.sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1))
						.map((chat, index) => {
							const date = getFormattedDate(chat.createdAt);
							const prevChatDate =
								index > 0 ? getFormattedDate(chats[index - 1].createdAt) : '';
							const isMe = chat.sender.id === user.user.id;
							return (
								<React.Fragment key={chat.id}>
									{date !== prevChatDate && <p className={styles.date}>{date}</p>}
									<div
										className={`${styles.chatItem} ${isMe && styles.myChatItem}`}
										ref={
											index === 0
												? firstChatRef
												: index === chats.length - 1
												? scrollBoxRef
												: undefined
										}
									>
										<div className={`${styles.senderBox} ${isMe && styles.mySenderBox}`}>
											{chat.sender.profileImage !== null ? (
												<img
													src={chat?.sender?.profileImage?.path}
													alt={chat.sender.firstName}
													height={40}
													width={40}
												/>
											) : isMe ? (
												user?.user?.firstName.slice(0, 1)
											) : (
												'Ad'
											)}
										</div>
										<p className={`${styles.chatBox} ${isMe && styles.myChatBox}`}>
											<span
												className={`${styles.senderName} ${isMe && styles.mySenderName}`}
											>
												{chat.sender?.firstName}
											</span>
											<span className={`${styles.message} ${isMe && styles.myMessage}`}>
												{chat.message}
											</span>
											<span className={`${styles.dateTime} ${isMe && styles.myDateTime}`}>
												{date === 'Today'
													? moment(chat.createdAt).fromNow()
													: moment(chat.createdAt).format('hh:mm A')}
											</span>
										</p>
									</div>
								</React.Fragment>
							);
						})}
			</div>
		</Modal>
	);
};

export default ChatModal;
