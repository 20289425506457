import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllSeo = createAsyncThunk(
	'getAllSeo',
	async (params: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/seo', {
				params,
				headers: {
					'x-locale': 'en',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const AddPageSeo = createAsyncThunk(
	'AddPageSeo',
	async (backendObject: any, thunkAPI) => {
		try {
			const response = await axiosInstance.post(`admin/seo`, backendObject);
			if (response.status === 200) {
				toast.success('Seo added successfully');
				thunkAPI.dispatch(push('/page-seo'));

				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const UpdatePageSeo = createAsyncThunk(
	'UpdatePageSeo',
	async ({ backendObject }: { backendObject: any }, thunkAPI) => {
		try {
			const response = await axiosInstance.put(`admin/seo`, backendObject);
			if (response.status === 200) {
				toast.success('Seo updated successfully');
				thunkAPI.dispatch(push('/page-seo'));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const DeletePageSeo = createAsyncThunk(
	'DeletePageSeo',
	async (
		{ pageName, sendStatus }: { sendStatus: number; pageName: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.patch(`admin/seo`, {
				status: sendStatus,
				pageName,
			});
			if (response.status === 200) {
				toast.success(
					sendStatus
						? 'Seo status changed to Active'
						: 'Seo status changed to Inactive'
				);

				const { pageSeo }: any = thunkAPI.getState();

				const page = pageSeo.pagination.currentPage;
				thunkAPI.dispatch(getAllSeo({ page, isRefresh: true }));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const getSeoByPage = createAsyncThunk(
	'getSeoByPage',
	async (pageName: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/seo/${pageName}`);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const searchSeo = createAsyncThunk(
	'searchSeo',
	async ({ q }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/seo?q=${q}`);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
