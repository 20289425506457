/* eslint-disable consistent-return */

// import ErrorMsg from 'components/common/ErrorMsg';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Login from '../../components/Login';

const LoginPage = () => (
	<GoogleReCaptchaProvider
		reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
	>
		<Login />
	</GoogleReCaptchaProvider>
);

export default LoginPage;
