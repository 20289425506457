import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

import { ApiEndPoints } from './constant';

export const getAllChatThreads = createAsyncThunk(
	'getAllChatThreads',
	async ({ page, perPage }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(ApiEndPoints.adminThreads, {
				params: {
					page,
					perPage,
				},
				headers: {
					'x-locale': 'en',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const searchUserThread = createAsyncThunk(
	'searchUserThread',
	async ({ q }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(ApiEndPoints.adminThreads, {
				params: {
					q,
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const updateChatStatus = createAsyncThunk(
	'updateChatStatus',
	async ({ threadId, status }: any, thunkAPI) => {
		try {
			const data = await axiosInstance.patch(
				`${ApiEndPoints.adminThread}/${threadId}`,
				{
					status,
				}
			);
			return data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
