import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
// import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllBankTransfer = createAsyncThunk(
	'getAllBankTransfer',
	async (prams: any) => {
		try {
			const response = await axiosInstance.get('admin/bank-transfers', {
				params: prams,
				headers: {
					'x-locale': 'en',
				},
			});

			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const changeBankTransferStatus = createAsyncThunk(
	'changeBankTransferStatus',
	async (
		{
			id,
			sendStatus,
			comment,
		}: {
			id: string | undefined;
			sendStatus: number | undefined;
			comment?: string;
		},
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.put(`admin/bank-transfers/${id}`, {
				status: sendStatus,
				comment,
			});
			if (response.status === 204) {
				toast.success(
					sendStatus === 3 ? 'Request rejected' : 'Request approved successfully'
				);
				thunkAPI.dispatch(getAllBankTransfer({}));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const searchBankTransfer = createAsyncThunk(
	'searchBankTransfer',
	async ({ q }: any) => {
		try {
			const response = await axiosInstance.get(`admin/bank-transfers?q=${q}`);
			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);
