import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useUrlSearchParams = (...params: string[]) => {
	const [paramList, setSearchParams] = useSearchParams();
	const searchParams: Record<string, any> = useMemo(
		() =>
			params.reduce((acc, param) => {
				const p = paramList.get(param);
				if (p === null) return acc;
				return { ...acc, [param]: p };
			}, {}),
		[params, paramList]
	);
	const setParams = (key: string, value: any) => {
		setSearchParams({ ...searchParams, [key]: String(value) });
	};
	return { searchParams, setParams };
};

export default useUrlSearchParams;
