import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
// import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

import getDashboardData from './dashboard';

export const getAllAdvertisement = createAsyncThunk(
	'getAllAdvertisement',
	async ({ page, status, q, couponType, perPage }: any) => {
		try {
			const response = await axiosInstance.get('admin/advertise-with-us', {
				params: {
					page,
					status,
					q,
					couponType,
					perPage,
				},
				headers: {
					'x-locale': 'en',
				},
			});

			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const changeAdvertisementStatus = createAsyncThunk(
	'changeAdvertisementStatus',
	async (
		{
			id,
			sendStatus,
			couponType,
		}: { id: string; sendStatus: number; couponType: number },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.patch(`admin/advertise-with-us/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success('Request Resolved successfully');
				thunkAPI.dispatch(getAllAdvertisement({ couponType, sendStatus }));
				thunkAPI.dispatch(getDashboardData());
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const searchAdvertisement = createAsyncThunk(
	'searchAdvertisement',
	async ({ q, couponType, page, status }: any) => {
		try {
			const response = await axiosInstance.get(`admin/advertise-with-us?q=${q}`, {
				params: {
					page,
					status,
					couponType,
				},
			});
			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);
