import { Box, FormLabel, TextField, TextFieldProps } from '@mui/material';

type InputProps = TextFieldProps & { accept?: string };

const Input = ({
	fullWidth = true,
	label,
	size = 'small',
	variant = 'outlined',
	...props
}: InputProps) => (
	<Box>
		{label && <FormLabel>{label}</FormLabel>}
		<TextField
			fullWidth={fullWidth}
			label=''
			variant={variant}
			hiddenLabel
			size={size}
			// style={{
			// 	backgroundColor: '#f5f6fa',
			// }}
			InputLabelProps={{ shrink: false }}
			{...props}
		/>
	</Box>
);

Input.defaultProps = {
	accept: '',
};

export default Input;
