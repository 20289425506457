import { Box, MenuItem, Pagination } from '@mui/material';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { PerPageCount } from 'utils/constant';

import Input from '../Input';
import Loader from '../Loader';
import styles from './table.module.scss';

const NoData = () => (
	<Box className={styles.emptyContainer}>
		<img
			src='/assets/no_data.jpg'
			alt='no-data'
			loading='lazy'
			className={styles.emptyImage}
		/>
	</Box>
);

const TableLoader = () => (
	<Box className={[styles.emptyContainer, styles.loaderBox].join(' ')}>
		<Loader />
	</Box>
);

interface ITableProps extends DataGridProps {
	count?: number;
	// eslint-disable-next-line react/require-default-props
	onChange?: any;
	page?: number;
	// eslint-disable-next-line react/require-default-props
	total?: number;
	// eslint-disable-next-line react/require-default-props
	onPerPage?: any;
	// eslint-disable-next-line react/require-default-props
	perPage?: number;
}

const Table = ({
	total,
	count,
	page,
	onChange,
	onPerPage,
	perPage,
	sx = {},
	...props
}: ITableProps) => (
	<>
		<DataGrid
			sx={{
				border: 'none',
				...sx,
				'& .MuiDataGrid-row:nth-of-type(even)': {
					backgroundColor: '#f5f6fa',
				},
				textAlign: 'center',
				'.MuiDataGrid-cellCenter': {
					justifyContent: 'center',
				},
			}}
			disableColumnMenu
			hideFooter
			disableSelectionOnClick
			components={{
				NoRowsOverlay: NoData,
				LoadingOverlay: TableLoader,
			}}
			paginationMode='server'
			{...props}
		/>

		<Box className={styles.pagination}>
			<Box> Total :- {total}</Box>

			<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
				{' '}
				Items per page :-
				<Input
					size='small'
					fullWidth
					id='status'
					name='status'
					variant='outlined'
					defaultValue={15}
					select
					value={perPage}
					onChange={e => onPerPage(e.target.value)}
				>
					{PerPageCount.map(pageCount => (
						<MenuItem value={pageCount}>{pageCount}</MenuItem>
					))}
				</Input>
			</div>
			<Pagination
				sx={props.loading ? { pointerEvents: 'none', opacity: 0.5 } : {}}
				color='primary'
				count={count}
				page={page}
				onChange={onChange}
			/>
		</Box>
	</>
);

Table.defaultProps = {
	count: 1,
	page: 1,
};

export default Table;
