/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable import/extensions */
import { message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import axiosInstance from './axiosInstance';

interface IUploadImage {
	formikImg?: any;
	aspect?: any;
	entity?: string;
	logo?: string;
	handleSlug?: any;
}
export interface UploadFiletype {
	obj: Obj;
}

export interface Obj {
	action: string;
	filename: string;
	data: Data;
	file: File;
	headers: Data;
	withCredentials: boolean;
	method: string;
}

export interface Data {}

export interface File {
	uid: string;
	size: number;
	type: string;
}

const UploadImage: React.FC<IUploadImage> = ({
	formikImg,
	aspect = 1 / 1,
	entity,
	logo,
	handleSlug,
}) => {
	const [files, setFiles] = useState<any>([]);

	useEffect(() => {
		if (logo !== undefined && logo !== null && logo !== '') {
			const state = [
				{
					url: logo,
					status: 'done',
					slug: logo,
				},
			];
			setFiles(state);
		}
	}, [logo]);

	const uploadFile = async (obj: Obj) => {
		const { file } = obj;
		// if(file.size > 52)

		const response = await axiosInstance.post(
			`admin/media/upload-urls?n=1&entity=${entity}`
		);
		const { data } = response.data;
		const storageObj = data[0];
		await axios
			.put(storageObj.url, file, {
				onUploadProgress: progressEvent => {
					const progress = (progressEvent.loaded * 100) / file.size;
					const state = [
						{
							...file,
							status: 'uploading',
							percent: progress,
						},
					];
					setFiles(state);
				},
				headers: { 'Content-Type': file.type },
			})
			.then(async res => {
				const response = await axiosInstance.post(`admin/media/register`, {
					isPrivate: true,
					key: storageObj.key,
					mimeType: file.type,
					entityType: entity && entity,
				});

				const { data } = response?.data;
				const mediaData = data;
				handleSlug(response?.data?.data?.path);
				formikImg(response.data.data.slug);

				const state = [
					{
						...file,
						url: response.data.data.path ? response.data.data.path : '',
						status: 'done',
						slug: mediaData.slug,
					},
				];
				setFiles(state);
			})
			.catch(err => {
				setFiles([
					{
						...file,
						status: 'error',
						err,
					},
				]);
			});
	};
	const uploadButton = <>Upload</>;
	return (
		<ImgCrop
			aspect={aspect || 4 / 4}
			beforeCrop={props => ['image/png', 'image/jpeg'].includes(props?.type)}
			fillColor='white'
		>
			<Upload
				accept='.png,.jpeg'
				disabled={false}
				listType='picture-card'
				maxCount={1}
				multiple={false}
				fileList={[...files]}
				// className={styles}
				onRemove={file => {
					setFiles([{ ...file, status: 'removed' }]);
					handleSlug('');
					formikImg('');
				}}
				// beforeUpload={file => {
				// 	const sizeInKbs = file.size / 1024;
				// 	if (sizeInKbs > MAX_FILE_SIZE_FOR_UPLOAD) {
				// 		message.error(`Size can't be greater than 5MB`);
				// 		return false;
				// 	}
				// 	return true;
				// }}
				customRequest={(e: any) => {
					uploadFile(e);
				}}
				onChange={info => {
					const { status } = info.file;
					if (status === 'removed') {
						setFiles([]);
						handleSlug('');
					} else if (status === 'done') {
						message.success(`${info.file.name} file uploaded successfully.`);
					} else if (status === 'error') {
						message.error(`${info.file.name} file upload failed.`);
					}
				}}
			>
				{files.length >= 1 ? null : uploadButton}
			</Upload>
		</ImgCrop>
	);
};
export default UploadImage;
