import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { push } from 'react-router-redux';
// import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllCashback = createAsyncThunk(
	'getAllCashback',
	async ({ status, perPage, page, q }: any) => {
		try {
			const response = await axiosInstance.get('admin/cashback', {
				params: { status, perPage, page, q },
				headers: {
					'x-locale': 'en',
				},
			});

			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const changeCashbackStatus = createAsyncThunk(
	'changeCashbackStatus',
	async (
		{
			id,
			sendStatus,
			comment,
		}: { id: string; sendStatus: number; comment?: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.put(`admin/cashback/${id}`, {
				status: sendStatus,
				comment,
			});
			if (response.status === 200) {
				toast.success(
					sendStatus === 2 ? 'Request approved successfully' : 'Request rejected'
				);
				thunkAPI.dispatch(getAllCashback({}));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const getCashbackById = createAsyncThunk(
	'getCashbackById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/cashback/${id}`);
			if (response.status === 200) {
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const searchCashback = createAsyncThunk(
	'searchCashback',
	async ({ q }: any) => {
		try {
			const response = await axiosInstance.get(`admin/cashback?q=${q}`);
			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const editCashback = createAsyncThunk(
	'editCashback',
	async (
		{ backendObject, id }: { backendObject: any; id: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.put(
				`admin/cashback/${id}`,
				backendObject
			);
			if (response.status === 204) {
				toast.success('Cashback updated successfully');
				thunkAPI.dispatch(push('/cashback-management'));
				return response.data;
			}
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const getConvertedAmount = createAsyncThunk(
	'getConvertedAmount',
	async (
		{ to, from, amount }: { to: string; from: string; amount: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.get(
				`/admin/currency-converter?to=${to}&from=${from}&amount=${amount}`
			);

			if (response.status === 200) {
				return response.data.data;
			}
			return response.data.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
