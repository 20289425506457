import * as yup from 'yup';

// const FILE_SIZE = 2000000;
// const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const validationSchema = yup.object().shape({
	sections: yup.array().of(
		yup.object().shape({
			sequence: yup
				.number()
				.required('Sequence is required')
				.typeError('Sequence must be a number'),
			title: yup
				.string()
				.min(2, 'Enter a valid title')
				.required('Title is required'),
			titleAra: yup
				.string()
				.min(2, 'Enter a valid arabic title')
				.required('Arabic title is required'),
			description: yup
				.string()
				.min(20, 'Minimum 20 characters are required')
				.required('Description is required'),
			descriptionAra: yup
				.string()
				.min(20, 'Minimum 20 characters are required')
				.required('Arabic description is required'),
			image: yup
				.string()
				.min(1, 'Image is required')
				.required('Image is required'),
			imageAra: yup
				.string()
				.min(1, 'Arabic image is required')
				.required('Arabic image is required'),
		})
	),
});

export default validationSchema;
