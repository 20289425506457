import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllRoles = createAsyncThunk(
	'getAllRoles',
	async (params: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/roles', {
				params,
				headers: {
					'x-locale': 'en',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const AddRoles = createAsyncThunk(
	'AddRoles',
	async (backendObject: any, thunkAPI) => {
		try {
			const response = await axiosInstance.post(`admin/roles`, backendObject);
			if (response.status === 200) {
				toast.success('Role added successfully');
				thunkAPI.dispatch(push('/roles-management'));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const UpdateRoles = createAsyncThunk(
	'UpdateRoles',
	async (
		{ backendObject, id }: { backendObject: any; id: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.put(`admin/roles/${id}`, backendObject);
			if (response.status === 204) {
				toast.success('Roles updated successfully');
				thunkAPI.dispatch(push('/roles-management'));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const DeleteRoles = createAsyncThunk(
	'DeleteRoles',
	async ({ id, sendStatus }: { id: string; sendStatus: number }, thunkAPI) => {
		try {
			const response = await axiosInstance.patch(`admin/roles/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success(
					sendStatus
						? 'Role status changed to Active'
						: 'Role status changed to Inactive'
				);
				const { role }: any = thunkAPI.getState();
				const page = role.pagination.currentPage;
				thunkAPI.dispatch(getAllRoles({ page, isRefresh: true }));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const getRolesById = createAsyncThunk(
	'getRolesById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/roles/${id}`);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const searchRoles = createAsyncThunk(
	'searchRoles',
	async ({ q }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/roles?q=${q}`);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
