/* eslint-disable import/no-extraneous-dependencies */
import { AddRounded, EditRounded } from '@mui/icons-material';
import { Chip, IconButton, Stack, Switch, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { getBreadCrumbs, updateBreadCrumb } from 'api/breadCrumbs';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { breadCrumbSlice } from 'redux/breadCrumbs/breadCrumbSlice';
import { RootState } from 'redux/store';
import { disablefilter } from 'utils/constant';

const CustomLink = () => {
	const dispatch = useDispatch();

	const { breadCrumbs, pagination, loading } = useSelector(
		(state: RootState) => state.breadCrumbs
	);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Link name',
			width: 200,

			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.name}>
					<span className='textWrap'>{params.row.name}</span>
				</Tooltip>
			)
		},
		{
			field: 'nameAra',
			headerName: 'Link name arabic',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.nameAra}>
					<span className='textWrap'>{params.row.nameAra}</span>
				</Tooltip>
			)
		},
		{
			field: 'link',
			headerName: 'Link',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.link}>
					<span className='textWrap'>{params.row.link}</span>
				</Tooltip>
			)
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			)
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 160,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/bread-crumbs/edit/${params.row.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
					<Switch
						checked={!!params.row.status}
						onChange={() => {
							const { id, status } = params.row;
							const sendStatus: number = status === 1 ? 0 : 1;
							dispatch(
								updateBreadCrumb({ id, data: { ...params.row, status: sendStatus } })
							);
						}}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			)
		}
	];

	useEffect(() => {
		dispatch(
			getBreadCrumbs({
				page: pagination?.currentPage,
				perPage: pagination?.perPage
			})
		);
	}, [pagination?.currentPage, pagination?.perPage]);

	return (
		<TableLayout>
			<Header title='Custom Links'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Link to='/bread-crumbs/add'>
							<Button startIcon={<AddRounded />} variant='contained'>
								Add
							</Button>
						</Link>
						{/* <Button startIcon={<GetAppRounded />} variant='contained'>
							Export
						</Button> */}
					</Stack>
				</Box>
			</Header>

			<Table
				rows={breadCrumbs}
				columns={columns}
				rowHeight={85}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				loading={loading}
				total={pagination?.total}
				perPage={pagination?.perPage}
				onPerPage={(value: number) => {
					dispatch(breadCrumbSlice.actions.setCurrentPage(1));
					dispatch(breadCrumbSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(breadCrumbSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
		</TableLayout>
	);
};
export default CustomLink;
