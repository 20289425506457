/* eslint-disable import/no-extraneous-dependencies */
import { EditRounded, SearchRounded } from '@mui/icons-material';
import {
	Chip,
	IconButton,
	InputAdornment,
	Stack,
	Switch,
	Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DeleteCountry, getAllCountry, searchCountry } from 'api/country';
// import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { countrySlice, emptySingleCountry } from 'redux/country/countrySlice';
import { RootState } from 'redux/store';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';

const Country = () => {
	const dispatch = useDispatch();
	const [query, setQuery] = useState('');

	const { allCountry, pagination } = useSelector(
		(state: RootState) => state.country
	);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Country Name',
			width: 200,

			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={
						params?.row?.translation?.filter(
							(item: any) => item.languageCode === 'en'
						)[0].name
					}
				>
					<span className='textWrap'>
						{
							params?.row?.translation?.filter(
								(item: any) => item.languageCode === 'en'
							)[0].name
						}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'flagSlug',
			...disablefilter,
			headerName: 'Country Image',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={params.row.flagSlug ? `${params?.row.flagSlug}` : 'logo.png'}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'cover',
							padding: '0.5rem',
						}}
					/>
				</Box>
			),
		},
		{
			field: 'code',
			headerName: 'Country Code',
			width: 200,

			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.code}>
					<span className='textWrap'>{params.row.code}</span>
				</Tooltip>
			),
		},
		{
			field: 'currency',
			headerName: 'Currency',
			width: 200,

			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.currency}>
					<span className='textWrap'>{params.row.currency}</span>
				</Tooltip>
			),
		},
		{
			field: 'mobileCode',
			headerName: 'Mobile Code',
			width: 200,

			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.mobileCode}>
					<span className='textWrap'>{params.row.mobileCode}</span>
				</Tooltip>
			),
		},

		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			),
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 160,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/country-management/edit/${params.row.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
					<Switch
						checked={!!params.row.status}
						onChange={() => {
							const { id, status } = params.row;
							const sendStatus: number = status === 1 ? 0 : 1;
							// dispatch(toggleStatus(id));
							dispatch(DeleteCountry({ id, sendStatus }));
						}}
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			),
		},
	];

	useEffect(() => {
		dispatch(
			getAllCountry({
				page: pagination?.currentPage,
				perPage: pagination?.perPage,
				status: 'all',
			})
		);
		dispatch(emptySingleCountry());
	}, [pagination?.currentPage, pagination?.perPage]);

	const { loading } = useSelector((state: RootState) => state.country);

	const debouncedFn = useCallback(
		debounce(q => {
			dispatch(searchCountry({ q }));
		}, 500),
		[]
	);

	useEffect(() => {
		if (query) {
			debouncedFn(query);
		}
	}, [query]);

	return (
		<TableLayout>
			<Header title='Country Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							placeholder='Search by Country name, code'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
							sx={{ width: '18rem' }}
							onChange={(e: any) => setQuery(e.target.value)}
						/>
						{/* <Link to='/country-management/add'>
							<Button startIcon={<AddRounded />} variant='contained'>
								Add
							</Button>
						</Link> */}
						{/* <Button startIcon={<GetAppRounded />} variant='contained'>
							Export
						</Button> */}
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allCountry}
				columns={columns}
				rowHeight={100}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				loading={loading}
				total={pagination?.total}
				onPerPage={(value: number) => {
					dispatch(countrySlice.actions.setCurrentPage(1));
					dispatch(countrySlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(countrySlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
		</TableLayout>
	);
};
export default Country;
