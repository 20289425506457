/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

import {
	addBreadCrumb,
	getBreadCrumbById,
	getBreadCrumbs,
	updateBreadCrumb,
} from '../../api/breadCrumbs';

const initialState: any = {
	breadCrumbs: [],
	loading: false,
	error: '',
	individualBreadCrumb: {},
	loadingBtn: false,
	isAdded: false,
	isUpdated: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const breadCrumbSlice = createSlice({
	name: 'breadCrumbs',
	initialState,
	reducers: {
		toggleLoading: state => {
			state.loading = !state.loading;
		},
		clearIndividualBreadCrumb: state => {
			state.individualBreadCrumb = {};
		},
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
			state.individualBreadCrumb = initialState.individualBreadCrumb;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// all category
			.addCase(getBreadCrumbs.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getBreadCrumbs.fulfilled, (state, action) => {
				state.loading = false;
				const data = action.payload?.data?.map((d: any) => ({
					id: d.id,
					link: d.link,
					linkType: d.linkType,
					status: d.status,
					countries: d.countries,
					createdAt: d.createdAt,
					updatedAt: d.updatedAt,
					name: d?.translation?.[0]?.linkName,
					nameAra: d?.translation?.[1]?.linkName,
				}));
				state.breadCrumbs = data;
				state.pagination = action?.payload?.meta?.pagination;
			})
			.addCase(getBreadCrumbs.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(getBreadCrumbById.pending, (state, action) => {
				state.loading = true;
			})
			.addCase(getBreadCrumbById.fulfilled, (state, action) => {
				state.loading = false;
				const data = action.payload?.data;
				state.individualBreadCrumb = {
					id: data.id,
					link: data.link,
					linkType: data.linkType,
					status: data.status,
					countries: data.country,
					createdAt: data.createdAt,
					updatedAt: data.updatedAt,
					name: data.translation?.[0].linkName,
					nameAra: data.translation?.[1].linkName,
				};
			})
			.addCase(getBreadCrumbById.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(addBreadCrumb.pending, (state, action) => {
				state.loadingBtn = true;
			})
			.addCase(addBreadCrumb.fulfilled, (state, action) => {
				state.isAdded = true;
				state.loadingBtn = false;
			})
			.addCase(addBreadCrumb.rejected, (state, action) => {
				state.loadingBtn = false;
			})
			.addCase(updateBreadCrumb.pending, (state, action) => {
				state.loadingBtn = true;
			})
			.addCase(updateBreadCrumb.fulfilled, (state, action) => {
				state.isUpdated = true;
				state.loadingBtn = false;
				const data = state.breadCrumbs.map((breadCrumb: any) =>
					breadCrumb.id === action.payload?.id
						? { ...breadCrumb, status: action.payload?.status }
						: breadCrumb
				);
				state.breadCrumbs = data;
			})
			.addCase(updateBreadCrumb.rejected, (state, action) => {
				state.loadingBtn = false;
			});
	},
});

export const { toggleLoading, clearData, clearIndividualBreadCrumb } =
	breadCrumbSlice.actions;

export default breadCrumbSlice.reducer;
