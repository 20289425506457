import {
	Box,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
	addBreadCrumb,
	getBreadCrumbById,
	updateBreadCrumb
} from 'api/breadCrumbs';
import { getAllCountry } from 'api/seeder';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearData } from 'redux/breadCrumbs/breadCrumbSlice';
import { RootState } from 'redux/store';
import { allValues, isEmpty, usePrompt } from 'utils/propts';

import FormLable from '../../../components/common/FormLable';
import BreadCrumbLinkInput from './breadCrumbLinkInput';
import validationSchema from './validation';

const useStyles = makeStyles(() => ({
	selectedAll: {
		backgroundColor: 'rgba(186, 84, 245, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(186, 84, 245, 0.12)'
		}
	}
}));

const WithMaterialUI = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const classes = useStyles();
	const { individualBreadCrumb, loadingBtn } = useSelector(
		(state: RootState) => state.breadCrumbs
	);

	const allCountry = useSelector(
		(state: RootState) => state.country.allCountry
	).filter((item: any) => item.code !== 'WW');

	const AllCountryIds = allCountry.map((item: any) => item.id);

	const [selected, setSelected] = useState<string[]>([]);

	useEffect(() => {
		setSelected(
			(individualBreadCrumb?.countries ?? [])?.map((each: any) => each?.id)
		);
		dispatch(getAllCountry());
	}, [individualBreadCrumb]);
	const formik = useFormik({
		initialValues: {
			name: individualBreadCrumb?.name ?? '',
			nameAra: individualBreadCrumb?.nameAra ?? '',
			status: individualBreadCrumb?.status ?? 0,
			link: individualBreadCrumb?.link ?? '',
			countries:
				individualBreadCrumb?.id !== ''
					? individualBreadCrumb?.country?.map((each: any) => each?.id)
					: [],
			linkType: individualBreadCrumb?.linkType ?? 0
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: values => {
			if (id) {
				dispatch(updateBreadCrumb({ data: values, id }));
			} else {
				const payload = {
					link: values.link,
					linkType: values.linkType,
					status: values.status,
					countries: values.countries,
					translation: [
						{
							languageCode: 'en',
							linkName: values.name
						},
						{
							languageCode: 'ara',
							linkName: values.nameAra
						}
					]
				};
				dispatch(addBreadCrumb(payload));
			}
		}
	});
	const [leavePage, setLeavePage] = useState(false);

	useEffect(() => {
		if (allValues(formik.values)) {
			setLeavePage(false);
		} else {
			setLeavePage(!isEmpty(formik.values));
		}
	}, [formik.values]);

	const handleChange = (event: any) => {
		const { value } = event.target;

		if (value[value.length - 1] === 'all') {
			formik.setFieldValue(
				'countries',
				selected.length === allCountry.length ? [] : AllCountryIds
			);
			setSelected(selected.length === allCountry.length ? [] : AllCountryIds);
			return;
		}
		setSelected(value);
		formik.setFieldValue('countries', value);
	};

	const isAllSelected =
		allCountry.length > 0 && selected?.length === allCountry?.length;

	usePrompt(leavePage);
	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='Name' />
						<Input
							sx={{
								marginBottom: '1rem'
							}}
							fullWidth
							id='name'
							name='name'
							placeholder='Enter name'
							value={formik.values.name}
							onChange={formik.handleChange}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.touched.name && formik.errors.name}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Name Ara' textAlign='right' />
						<Input
							sx={{
								marginBottom: '1rem'
							}}
							fullWidth
							id='nameAra'
							name='nameAra'
							dir='rtl'
							placeholder='Enter name arabic'
							value={formik.values.nameAra}
							onChange={formik.handleChange}
							error={formik.touched.nameAra && Boolean(formik.errors.nameAra)}
							helperText={formik.touched.nameAra && formik.errors.nameAra}
						/>
					</Grid>
				</Grid>
				{/* Status */}

				<FormLable label='Status' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								fullWidth
								size='small'
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem selected={formik.values.status === 1} value={1}>
									Active<span style={{ margin: '2px' }}>/</span>صالح
								</MenuItem>
								<MenuItem selected={formik.values.status === 0} value={0}>
									Inactive<span style={{ margin: '2px' }}>/</span>غير صالح
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>

				{formik.values.link !== '/brand' && formik.values.link !== '/category' && (
					<>
						<FormLable label='Country' />
						<Grid container rowSpacing={3} columnSpacing={1}>
							<Grid item xs={6}>
								<FormControl
									sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
									error={formik.touched.countries && Boolean(formik.errors.countries)}
								>
									<Select
										size='small'
										fullWidth
										id='country'
										name='country'
										displayEmpty
										multiple
										value={selected}
										input={<OutlinedInput />}
										onChange={handleChange}
										error={formik.touched.countries && Boolean(formik.errors.countries)}
									>
										<MenuItem
											key='all'
											value='all'
											classes={{
												root: isAllSelected ? classes.selectedAll : ''
											}}
										>
											Select All
										</MenuItem>

										{allCountry.map((each: any) => (
											<MenuItem key={each.id} value={each.id}>
												{
													each.translation.filter(
														(item: any) => item.languageCode === 'en'
													)[0].name
												}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										{formik.touched.countries && formik.errors.countries}
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								{/* Arabic version field */}

								<FormControl
									sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
									error={formik.touched.countries && Boolean(formik.errors.countries)}
								>
									<Select
										size='small'
										fullWidth
										dir='rtl'
										id='country'
										name='country'
										multiple
										displayEmpty
										value={selected}
										input={<OutlinedInput />}
										onChange={handleChange}
										error={formik.touched.countries && Boolean(formik.errors.countries)}
									>
										<MenuItem
											key='all'
											value='all'
											classes={{
												root: isAllSelected ? classes.selectedAll : ''
											}}
										>
											Select All
										</MenuItem>

										{allCountry.map((each: any) => (
											<MenuItem key={each.id} value={each.id}>
												{
													each.translation.filter(
														(item: any) => item.languageCode === 'en'
													)[0].name
												}
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										{formik.touched.countries && formik.errors.countries}
									</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
						<FormLable label='Link Type' />
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<FormControl
									sx={{ width: '100%' }}
									error={formik.touched.linkType && Boolean(formik.errors.linkType)}
								>
									<Select
										fullWidth
										size='small'
										id='status'
										name='linkType'
										displayEmpty
										value={formik.values.linkType}
										input={<OutlinedInput />}
										onChange={formik.handleChange}
										error={formik.touched.linkType && Boolean(formik.errors.linkType)}
									>
										<MenuItem selected={formik.values.linkType === 1} value={1}>
											External
											<span style={{ margin: '2px' }} />
										</MenuItem>
										<MenuItem selected={formik.values.linkType === 0} value={0}>
											Internal
											<span style={{ margin: '2px' }} />
										</MenuItem>
									</Select>
									<FormHelperText>
										{formik.touched.linkType && formik.errors.linkType}
									</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
						<BreadCrumbLinkInput
							selectedType={formik.values.linkType}
							link={formik.values.link}
							formikInstance={formik}
							selectedCountry={selected}
							onChange={(value: string) => {
								formik.setFieldValue('link', value);
							}}
						/>
					</>
				)}
			</Box>

			<Button
				sx={{
					marginTop: '1.5rem',
					marginBottom: '1rem'
				}}
				color='primary'
				variant='contained'
				fullWidth
				loading={loadingBtn}
				type='submit'
			>
				Submit
			</Button>
		</form>
	);
};

const BreadCrumbsAdd = () => {
	const { loading, isAdded, isUpdated } = useSelector(
		(state: RootState) => state.breadCrumbs
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('/bread-crumbs');
		}
		return () => {
			dispatch(clearData());
		};
	}, [isAdded, isUpdated]);
	useEffect(() => {
		if (id) {
			dispatch(getBreadCrumbById(id));
		}
	}, []);

	return (
		<TableLayout>
			<Container maxWidth='lg'>
				<Header title={id ? 'Edit Custom Link' : 'Add Custom Link'} />
				<Box>
					<Box>
						{loading ? (
							<Box
								sx={{
									width: '100%',
									height: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'absolute',
									backgroundColor: 'white',
									opacity: '0.7',
									zIndex: '999',
									minHeight: 'calc(100vh - 80px)'
								}}
							>
								<Loader />
							</Box>
						) : (
							<WithMaterialUI />
							// <Box>abc</Box>
						)}
					</Box>
				</Box>
			</Container>
		</TableLayout>
	);
};

export default BreadCrumbsAdd;
