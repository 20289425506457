import { createSlice } from '@reduxjs/toolkit';

import {
	changeAdvertisementStatus,
	getAllAdvertisement,
	searchAdvertisement,
} from '../../api/advertise';

const initialState: any = {
	allAdvertisements: [],
	error: '',
	filterByStatus: -1,
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const advertisementSlice = createSlice({
	name: 'advertisement',
	initialState,
	reducers: {
		setFilterByStatus: (state, action) => {
			state.filterByStatus = action.payload.status;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getAllAdvertisement.pending, state => {
				state.loading = true;
			})
			.addCase(getAllAdvertisement.fulfilled, (state, action) => {
				state.loading = false;
				state.allAdvertisements = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllAdvertisement.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search user
			.addCase(searchAdvertisement.pending, state => {
				state.loading = true;
			})
			.addCase(searchAdvertisement.fulfilled, (state, action) => {
				state.loading = false;
				state.allAdvertisements = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchAdvertisement.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			.addCase(changeAdvertisementStatus.pending, state => {
				state.loading = true;
			})
			.addCase(changeAdvertisementStatus.fulfilled, state => {
				state.loading = false;
			})
			.addCase(changeAdvertisementStatus.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { setFilterByStatus } = advertisementSlice.actions;

export default advertisementSlice.reducer;
