import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { newsSlice } from 'redux/newsLetter/newsSlice';
// import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';
import { ApiEndPoints } from './constant';

export const getAllNewsLetters = createAsyncThunk(
	'getAllNewsLetters',
	async (prams: any) => {
		try {
			const response = await axiosInstance.get('admin/news-letters', {
				params: prams,
				headers: {
					'x-locale': 'en',
				},
			});

			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const searchNewsLetter = createAsyncThunk(
	'searchNewsLetter',
	async ({ q, page, status }: any) => {
		try {
			const response = await axiosInstance.get(`admin/news-letters?q=${q}`, {
				params: {
					page,
					status,
				},
			});
			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);
export const exportNewsLetter = createAsyncThunk(
	'ExportNewsLetter',
	async ({ status }: { status: any }, thunkAPI) => {
		const { dispatch } = thunkAPI;
		try {
			dispatch(newsSlice.actions.setNewLetterExportLoading(true));
			const response = await axiosInstance.post(ApiEndPoints.exportNewsLetter, {
				status,
			});
			if (response.status === 200) {
				const downloadUrl = response.data.data.url;

				const link = document.createElement('a');
				link.href = downloadUrl;
				link.click();

				toast.success('Subscribers Data downloaded successfully.');
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		} finally {
			dispatch(newsSlice.actions.setNewLetterExportLoading(false));
		}
		return null;
	}
);
