/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import {
	Box,
	Stack,
	Switch,
	Tab,
	Tabs,
	Tooltip,
	Typography
} from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
	getTrendingBrands,
	getTrendingCategories,
	getTrendingCoupons,
	toggleTrending
} from 'api/trending';
import Header from 'components/common/Header';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { trendingSlice } from 'redux/trending/trendingSlice';
// import { toggleLoading } from 'redux/trending/trendingSlice';
import { disablefilter, rewardType } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';

import styles from './index.module.scss';

interface TabPanelProps {
	children: any;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const TrendingBrand = () => {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	const [values, setValue] = useState(
		searchParams.has('couponType') ? Number(searchParams.get('couponType')) : 0
	);

	const { brands, coupons, categories, loading, pagination } = useSelector(
		(state: RootState) => state?.trending
	);

	const filteredCoupons = coupons?.filter((item: any) => item.coupons);

	useEffect(() => {
		switch (values) {
			case 0:
				dispatch(
					getTrendingBrands({
						page: pagination?.currentPage,
						perPage: pagination?.perPage
					})
				);
				break;
			case 1:
				dispatch(
					getTrendingCategories({
						page: pagination?.currentPage,
						perPage: pagination?.perPage
					})
				);
				break;
			case 2:
				dispatch(
					getTrendingCoupons({
						couponType: 2,
						page: pagination?.currentPage,
						perPage: pagination?.perPage
					})
				);
				break;
			case 3:
				dispatch(
					getTrendingCoupons({
						couponType: 1,
						page: pagination?.currentPage,
						perPage: pagination?.perPage
					})
				);
				break;
			case 4:
				dispatch(
					getTrendingCoupons({
						couponType: 3,
						page: pagination?.currentPage,
						perPage: pagination?.perPage
					})
				);
				break;
			default:
				dispatch(
					getTrendingBrands({
						page: pagination?.currentPage,
						perPage: pagination?.perPage
					})
				);
				break;
		}
	}, [pagination?.currentPage, pagination?.perPage, values]);

	useEffect(() => {
		setSearchParams({ couponType: `${values}` });
	}, [values]);

	const handleChange = (event: any, newValue: number) => {
		dispatch(trendingSlice.actions.setCurrentPage(1));
		setValue(newValue);
	};

	const couponsColumns: GridColDef[] = [
		{
			field: 'brandName',
			headerName: 'Brand Name',
			width: 200,
			valueGetter: (params: GridValueGetterParams) =>
				`${params?.row?.coupons?.brand?.brandTranslation[0]?.name || ''}`
		},
		{
			field: 'brandImage',
			headerName: 'Brand Image',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={
							params?.row?.coupons?.brand?.brandTranslation[0]?.imageSlug?.path
								? `${params?.row?.coupons?.brand?.brandTranslation[0]?.imageSlug?.path}`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'cover',
							padding: '0.5rem'
						}}
					/>
				</Box>
			)
		},
		{
			field: 'brandImageAra',
			headerName: 'Brand Image (Arabic)',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={
							params?.row?.coupons?.brand?.brandTranslation[1]?.imageSlug?.path
								? `${params?.row?.coupons?.brand?.brandTranslation[1]?.imageSlug?.path}`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'cover',
							padding: '0.5rem'
						}}
					/>
				</Box>
			)
		},
		{
			field: 'Coupon code',
			headerName: 'Coupon code',
			width: 200,
			valueGetter: (params: GridValueGetterParams) =>
				`${params?.row?.coupons?.couponCode || 'N/A'}`
		},
		{
			field: 'country',
			headerName: 'Country',
			width: 200,
			renderCell: (params: GridValueGetterParams) => {
				const countryArray = params?.row?.coupons?.country;
				const arr = [];
				// eslint-disable-next-line no-plusplus
				for (let i = 0; i < countryArray.length; i++) {
					arr.push(
						countryArray[i].translation.filter(
							(item: any) => item.languageCode === 'en'
						)[0].name
					);
				}

				return (
					<Tooltip title={arr.join(', ')}>
						<div className={styles.workBreak}>{arr.join(', ') || '-'}</div>
					</Tooltip>
				);
			}
		},
		{
			field: 'views',
			headerName: 'Views',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<span>
					{params?.row?.coupons?.views.length > 0
						? params?.row?.coupons?.views[0].views
						: ''}
				</span>
			)
		},

		{
			field: 'expiryDate',
			headerName: 'End Date',
			...disablefilter,
			width: 200,
			valueGetter: (params: GridValueGetterParams) => `
			${getArabicTime(params?.row?.coupons?.expiryDate, 'date')}`
		},
		{
			field: 'rewardType',
			headerName: 'Reward Type',
			...disablefilter,
			width: 200,
			valueGetter: (params: GridValueGetterParams) =>
				// eslint-disable-next-line no-unsafe-optional-chaining
				`${rewardType[params?.row?.coupons?.rewardType - 1]}`
		},
		{
			field: 'rewardValue',
			headerName: 'Reward Value',
			...disablefilter,
			width: 200,
			valueGetter: (params: GridValueGetterParams) =>
				`${params?.row?.coupons?.rewardValue}`
		},

		{
			field: 'trending',
			headerName: 'Trending',
			...disablefilter,
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Switch
						checked={!!params.row.isTrending}
						onChange={() => {
							const isTrending = params?.row?.isTrending;
							const id = params?.row?.coupons?.id;
							const isTrend: number = isTrending === 1 ? 0 : 1;
							const tab: number = values === 4 ? 3 : values === 3 ? 1 : 2;
							const x = {
								entityId: id,
								entityType: 'coupons',
								isTrending: isTrend,
								// setState: toggleLoading,
								tab,
								getAll: getTrendingCoupons
							};
							dispatch(toggleTrending(x));
						}}
						color='success'
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			)
		}
	];

	const bransColumns: GridColDef[] = [
		// {
		// 	field: 'id',
		// 	headerName: 'ID',
		// 	width: 70,
		// },
		{
			field: 'brandName',
			headerName: 'Brand name',
			width: 200,
			valueGetter: (params: GridValueGetterParams) =>
				`${params?.row?.brandTranslation?.en[0]?.name || ''}`
		},
		{
			field: 'brandImage',
			headerName: 'Brand Image',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={
							params?.row?.brandTranslation.en[0]?.imageSlug?.path
								? `${params?.row?.brandTranslation.en[0]?.imageSlug?.path}`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'cover',
							padding: '0.5rem'
						}}
					/>
				</Box>
			)
		},
		{
			field: 'brandImageAra',
			headerName: 'Brand Image (Arabic)',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={
							params?.row?.brandTranslation.ara[0]?.imageSlug?.path
								? `${params?.row?.brandTranslation.ara[0]?.imageSlug?.path}`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100px'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'cover',
							padding: '0.5rem'
						}}
					/>
				</Box>
			)
		},
		{
			field: 'country',
			headerName: 'Country',
			width: 200,
			renderCell: (params: GridValueGetterParams) => {
				const countryArray = params?.row?.brands?.country;
				const arr = [];
				// eslint-disable-next-line no-plusplus
				for (let i = 0; i < countryArray.length; i++) {
					arr.push(
						countryArray[i].translation.filter(
							(item: any) => item.languageCode === 'en'
						)[0].name
					);
				}

				return (
					<Tooltip title={arr.join(', ')}>
						<div className={styles.workBreak}>{arr.join(', ') || '-'}</div>
					</Tooltip>
				);
			}
		},
		{
			field: 'views',
			headerName: 'Views',
			width: 200,
			valueGetter: (params: GridValueGetterParams) => `${params.row?.views}`
		},
		{
			field: 'trending',
			headerName: 'Trending',
			...disablefilter,
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Switch
						checked={!!params.row.isTrending}
						onChange={() => {
							const isTrending = params?.row?.isTrending;
							const id = params?.row?.brands.id;
							const isTrend: number = isTrending === 1 ? 0 : 1;
							const x = {
								entityId: id,
								entityType: 'brands',
								isTrending: isTrend,
								// setState: toggleLoading,
								getAll: getTrendingBrands,
								tab: values
							};
							dispatch(toggleTrending(x));
						}}
						color='success'
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			)
		}
	];

	const categoryColumns: GridColDef[] = [
		// {
		// 	field: 'id',
		// 	headerName: 'ID',
		// 	width: 70,
		// },
		{
			field: 'categoryName',
			headerName: 'Category name',
			width: 200,
			valueGetter: (params: GridValueGetterParams) =>
				`${params?.row?.translation?.en?.[0]?.name || ''}`
		},
		{
			field: 'categoryImage',
			headerName: 'Category Image',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='130px' height='130px'>
					<img
						src={
							params?.row?.categories?.translation
								? `${
										params?.row?.categories?.translation?.filter(
											(item: any) => item.languageCode === 'en'
										)[0]?.imageSlug.path
								  }`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100%'
						style={{
							backgroundPosition: 'left',
							objectFit: 'contain',
							padding: '0.5rem'
						}}
					/>
				</Box>
			)
		},
		{
			field: 'categoryImageAra',
			headerName: 'Category Image (Arabic)',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='130px' height='130px'>
					<img
						src={
							params?.row?.categories?.translation
								? `${
										params?.row?.categories?.translation?.filter(
											(item: any) => item.languageCode === 'ara'
										)[0]?.imageSlug.path
								  }`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100%'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'contain',
							padding: '0.5rem'
						}}
					/>
				</Box>
			)
		},
		{
			field: 'views',
			headerName: 'Views',
			width: 200,
			valueGetter: (params: GridValueGetterParams) => `${params.row?.views}`
		},
		{
			field: 'trending',
			headerName: 'Trending',
			...disablefilter,
			sortable: false,
			width: 160,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Switch
						checked={!!params.row.isTrending}
						onChange={() => {
							const isTrending = params?.row?.isTrending;
							const id = params?.row?.categories.id;
							const isTrend: number = isTrending === 1 ? 0 : 1;
							const x = {
								entityId: id,
								entityType: 'categories',
								isTrending: isTrend,
								// setState: toggleLoading,
								getAll: getTrendingCategories
							};
							dispatch(toggleTrending(x));
						}}
						color='success'
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</Stack>
			)
		}
	];

	// const [expanded, setExpanded] = useState<string | false>('');

	// const handleChange =
	// 	(panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
	// 		setExpanded(isExpanded ? panel : false);
	// 	};

	return (
		<TableLayout>
			<Header title='Trending' />

			<Tabs value={values} onChange={handleChange} aria-label='basic tabs example'>
				<Tab label='Trending Brand' style={{ padding: '0px' }} />
				<Tab label='Trending Category' style={{ marginLeft: '5px' }} />
				<Tab label='Trending Coupons' style={{ marginLeft: '5px' }} />
				<Tab label='Trending Cashback' style={{ marginLeft: '5px' }} />
				<Tab label='Trending Offers' style={{ marginLeft: '5px' }} />
			</Tabs>

			<TabPanel index={0} value={values}>
				<Table
					loading={loading}
					rows={brands}
					columns={bransColumns}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					rowCount={pagination?.perPage}
					rowHeight={85}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(trendingSlice.actions.setCurrentPage(1));
						dispatch(trendingSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(trendingSlice.actions.setCurrentPage(value))
					}
					getRowId={row => row?.brands?.id}
					sx={{ height: '70vh' }}
				/>
			</TabPanel>

			<TabPanel index={1} value={values}>
				<Table
					loading={loading}
					rows={categories}
					columns={categoryColumns}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					rowCount={pagination?.perPage}
					rowHeight={115}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(trendingSlice.actions.setCurrentPage(1));
						dispatch(trendingSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(trendingSlice.actions.setCurrentPage(value))
					}
					getRowId={row => row?.categories?.id}
					sx={{ height: '70vh' }}
				/>
			</TabPanel>
			<TabPanel index={2} value={values}>
				<Table
					rowHeight={95}
					loading={loading}
					rows={coupons}
					columns={couponsColumns}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					rowCount={pagination?.perPage}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(trendingSlice.actions.setCurrentPage(1));
						dispatch(trendingSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(trendingSlice.actions.setCurrentPage(value))
					}
					getRowId={row => row?.coupons?.id}
					sx={{ height: '70vh' }}
				/>
			</TabPanel>
			<TabPanel index={3} value={values}>
				<Table
					rowHeight={100}
					loading={loading}
					rows={filteredCoupons}
					columns={couponsColumns}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					rowCount={pagination?.perPage}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(trendingSlice.actions.setCurrentPage(1));
						dispatch(trendingSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(trendingSlice.actions.setCurrentPage(value))
					}
					getRowId={row => row?.coupons?.id}
					sx={{ height: '70vh' }}
				/>
			</TabPanel>
			<TabPanel index={4} value={values}>
				<Table
					rowHeight={100}
					loading={loading}
					rows={filteredCoupons}
					columns={couponsColumns}
					count={pagination?.totalPages}
					page={pagination?.currentPage}
					rowCount={pagination?.perPage}
					total={pagination?.total}
					perPage={pagination?.perPage}
					onPerPage={(value: number) => {
						dispatch(trendingSlice.actions.setCurrentPage(1));
						dispatch(trendingSlice.actions.setPerPage(value));
					}}
					onChange={(_e: ChangeEvent, value: number) =>
						dispatch(trendingSlice.actions.setCurrentPage(value))
					}
					getRowId={row => row?.coupons?.id}
					sx={{ height: '70vh' }}
				/>
			</TabPanel>
		</TableLayout>
	);
};
export default TrendingBrand;
