import * as yup from 'yup';

// const FILE_SIZE = 2000000;
// const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const validationSchema = yup.object({
	brandName: yup
		.string()
		.min(2, 'Enter a valid Brand Name')
		.required('Brand Name is required'),
	brandName_ara: yup
		.string()
		.min(2, 'أدخل اسم علامة تجارية صالحًا')
		.required('اسم العلامة التجارية مطلوب'),
	brandLogo: yup.string().required('Logo is required'),
	status: yup.number().required('Status is required.'),
	seoMetas: yup
		.string()
		.max(255, 'Description must be at most 255 characters')
		.nullable(),
	country: yup.array().min(1, 'select  atleast one country'),
});

export default validationSchema;
