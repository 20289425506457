import { createSlice } from '@reduxjs/toolkit';

import {
	AddRoles,
	DeleteRoles,
	getAllRoles,
	getRolesById,
	searchRoles,
	UpdateRoles,
} from '../../api/roles';

const initialState: any = {
	allRoles: [],
	error: '',
	singleRoles: {},
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const roleSlice = createSlice({
	name: 'role',
	initialState,
	reducers: {
		emptySingleRole: state => {
			state.singleRoles = initialState.singleRoles;
		},
		toggleStatus: (state, action) => {
			state.allRoles = state.allRoles.map((d: any) =>
				d.id === action.payload ? { ...d, status: !d.status } : { ...d }
			);
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getAllRoles.pending, (state, action) => {
				state.loading = !action?.meta?.arg?.isRefresh;
			})
			.addCase(getAllRoles.fulfilled, (state, action) => {
				state.loading = false;
				state.allRoles = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllRoles.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search user
			.addCase(searchRoles.pending, state => {
				state.loading = true;
			})
			.addCase(searchRoles.fulfilled, (state, action) => {
				state.loading = false;
				state.allRoles = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchRoles.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// add brand
			.addCase(AddRoles.pending, state => {
				state.loadingBtn = true;
			})
			.addCase(AddRoles.fulfilled, state => {
				state.loadingBtn = false;
			})
			.addCase(AddRoles.rejected, (state, action) => {
				state.loadingBtn = false;
				state.error = action.error.message;
			})

			// update

			.addCase(UpdateRoles.pending, state => {
				state.loadingBtn = true;
			})
			.addCase(UpdateRoles.fulfilled, state => {
				state.loadingBtn = false;
			})
			.addCase(UpdateRoles.rejected, (state, action) => {
				state.loadingBtn = false;
				state.error = action.error.message;
			})

			// get single brand
			.addCase(getRolesById.pending, state => {
				state.loading = true;
			})
			.addCase(getRolesById.fulfilled, (state, action) => {
				state.loading = false;
				state.singleRoles = action?.payload?.data;
			})
			.addCase(getRolesById.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// change status
			.addCase(DeleteRoles.pending, state => {
				state.loading = true;
			})
			.addCase(DeleteRoles.fulfilled, state => {
				state.loading = false;
			})
			.addCase(DeleteRoles.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { emptySingleRole, toggleStatus } = roleSlice.actions;

export default roleSlice.reducer;
