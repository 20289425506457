import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import getGenericErrorMessage from 'utils/error';

import axiosInstance from '../utils/axiosInstance';

// eslint-disable-next-line import/prefer-default-export
export const addSetting = createAsyncThunk(
	'addSetting',
	async ({ data }: any) => {
		try {
			const response = await axiosInstance.post('admin/settings', data);
			if (response.status === 200) {
				toast.success('Added successfully');
				return response.data;
			}
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);
export const addFaq = createAsyncThunk('addFaq', async ({ data }: any) => {
	try {
		const response = await axiosInstance.post('admin/settings', data);
		if (response.status === 200) {
			toast.success('Added successfully');
			return response.data;
		}
	} catch (e) {
		toast.error(getGenericErrorMessage(e));
	}
	return null;
});
export const getSettings = createAsyncThunk('getSettings', async () => {
	try {
		const response = await axiosInstance.get('admin/settings');
		return response.data;
	} catch (e) {
		toast.error(getGenericErrorMessage(e));
	}
	return null;
});
export const getFaq = createAsyncThunk('getFaq', async ({ key }: any) => {
	try {
		const response = await axiosInstance.get(`admin/settings`, {
			params: {
				key,
			},
		});

		if (response.status === 200) {
			return response.data;
		}
		throw new Error('Data not found');
	} catch (e) {
		toast.error(getGenericErrorMessage(e));
	}
	return null;
});

export const getSetting = createAsyncThunk(
	'getSetting',
	async ({ key }: any) => {
		try {
			const response = await axiosInstance.get('admin/settings', {
				params: {
					key,
				},
			});
			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);
