import * as yup from 'yup';

const validationSchema = yup.object({
	type: yup.number().required('Notification type is required.'),
	link: yup
		.string()
		.required('Link is required')
		.when('type', {
			is: 1,
			then: yup
				.string()
				.required('Link is required')
				.matches(
					/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/,
					'Link must start with http:// or https://'
				),
		}),
	status: yup.number().required('Status is required'),
	imageSlug: yup.string().required('Image is required'),
});

export default validationSchema;
