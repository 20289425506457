/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */

import {
	Checkbox,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	ListItemIcon,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { AddRoles, getRolesById, UpdateRoles } from 'api/roles';
import { getAllallPermissions } from 'api/seeder';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';

import FormLable from '../../../components/common/FormLable';
import styles from './roles.module.scss';
import validationSchema from './validation';

const useStyles = makeStyles(theme => ({
	selectedAll: {
		backgroundColor: 'rgba(186, 84, 245, 0.08)',
		'&:hover': {
			backgroundColor: 'rgba(186, 84, 245, 0.12)',
		},
	},
}));

const WithMaterialUI = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { allPermissions } = useSelector((state: RootState) => state.country);
	const { loadingBtn, singleRoles } = useSelector(
		(state: RootState) => state.role
	);
	const classes = useStyles();
	const [selected, setSelected] = useState<string[]>([]);
	const isAllSelected =
		allPermissions?.length > 0 && selected?.length === allPermissions?.length;

	const formik = useFormik({
		initialValues: {
			name: id && singleRoles?.name !== '' ? singleRoles?.name : '',
			status: id && singleRoles ? singleRoles.status : 0,
			permissions:
				id && singleRoles?.permissions
					? singleRoles?.permissions?.permissions.map((e: any) => e.id)
					: [],
		},
		validationSchema,
		onSubmit: values => {
			if (id) {
				const backendObject = values;
				dispatch(UpdateRoles({ backendObject, id }));
			} else {
				dispatch(AddRoles(values));
			}
		},
	});

	useEffect(() => {
		dispatch(getAllallPermissions());
	}, []);

	useEffect(() => {
		if (id) {
			setSelected(singleRoles?.permissions?.permissions.map((e: any) => e.id));
		}
	}, [id]);

	const AllIds = allPermissions?.map((item: any) => item.id);

	const handleChange = (event: any) => {
		const { value } = event.target;

		if (value[value.length - 1] === 'all') {
			formik.setFieldValue(
				'permissions',
				selected?.length === allPermissions?.length ? [] : AllIds
			);
			setSelected(selected?.length === allPermissions?.length ? [] : AllIds);
			return;
		}
		setSelected(value);
		formik.setFieldValue('permissions', value);
	};

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				{/* Brand Name */}

				<Grid container rowSpacing={4} columnSpacing={3}>
					<Grid item xs={12} className='my-grid'>
						{/* English version field */}
						<FormLable label='Role Name' />
						<Input
							fullWidth
							id='name'
							name='name'
							placeholder='Enter Role'
							value={formik.values.name}
							onChange={formik.handleChange}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.touched.name && formik.errors.name}
						/>
					</Grid>

					{/* Status */}
					<Grid item xs={12} className='my-grid'>
						<FormLable label='Status' />
						{/* English version field */}

						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								size='small'
								fullWidth
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem selected={formik.values.status === 1} value={1}>
									Active
								</MenuItem>
								<MenuItem selected={formik.values.status === 0} value={0}>
									Inactive
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
					<Grid item xs={12} className='my-grid'>
						<FormLable label='Permissions' />
						<FormControl className={styles.formControl}>
							<Select
								size='small'
								fullWidth
								id='permissions'
								name='permissions'
								displayEmpty
								multiple
								renderValue={() =>
									allPermissions
										?.filter((e: any) => selected?.includes(e.id))
										?.map((ee: any) => ee.name)
										.join(', ')
								}
								value={selected}
								input={<OutlinedInput />}
								onChange={handleChange}
								error={formik.touched.permissions && Boolean(formik.errors.permissions)}
							>
								<MenuItem
									value='all'
									classes={{
										root: isAllSelected ? classes.selectedAll : '',
									}}
								>
									<ListItemIcon>
										<Checkbox checked={isAllSelected} />
									</ListItemIcon>
									<ListItemText primary='Select All' />
								</MenuItem>
								{allPermissions?.map((option: any) => (
									<MenuItem key={option.id} value={option.id}>
										<Checkbox checked={selected?.includes(option.id)} />
										<ListItemText primary={option.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Box>

			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem',
				}}
				color='primary'
				variant='contained'
				fullWidth
				loading={loadingBtn}
				type='submit'
			>
				Submit
			</Button>
		</form>
	);
};

const RolesAdd = () => {
	const { loading } = useSelector((state: RootState) => state.role);
	const dispatch = useDispatch();
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			dispatch(getRolesById(id));
		}
	}, []);

	return (
		<TableLayout>
			<Container maxWidth='lg'>
				<Header title={id ? 'Edit Role' : 'Add Role'} />
				<Box>
					<Box>
						{loading ? (
							<Box
								sx={{
									width: '100%',
									height: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'absolute',
									backgroundColor: 'white',
									opacity: '0.7',
									zIndex: '999',
									minHeight: 'calc(100vh - 80px)',
								}}
							>
								<Loader />
							</Box>
						) : (
							<WithMaterialUI />
							// <Box>abc</Box>
						)}
					</Box>
				</Box>
			</Container>
		</TableLayout>
	);
};

export default RolesAdd;
