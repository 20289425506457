import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
// import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllAccounts = createAsyncThunk(
	'getAllAccounts',
	async (prams: any) => {
		try {
			const response = await axiosInstance.get('admin/banks', {
				params: prams,
				headers: {
					'x-locale': 'en',
				},
			});

			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);

export const searchBankAccount = createAsyncThunk(
	'searchBankAccount',
	async ({ q }: any) => {
		try {
			const response = await axiosInstance.get(`admin/banks?q=${q}`);
			return response.data;
		} catch (e) {
			toast.error(getGenericErrorMessage(e));
		}
		return null;
	}
);
