import 'react-quill/dist/quill.snow.css';

import { Typography } from '@mui/material';
import { addSetting, getSettings } from 'api/settings';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import TableLayout from 'components/common/TableLayout';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const Settings = () => {
	const { settings, privacyBtnLoading, termsBtnLoading } = useSelector(
		(state: RootState) => state.settings
	);

	const [privacyPolicy, setPrivacyPolicy] = useState('');
	const [privacyPolicyArabic, setPrivacyPolicyArabic] = useState('');

	const [tnc, setTnc] = useState('');
	const [tncArabic, setTncArabic] = useState('');
	const dispatch = useDispatch();

	// const [error, setError] = useState(false);

	useEffect(() => {
		dispatch(getSettings());
	}, []);

	useEffect(() => {
		if (Object.keys(settings).length > 0) {
			if (settings?.Privacy) {
				setPrivacyPolicy(settings?.Privacy[0]?.translation?.en[0]?.value);
				setPrivacyPolicyArabic(settings?.Privacy[0]?.translation?.ara[0]?.value);
			}

			if (settings?.Terms) {
				setTnc(settings?.Terms[0]?.translation?.en[0]?.value);
				setTncArabic(settings?.Terms[0]?.translation?.ara[0]?.value);
			}
		}
	}, [settings]);

	const savePP = () => {
		if (privacyPolicy !== '' && privacyPolicyArabic !== '') {
			const privacyObject = {
				key: 'Privacy',
				translation: [
					{
						languageCode: 'en',
						value: privacyPolicy,
					},
					{
						languageCode: 'ara',
						value: privacyPolicyArabic,
					},
				],
			};
			dispatch(addSetting({ data: privacyObject }));
		} else {
			// setError(true);
		}
	};

	const saveTnc = () => {
		if (tnc !== '' && tncArabic !== '') {
			const tncObject = {
				key: 'Terms',
				translation: [
					{
						languageCode: 'en',
						value: tnc,
					},
					{
						languageCode: 'ara',
						value: tncArabic,
					},
				],
			};
			dispatch(addSetting({ data: tncObject }));
		} else {
			// setError(true);
		}
	};

	return (
		<TableLayout>
			<Header title='Settings Management' />
			<Typography variant='h6' component='h6' my={2}>
				Privacy Policy - English
			</Typography>

			<ReactQuill theme='snow' value={privacyPolicy} onChange={setPrivacyPolicy} />

			<Typography variant='h6' component='h6' my={2}>
				Privacy Policy - Arabic
			</Typography>

			<ReactQuill
				theme='snow'
				value={privacyPolicyArabic}
				onChange={setPrivacyPolicyArabic}
			/>
			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem',
				}}
				onClick={savePP}
				loading={privacyBtnLoading}
				color='primary'
				variant='contained'
				type='submit'
			>
				Submit
			</Button>

			<Typography variant='h6' component='h6' my={2}>
				Terms and condition - English
			</Typography>
			<ReactQuill theme='snow' value={tnc} onChange={setTnc} />
			<Typography variant='h6' component='h6' my={2}>
				Terms and condition - Arabic
			</Typography>
			<ReactQuill theme='snow' value={tncArabic} onChange={setTncArabic} />
			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem',
				}}
				onClick={saveTnc}
				loading={termsBtnLoading}
				color='primary'
				variant='contained'
				type='submit'
			>
				Submit
			</Button>
		</TableLayout>
	);
};
export default Settings;
