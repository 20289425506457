import { createSlice } from '@reduxjs/toolkit';
import {
	AddCountry,
	DeleteCountry,
	getCountryById,
	searchCountry,
	UpdateCountry,
} from 'api/country';
import { getAllallPermissions, getAllCountry } from 'api/seeder';
import { groupBy } from 'lodash';

export interface IpaginationObject {
	currentPage: number;
	totalPages: number;
	perPage: number;
	total: number;
}
interface ICountry {
	allCountry: any;
	// allRoles: any;
	loading: boolean;
	allPermissions: any;
	error: string | undefined;
	pagination: IpaginationObject;
	isAdded: boolean;
	isUpdated: boolean;
	loadingBtn: boolean;
	singleCountry: any;
}

const initialState: ICountry = {
	allCountry: [],
	// allRoles: [],
	allPermissions: [],
	loading: false,
	error: '',
	isAdded: false,
	isUpdated: false,
	loadingBtn: false,
	singleCountry: {},
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const countrySlice = createSlice({
	name: 'country',
	initialState,
	reducers: {
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		emptySingleCountry: state => {
			state.singleCountry = initialState.singleCountry;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getAllCountry.pending, state => {
				state.loading = false;
			})
			.addCase(getAllCountry.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
					})
				);
				state.allCountry = array;
				state.pagination = action?.payload?.meta?.pagination;
			})
			.addCase(getAllCountry.rejected, state => {
				state.loading = false;
			})

			.addCase(searchCountry.pending, state => {
				state.loading = true;
			})
			.addCase(searchCountry.fulfilled, (state, action) => {
				state.loading = false;
				state.allCountry = [...action.payload.data];
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchCountry.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// add country
			.addCase(AddCountry.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(AddCountry.fulfilled, state => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(AddCountry.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = false;
				state.error = action.error.message;
			})

			// update country
			.addCase(UpdateCountry.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdateCountry.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdateCountry.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;
				state.error = action.error.message;
			})

			.addCase(getCountryById.pending, state => {
				state.loading = true;
			})
			.addCase(getCountryById.fulfilled, (state, action) => {
				state.loading = false;

				const data = {
					...action?.payload?.data,
					translation: groupBy(action?.payload?.data?.translation, 'languageCode'),
				};

				state.singleCountry = data;
			})
			.addCase(getCountryById.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// change status

			.addCase(DeleteCountry.pending, state => {
				state.loading = false;
			})
			.addCase(DeleteCountry.fulfilled, state => {
				state.loading = false;
			})
			.addCase(DeleteCountry.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			.addCase(getAllallPermissions.pending, state => {
				state.loading = false;
			})
			.addCase(getAllallPermissions.fulfilled, (state, action) => {
				state.loading = true;
				state.allPermissions = action.payload.data;
			})
			.addCase(getAllallPermissions.rejected, state => {
				state.loading = false;
			});

		// roles

		// .addCase(getAllRoles.pending, state => {
		// 	state.loading = false;
		// })
		// .addCase(getAllRoles.fulfilled, (state, action) => {
		// 	state.loading = true;
		// 	state.allRoles = action.payload.data;
		// })
		// .addCase(getAllRoles.rejected, state => {
		// 	state.loading = false;
		// })

		// permission
	},
});

export const { clearData, emptySingleCountry } = countrySlice.actions;

export default countrySlice.reducer;
