import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllCountry = createAsyncThunk(
	'getAllCountry',
	async (params: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get('admin/countries', {
				params,
				headers: {
					'x-locale': 'en',
				},
			});

			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const AddCountry = createAsyncThunk(
	'AddCountry',
	async (backendObject: any, thunkAPI) => {
		try {
			const response = await axiosInstance.post(`admin/countries`, backendObject);
			if (response.status === 201) {
				toast.success('Country added successfully');
				thunkAPI.dispatch(push('/country-management'));

				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const UpdateCountry = createAsyncThunk(
	'UpdateCountry',
	async (
		{ backendObject, id }: { backendObject: any; id: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.put(
				`admin/countries/${id}`,
				backendObject
			);
			if (response.status === 200) {
				toast.success('Country updated successfully');
				thunkAPI.dispatch(push('/country-management'));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const DeleteCountry = createAsyncThunk(
	'DeleteCountry',
	async ({ id, sendStatus }: { id: string; sendStatus: number }, thunkAPI) => {
		try {
			const response = await axiosInstance.patch(`admin/countries/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success(
					sendStatus
						? 'Country status changed to Active'
						: 'Country status changed to Inactive'
				);
				const { client }: any = thunkAPI.getState();
				const page = client.pagination.currentPage;
				thunkAPI.dispatch(getAllCountry({ page, isRefresh: true }));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const getCountryById = createAsyncThunk(
	'getCountryById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/countries/${id}`);
			if (response.status === 200) {
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const searchCountry = createAsyncThunk(
	'searchCountry',
	async ({ q }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/countries?q=${q}`);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
