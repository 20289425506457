import { createSlice } from '@reduxjs/toolkit';
import { getAllChatThreads, searchUserThread } from 'api/chatThreads';

const initialState: any = {
	chatThreads: [],
	chatMessages: { messages: [], user: {} },
	chatUser: {},
	loading: false,
	loadingMessages: false,
	loadingBtn: false,
	error: '',
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const chatThreadsSlice = createSlice({
	name: 'chatThreads',
	initialState,
	reducers: {
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
		updateThreadStatus: (state, { payload }) => {
			state.chatThreads = state.chatThreads.map((thread: any, index: any) =>
				thread.id !== payload.id ? thread : { ...thread, status: payload.status }
			);
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getAllChatThreads.pending, (state, action) => {
				state.loading = !action.meta.arg.isRefresh;
			})
			.addCase(getAllChatThreads.fulfilled, (state, action) => {
				state.loading = false;
				state.chatThreads = action.payload?.data;
				state.pagination = action?.payload?.meta?.pagination;
			})
			.addCase(getAllChatThreads.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})
			.addCase(searchUserThread.pending, state => {
				state.loading = true;
			})
			.addCase(searchUserThread.fulfilled, (state, action) => {
				state.loading = false;
				state.chatThreads = action.payload?.data;
				state.pagination = action?.payload?.meta?.pagination;
			})
			.addCase(searchUserThread.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export default chatThreadsSlice.reducer;
