const NotFound = () => (
	<div
		style={{
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}
	>
		404
	</div>
);

export default NotFound;
