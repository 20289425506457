/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
/* eslint-disable import/no-extraneous-dependencies */
import { EditRounded, SearchRounded } from '@mui/icons-material';
import {
	Chip,
	IconButton,
	InputAdornment,
	MenuItem,
	Stack,
	Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
	// changeCashbackStatus,
	getAllCashback,
} from 'api/cashback';
// import Button from 'components/common/Button';
// import FormLable from 'components/common/FormLable';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { cashbackSlice } from 'redux/cashback/cashbackSlice';
import { RootState } from 'redux/store';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';
import useUrlSearchParams from 'utils/useUrlSearchParams';

const Cashback = () => {
	const { searchParams, setParams } = useUrlSearchParams('filter', 'query');
	const [status, setStatus] = useState(searchParams?.filter ?? 0);
	const [query, setQuery] = useState<string>(searchParams?.query ?? undefined);
	const dispatch = useDispatch();

	const { allCashback, pagination, loading } = useSelector(
		(state: RootState) => state.cashback
	);

	const statusValue = [
		'New',
		'Pending From Brand',
		'Approved From Brand',
		'Rejected From Brand',
		'Pending From Qyubic',
		'Approved From Qyubic',
		'Rejected From Qyubic',
	];

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'User Name',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={`${params.row.userMeta.firstName} ${params.row.userMeta.lastName} `}
				>
					<span className='textWrap'>{`${params.row.userMeta.firstName} ${params.row.userMeta.lastName} `}</span>
				</Tooltip>
			),
		},
		{
			field: 'email',
			headerName: 'User Email',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params?.row?.userMeta.email}>
					<span className='textWrap'>
						{params?.row?.userMeta.email.toLowerCase()}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'mobile',
			headerName: 'User Mobile',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) =>
				`${params.row.userMeta.mobile}`,
		},
		{
			field: 'brand',
			headerName: 'Brand Name',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip
					title={`${
						params.row?.brand?.brandTranslation?.filter(
							(item: any) => item.languageCode === 'en'
						)[0].name
					}`}
				>
					<span className='textWrap'>
						{`${
							params.row?.brand?.brandTranslation?.filter(
								(item: any) => item.languageCode === 'en'
							)[0].name
						}`}
					</span>
				</Tooltip>
			),
		},

		// {
		// 	field: 'country',
		// 	headerName: 'Country',
		// 	width: 200,
		// 	...disablefilter,
		// 	renderCell: (params: GridValueGetterParams) =>
		// 		`${
		// 			params.row.country.translation.filter(
		// 				(item: any) => item.languageCode === 'en'
		// 			)[0].name
		// 		}`,
		// },
		{
			field: 'receipt',
			headerName: 'Receipt',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label='View'
					color='primary'
					onClick={() => {
						window.open(`${params?.row?.media?.path}`, '_blank');
					}}
				/>
			),
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 200,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={`${statusValue[params.row.status - 1]}`}
					color={`${
						params.row.status === 1
							? 'secondary'
							: params.row.status === 2
							? 'warning'
							: params.row.status === 3
							? 'success'
							: params.row.status === 4
							? 'error'
							: params.row.status === 5
							? 'warning'
							: params.row.status === 6
							? 'success'
							: 'error'
					}`}
				/>
			),
		},

		{
			field: 'action',
			headerName: 'Action',
			width: 160,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/cashback-management/edit/${params.row.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
				</Stack>
			),
		},
		{
			field: 'comment',
			headerName: 'Comment',
			width: 150,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.comment}>
					<span
						style={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{params.row.comment || '-'}
					</span>
				</Tooltip>
			),
		},
	];
	const fetchCashback = (q?: string) => {
		dispatch(
			getAllCashback({
				status,
				perPage: pagination?.perPage,
				page: pagination?.currentPage,
				q: q ?? query,
			})
		);
	};

	useEffect(() => {
		fetchCashback();
	}, [status, pagination?.currentPage, pagination?.perPage]);

	const debouncedFn = useCallback(
		debounce(q => {
			fetchCashback(q);
		}, 500),
		[]
	);

	return (
		<TableLayout>
			<Header title='Cashback Management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							placeholder='Search by name, email'
							sx={{ width: '18rem' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								),
							}}
							onChange={(e: any) => {
								const q = e.target.value;
								debouncedFn(q);
								setQuery(q);
								setParams('query', q);
							}}
							value={query}
						/>

						<Input
							size='small'
							fullWidth
							id='status'
							name='status'
							variant='outlined'
							select
							value={status}
							onChange={e => {
								const sendStatus = Number(e?.target?.value);
								setStatus(() => sendStatus);
								setParams('filter', sendStatus);
							}}
						>
							<MenuItem value={0}>All</MenuItem>
							<MenuItem value={1}>New</MenuItem>
							<MenuItem value={2}>Pending From Brand</MenuItem>
							<MenuItem value={3}>Approved From Brand</MenuItem>
							<MenuItem value={4}>Rejected From Brand</MenuItem>
							<MenuItem value={5}>Pending From Qyubic</MenuItem>
							<MenuItem value={6}>Approved From Qyubic</MenuItem>
							<MenuItem value={7}>Rejected From Qyubic</MenuItem>
						</Input>
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allCashback}
				columns={columns}
				rowHeight={100}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				loading={loading}
				total={pagination?.total}
				perPage={pagination?.perPage}
				onPerPage={(value: number) => {
					dispatch(cashbackSlice.actions.setCurrentPage(1));
					dispatch(cashbackSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(cashbackSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>

			{/* <Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				<div className={styles.modalContainer}>
					<div style={{ position: 'absolute', right: 16, top: 16 }}>
						<Button
							style={{
								all: 'unset',
								cursor: 'pointer',
								color: '#084370',
								transform: 'rotate(45deg)',
								fontSize: '2.2rem',
								left: '10%',
							}}
							onClick={handleClose}
						>
							+
						</Button>
					</div>
					<FormLable label='Comment' required={false} />
					<TextField
						sx={{
							marginTop: '1rem',
						}}
						fullWidth
						id='comment'
						name='comment'
						multiline
						rows={2}
						maxRows={4}
						placeholder='Comment'
						onChange={e => setComment(e.target.value)}
						value={comment}
						error={!!error}
					/>
					<span style={{ color: '#df0300', fontSize: '0.75rem' }}>{error}</span>
					<Button
						sx={{
							marginTop: '1rem',
							marginBottom: '1rem',
						}}
						color='primary'
						variant='contained'
						fullWidth
						type='submit'
						onClick={handleReject}
						loading={loading}
					>
						Submit
					</Button>
				</div>
			</Modal> */}
		</TableLayout>
	);
};
export default Cashback;
