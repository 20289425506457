import { createAsyncThunk } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { push } from 'react-router-redux';
import axiosInstance from 'utils/axiosInstance';
import getGenericErrorMessage from 'utils/error';

export const getAllPartners = createAsyncThunk(
	'getAllPartners',
	async ({ page, perPage }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/partners`, {
				params: { page, perPage },
				headers: {
					'x-locale': 'en',
				},
			});
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const AddPartners = createAsyncThunk(
	'AddPartners',
	async (backendObject: any, thunkAPI) => {
		try {
			const response = await axiosInstance.post(`admin/partners`, backendObject);
			if (response.status === 201) {
				toast.success('Partner added successfully');
				thunkAPI.dispatch(push('/affiliate-management'));

				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const UpdatePartner = createAsyncThunk(
	'UpdatePartner',
	async (
		{ backendObject, id }: { backendObject: any; id: string },
		thunkAPI
	) => {
		try {
			const response = await axiosInstance.put(
				`admin/partners/${id}`,
				backendObject
			);
			if (response.status === 200) {
				toast.success('Partners updated successfully');
				thunkAPI.dispatch(push('/affiliate-management'));
				return response.data;
			}
			if (response.status === 422) {
				toast.error('check field');
				return false;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const DeletePartner = createAsyncThunk(
	'DeletePartner',
	async ({ id, sendStatus }: { id: string; sendStatus: number }, thunkAPI) => {
		try {
			const response = await axiosInstance.patch(`admin/partners/${id}`, {
				status: sendStatus,
			});
			if (response.status === 204) {
				toast.success(
					sendStatus
						? 'Partner status changed to Active'
						: 'Partner status changed to Inactive'
				);

				const { partner }: any = thunkAPI.getState();
				const page = partner.pagination.currentPage;
				thunkAPI.dispatch(getAllPartners({ page, isRefresh: true }));
				return response.data;
			}
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
		return null;
	}
);

export const getPartnerById = createAsyncThunk(
	'getPartnerById',
	async (id: string, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/partners/${id}`);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);

export const searchPartners = createAsyncThunk(
	'searchPartners',
	async ({ q }: any, thunkAPI) => {
		try {
			const response = await axiosInstance.get(`admin/partners?q=${q}`);
			return response.data;
		} catch (e: any) {
			toast.error(getGenericErrorMessage(e));
			return thunkAPI.rejectWithValue(e?.response?.status);
		}
	}
);
