import Axios from 'axios';

import { getFromLocal } from './localStorage';

// const user = getFromLocal('user') ? getFromLocal('user').token : '';

const axiosInstance = Axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
	config => {
		const request = config;
		request.headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${
				getFromLocal('user') ? getFromLocal('user').token : ''
			}`,
		};
		return request;
	},
	err => Promise.reject(err)
);

export default axiosInstance;
