/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import 'antd/dist/antd.css';

import {
	Box,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	OutlinedInput,
	Select,
} from '@mui/material';
import Container from '@mui/material/Container';
import { addCategory, editCategory, getCategoryById } from 'api/categories';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Loader from 'components/common/Loader';
import TableLayout from 'components/common/TableLayout';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearData, emptySingleCategory } from 'redux/category/categorySice';
import { RootState } from 'redux/store';
import UploadImage from 'utils/UploadImage';

// import { allValues, isEmpty, usePrompt } from 'utils/propts';
import FormLable from '../../../components/common/FormLable';
import validationSchema from './validation';

const WithMaterialUI = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const singleCategory = useSelector(
		(state: RootState) => state.category.singleCategory
	);

	const loadingBtn = useSelector(
		(state: RootState) => state.category.loadingBtn
	);

	const [imageEn, setImageEn] = useState<string>(
		'' || singleCategory?.translation?.en[0]?.imageSlug?.path
	);
	const [imageAra, setImageAra] = useState<string>(
		'' || singleCategory?.translation?.ara[0]?.imageSlug?.path
	);
	const [seoImageEn, setSeoImageEn] = useState<string>(
		'' ||
			singleCategory?.seo?.filter((item: any) => item.languageCode === 'en')[0]
				?.imageSlug?.path
	);
	const [seoImageAra, setSeoImageAra] = useState<string>(
		'' ||
			singleCategory?.seo?.filter((item: any) => item.languageCode === 'ara')[0]
				?.imageSlug?.path
	);

	const [logo, setLogo] = useState<string>('' || singleCategory?.iconSlug?.path);

	// image upload progress

	const handleImageEn = (data: string) => {
		setImageEn(data);
	};
	const handleImageAra = (data: string) => {
		setImageAra(data);
	};

	const handleSeoImageEn = (data: string) => {
		setSeoImageEn(data);
	};
	const handleSeoImageAra = (data: string) => {
		setSeoImageAra(data);
	};

	const handleSlug = (data: string) => {
		setLogo(data);
	};

	const formik = useFormik({
		initialValues: {
			iconSlug: singleCategory?.iconSlug?.slug || '',
			status: singleCategory ? (singleCategory?.status === 1 ? 1 : 0) : 0,
			name: singleCategory?.translation?.en[0]?.name
				? singleCategory?.translation?.en[0]?.name
				: '',
			name_ara: singleCategory?.translation?.ara[0]?.name
				? singleCategory?.translation?.ara[0]?.name
				: '',
			imageSlug: singleCategory?.translation?.en[0]?.imageSlug?.slug || '',
			imageSlug_ara: singleCategory?.translation?.ara[0]?.imageSlug.slug || '',
			imageAlt: singleCategory?.translation?.en[0]?.imageAlt || '',
			imageAltAra: singleCategory?.translation?.ara[0]?.imageAlt || '',
			imageTitle: singleCategory?.translation?.en[0]?.imageTitle || '',
			imageTitleAra: singleCategory?.translation?.ara[0]?.imageTitle || '',
			seoTitle: singleCategory
				? singleCategory?.seo?.filter((item: any) => item.languageCode === 'en')[0]
						?.title
				: '',
			// seoKeywords: singleCategory
			//   ? singleCategory?.seo?.filter(
			//       (item: any) => item.languageCode === 'en',
			//     )[0]?.keywords
			//   : '',
			seoMetas: singleCategory
				? singleCategory?.seo?.filter((item: any) => item.languageCode === 'en')[0]
						?.description
				: '',
			seoTitleAra: singleCategory
				? singleCategory?.seo?.filter((item: any) => item.languageCode === 'ara')[0]
						?.title
				: '',
			// seoKeywordsAra: singleCategory
			//   ? singleCategory?.seo?.filter(
			//       (item: any) => item.languageCode === 'ara',
			//     )[0]?.keywords
			//   : '',
			seoMetasAra: singleCategory
				? singleCategory?.seo?.filter((item: any) => item.languageCode === 'ara')[0]
						?.description
				: '',
			seoImage:
				singleCategory?.seo?.filter((item: any) => item.languageCode === 'en')[0]
					?.imageSlug?.slug || '',

			seoImageAra:
				singleCategory?.seo?.filter((item: any) => item.languageCode === 'ara')[0]
					?.imageSlug?.slug || '',
			seoImageAlt: singleCategory?.seo?.filter(
				(item: any) => item.languageCode === 'en'
			)[0]?.imageAlt,

			seoImageAltAra: singleCategory?.seo?.filter(
				(item: any) => item.languageCode === 'ara'
			)[0]?.imageAlt,
			description: singleCategory?.translation?.en[0].description || '',
			descriptionAra: singleCategory?.translation?.ara[0].description || '',
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: values => {
			const backendObject: any = {
				iconSlug: values.iconSlug,
				status: values.status,
				seoTitle: values?.seoTitle,
				// seoKeywords: values?.seoKeywords,
				seoMetas: values?.seoMetas,
				translation: [
					{
						languageCode: 'en',
						name: values.name,
						imageSlug: values.imageSlug,
						description: values.description,
						imageAlt: values.imageAlt,
						imageTitle: values.imageTitle,
					},
					{
						languageCode: 'ara',
						name: values.name_ara,
						imageSlug: values.imageSlug_ara ? values.imageSlug_ara : values.imageSlug,
						description: values.descriptionAra,
						imageAlt: values.imageAltAra,
						imageTitle: values.imageTitleAra,
					},
				],
				seo: [
					{
						seoTitle: values.seoTitle,
						// seoKeywords: values.seoKeywords,
						seoMetas: values.seoMetas,
						imageSlug: values.seoImage || values.imageSlug,
						imageAlt: values.seoImageAlt,
						languageCode: 'en',
					},
					{
						seoTitle: values.seoTitleAra,
						// seoKeywords: values.seoKeywordsAra,
						seoMetas: values.seoMetasAra,
						imageAlt: values.seoImageAltAra,
						imageSlug: values.seoImageAra ? values.seoImageAra : values.imageSlug_ara,
						languageCode: 'ara',
					},
				],
			};
			if (id) dispatch(editCategory({ backendObject, id }));
			else dispatch(addCategory(backendObject));
		},
	});

	// const [leavePage, setLeavePage] = useState(false);

	// useEffect(() => {
	// 	allValues(formik.values)
	// 		? setLeavePage(false)
	// 		: setLeavePage(!isEmpty(formik.values));
	// }, [formik.values]);

	// usePrompt(leavePage);

	const setEnImg = (slug: string) => {
		formik.setFieldValue('imageSlug', slug);
	};
	const setAraImg = (slug: string) => {
		formik.setFieldValue('imageSlug_ara', slug);
	};
	const setSeoEnImg = (slug: string) => {
		formik.setFieldValue('seoImage', slug);
	};
	const setSeoAraImg = (slug: string) => {
		formik.setFieldValue('seoImageAra', slug);
	};
	const setFormLogo = (slug: string) => {
		formik.setFieldValue('iconSlug', slug);
	};

	return (
		<form onSubmit={formik.handleSubmit}>
			<Box>
				{/* category Name */}
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='English' required={false} />
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Arabic' required={false} textAlign='right' />
					</Grid>
				</Grid>

				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='Category Name' />
						{/* English version field */}
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='name'
							name='name'
							placeholder='Name'
							value={formik.values.name}
							onChange={formik.handleChange}
							error={formik.touched.name && Boolean(formik.errors.name)}
							helperText={formik.touched.name && formik.errors.name}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Category Name' textAlign='right' />
						{/* Arabic version field */}

						<Input
							fullWidth
							id='name_ara'
							name='name_ara'
							type='text'
							dir='rtl'
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							value={formik.values.name_ara}
							onChange={formik.handleChange}
							placeholder='Name'
							error={formik.touched.name_ara && Boolean(formik.errors.name_ara)}
							helperText={formik.touched.name_ara && formik.errors.name_ara}
							className='arabic-error'
						/>
					</Grid>
				</Grid>

				{/* category Logo */}

				<FormLable label='Category Image' />
				{/* <small style={{ color: 'GrayText' }}>You can only upload 1:1 image</small> */}
				<Grid container spacing={1}>
					<Grid item xs={2}>
						<UploadImage
							logo={imageEn}
							handleSlug={handleImageEn}
							entity='categories'
							formikImg={(s: string) => setEnImg(s)}
						/>
						<span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.imageSlug &&
								formik.errors.imageSlug &&
								formik.errors.imageSlug}
						</span>
					</Grid>
					<Grid item xs={3}>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='imageAlt'
							name='imageAlt'
							placeholder='Image alt text'
							value={formik.values.imageAlt}
							onChange={formik.handleChange}
						/>
					</Grid>
					<Grid item xs={2} />
					<Grid item xs={2}>
						<UploadImage
							logo={imageAra}
							handleSlug={handleImageAra}
							entity='categories'
							formikImg={(s: string) => setAraImg(s)}
						/>
					</Grid>
					<Grid item xs={3}>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='imageAltAra'
							name='imageAltAra'
							placeholder='Image Alt Arabic'
							value={formik.values.imageAltAra}
							onChange={formik.handleChange}
						/>
					</Grid>
				</Grid>

				{/* category Image */}
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='Image Title' required={false} />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='imageTitle'
							multiline
							maxRows={4}
							name='imageTitle'
							placeholder='Image Title'
							value={formik.values.imageTitle}
							onChange={formik.handleChange}
							error={formik.touched.imageTitle && Boolean(formik.errors.imageTitle)}
							helperText={formik.touched.imageTitle && formik.errors.imageTitle}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='Image Title Ara' required={false} textAlign='right' />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='imageTitleAra'
							multiline
							maxRows={4}
							name='imageTitleAra'
							type='text'
							dir='rtl'
							placeholder='Image Title'
							value={formik.values.imageTitleAra}
							onChange={formik.handleChange}
							error={
								formik.touched.imageTitleAra && Boolean(formik.errors.imageTitleAra)
							}
							helperText={formik.touched.imageTitleAra && formik.errors.imageTitleAra}
						/>
					</Grid>
				</Grid>
				<FormLable label='Category Icon' />

				<Grid container spacing={1}>
					<Grid item xs={12}>
						<UploadImage
							logo={logo}
							handleSlug={handleSlug}
							entity='categories'
							formikImg={(s: string) => setFormLogo(s)}
						/>
						<span style={{ fontSize: '12px', color: '#D32F2F' }}>
							{formik.touched.iconSlug &&
								formik.errors.iconSlug &&
								formik.errors.iconSlug}
						</span>
					</Grid>
				</Grid>

				<Grid container rowSpacing={3} columnSpacing={1}>
					<Grid item xs={6}>
						<FormLable label='Description' />

						<ReactQuill
							theme='snow'
							value={formik.values.description}
							onChange={v => formik.setFieldValue('description', v)}
						/>
					</Grid>

					<Grid item xs={6}>
						<FormLable label='Description' />

						<ReactQuill
							theme='snow'
							value={formik.values.descriptionAra}
							onChange={v => formik.setFieldValue('descriptionAra', v)}
						/>
					</Grid>
				</Grid>

				<FormLable label='Status' />
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<FormControl
							sx={{ width: '100%' }}
							error={formik.touched.status && Boolean(formik.errors.status)}
						>
							<Select
								fullWidth
								size='small'
								id='status'
								name='status'
								displayEmpty
								value={formik.values.status}
								input={<OutlinedInput />}
								onChange={formik.handleChange}
								error={formik.touched.status && Boolean(formik.errors.status)}
							>
								<MenuItem selected={formik.values.status === 1} value={1}>
									Active
								</MenuItem>
								<MenuItem selected={formik.values.status === 0} value={0}>
									Inactive
								</MenuItem>
							</Select>
							<FormHelperText>
								{formik.touched.status && formik.errors.status}
							</FormHelperText>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='SEO Title' required={false} />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='seoTitle'
							name='seoTitle'
							multiline
							maxRows={4}
							placeholder='SEO Title'
							value={formik.values.seoTitle}
							onChange={formik.handleChange}
							error={formik.touched.seoTitle && Boolean(formik.errors.seoTitle)}
							helperText={formik.touched.seoTitle && formik.errors.seoTitle}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='SEO Title' required={false} textAlign='right' />
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							id='seoTitleAra'
							name='seoTitleAra'
							multiline
							maxRows={4}
							type='text'
							dir='rtl'
							placeholder='SEO Title'
							value={formik.values.seoTitleAra}
							onChange={formik.handleChange}
							error={formik.touched.seoTitleAra && Boolean(formik.errors.seoTitleAra)}
							helperText={formik.touched.seoTitleAra && formik.errors.seoTitleAra}
						/>
					</Grid>
				</Grid>
				{/* <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormLable
              label="SEO Keywords (comma separated)"
              required={false}
            />

            <Input
              sx={{
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              fullWidth
              id="seoKeywords"
              name="seoKeywords"
              multiline
              maxRows={4}
              placeholder="SEO Keywords"
              value={formik.values.seoKeywords?.trim()}
              onChange={formik.handleChange}
              error={
                formik.touched.seoKeywords && Boolean(formik.errors.seoKeywords)
              }
              helperText={
                formik.touched.seoKeywords && formik.errors.seoKeywords
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormLable
              label="SEO Keywords (comma separated)"
              required={false}
              textAlign="right"
            />
            <Input
              sx={{
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
              fullWidth
              id="seoKeywordsAra"
              name="seoKeywordsAra"
              multiline
              maxRows={4}
              type="text"
              dir="rtl"
              placeholder="SEO Keywords"
              value={formik.values.seoKeywordsAra}
              onChange={formik.handleChange}
              error={
                formik.touched.seoKeywords && Boolean(formik.errors.seoKeywords)
              }
              helperText={
                formik.touched.seoKeywords && formik.errors.seoKeywords
              }
            />
          </Grid>
        </Grid> */}

				<Grid container spacing={1}>
					<Grid item xs={6}>
						<FormLable label='SEO Description' required={false} />

						{/* Arabic version field */}
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='seoMetas'
							name='seoMetas'
							maxRows={4}
							placeholder='SEO Description'
							value={formik.values.seoMetas}
							onChange={formik.handleChange}
							error={formik.touched.seoMetas && Boolean(formik.errors.seoMetas)}
							helperText={formik.touched.seoMetas && formik.errors.seoMetas}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormLable label='SEO Description' required={false} textAlign='right' />

						{/* Arabic version field */}
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='seoMetasAra'
							name='seoMetasAra'
							maxRows={4}
							dir='rtl'
							placeholder='SEO Description'
							value={formik.values.seoMetasAra}
							onChange={formik.handleChange}
							error={formik.touched.seoMetasAra && Boolean(formik.errors.seoMetasAra)}
							helperText={formik.touched.seoMetasAra && formik.errors.seoMetasAra}
						/>
					</Grid>
				</Grid>
				<FormLable label='Seo Image' />
				{/* <small style={{ color: 'GrayText' }}>You can only upload 1:1 image</small> */}
				<Grid container spacing={1}>
					<Grid item xs={2}>
						<UploadImage
							logo={seoImageEn}
							handleSlug={handleSeoImageEn}
							entity='category-seo'
							formikImg={(s: string) => setSeoEnImg(s)}
						/>
					</Grid>
					<Grid item xs={3}>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='seoImageAlt'
							name='seoImageAlt'
							placeholder='Seo Image Alt'
							value={formik.values.seoImageAlt}
							onChange={formik.handleChange}
						/>
					</Grid>
					<Grid item xs={2} />
					<Grid item xs={2}>
						<UploadImage
							aspect={1 / 1}
							logo={seoImageAra}
							handleSlug={handleSeoImageAra}
							entity='category-seo'
							formikImg={(s: string) => setSeoAraImg(s)}
						/>
					</Grid>
					<Grid item xs={3}>
						<Input
							sx={{
								marginTop: '1rem',
								marginBottom: '1rem',
							}}
							fullWidth
							multiline
							id='seoImageAltAra'
							name='seoImageAltAra'
							placeholder='Seo Image Alt'
							value={formik.values.seoImageAltAra}
							onChange={formik.handleChange}
						/>
					</Grid>
				</Grid>

				{/* Status */}
			</Box>

			<Button
				sx={{
					marginTop: '1rem',
					marginBottom: '1rem',
				}}
				color='primary'
				variant='contained'
				fullWidth
				loading={loadingBtn}
				type='submit'
			>
				Submit
			</Button>
		</form>
	);
};

const CategoryAdd = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();

	const loading = useSelector((state: RootState) => state.category.loading);
	const isAdded = useSelector((state: RootState) => state.category.isAdded);
	const isUpdated = useSelector((state: RootState) => state.category.isUpdated);

	useEffect(() => {
		if (isAdded || isUpdated) {
			navigate('/category-management');
		}
		return () => {
			dispatch(clearData());
		};
	}, [isAdded, isUpdated]);

	useEffect(() => {
		if (id) {
			dispatch(getCategoryById(id));
		}
		return () => {
			dispatch({ type: emptySingleCategory });
		};
	}, [id]);
	return (
		<TableLayout>
			<Container maxWidth='lg' className='addScroll'>
				<Header title={id ? 'Edit Category' : 'Add Category'} />
				<Box>
					<Box>
						{loading ? (
							<Box
								sx={{
									width: '100%',
									height: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'absolute',
									backgroundColor: 'white',
									opacity: '0.7',
									zIndex: '999',
									minHeight: 'calc(100vh - 80px)',
								}}
							>
								<Loader />
							</Box>
						) : (
							<WithMaterialUI />
						)}
					</Box>
				</Box>
			</Container>
		</TableLayout>
	);
};
export default CategoryAdd;
