import * as yup from 'yup';

const validationSchema = yup.object({
	name: yup
		.string()
		.min(2, 'Enter a valid Link Name')
		.required('Link Name is required'),
	status: yup.number().required('Status is required.'),
	nameAra: yup
		.string()
		.min(2, 'Minimum 2 characters required')
		.required('Arabic Link Name is required'),
	link: yup.string().required('Link is required'),
});

export default validationSchema;
