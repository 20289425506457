import { createSlice } from '@reduxjs/toolkit';

import {
	changeCashbackStatus,
	editCashback,
	getAllCashback,
	getCashbackById,
	getConvertedAmount,
	searchCashback,
} from '../../api/cashback';

const initialState: any = {
	allCashback: [],
	error: '',
	filterByStatus: -1,
	singleCashback: {},
	isUpdated: false,
	loading: false,
	isRejected: false,
	loadingBtn: false,
	currencyLoading: false,

	convertedAmount: {},
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const cashbackSlice = createSlice({
	name: 'cashback',
	initialState,
	reducers: {
		setFilterByStatus: (state, action) => {
			state.filterByStatus = action.payload.status;
		},
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.singleCashback = initialState.singleCashback;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getAllCashback.pending, state => {
				state.loading = true;
			})
			.addCase(getAllCashback.fulfilled, (state, action) => {
				state.loading = false;
				state.allCashback = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllCashback.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search user
			.addCase(searchCashback.pending, state => {
				state.loading = true;
			})
			.addCase(searchCashback.fulfilled, (state, action) => {
				state.loading = false;
				state.allCashback = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchCashback.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// singleCashback

			.addCase(getCashbackById.pending, state => {
				state.loading = true;
			})
			.addCase(getCashbackById.fulfilled, (state, action) => {
				state.loading = false;
				state.singleCashback = action.payload.data;
			})
			.addCase(getCashbackById.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// change status
			.addCase(changeCashbackStatus.pending, state => {
				state.loading = true;
			})
			.addCase(changeCashbackStatus.fulfilled, state => {
				state.loading = false;
			})
			.addCase(changeCashbackStatus.rejected, (state, action) => {
				state.loading = false;

				state.error = action.error.message;
			})

			.addCase(editCashback.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(editCashback.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(editCashback.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;
				state.error = action.error.message;
			})
			.addCase(getConvertedAmount.pending, state => {
				state.currencyLoading = true;
			})
			.addCase(getConvertedAmount.fulfilled, (state, action) => {
				state.currencyLoading = false;
				state.convertedAmount = action.payload;
			})
			.addCase(getConvertedAmount.rejected, (state, action) => {
				state.currencyLoading = false;
				state.error = action.error.message;
			});
	},
});

export const { setFilterByStatus, clearData } = cashbackSlice.actions;

export default cashbackSlice.reducer;
