import { createSlice } from '@reduxjs/toolkit';
import { IBanners } from 'Interfaces/Banners';
import { groupBy } from 'lodash';

import {
	AddBanner,
	DeleteBanner,
	getAllbanners,
	getBannerById,
	searchBanner,
	UpdateBanner,
} from '../../api/banners';

const initialState: IBanners = {
	allBanner: [],
	error: '',
	isAdded: false,
	isUpdated: false,
	singleBanner: {},
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const bannerSlice = createSlice({
	name: 'banner',
	initialState,
	reducers: {
		emptySingleBanner: state => {
			state.singleBanner = initialState.singleBanner;
		},
		toggleLoading: state => {
			state.loading = !state.loading;
		},
		toggleStatus: (state, action) => {
			state.allBanner = state.allBanner.map((d: any) =>
				d.id === action.payload ? { ...d, status: !d.status } : { ...d }
			);
		},
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			//  get all Banner
			.addCase(getAllbanners.pending, (state, action) => {
				state.loading = !action.meta.arg.isRefresh;
			})
			.addCase(getAllbanners.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						brandMeta: groupBy(each?.brandMeta[0]?.brandTranslation, 'languageCode'),
						// categoryMeta: groupBy(each?.categoryMeta[0]?.translation, 'languageCode'),
						translation: groupBy(each?.translation, 'languageCode'),
					})
				);
				state.allBanner = array;
				state.pagination = action?.payload?.meta?.pagination;
			})
			.addCase(getAllbanners.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			.addCase(searchBanner.pending, state => {
				state.loading = true;
			})
			.addCase(searchBanner.fulfilled, (state, action) => {
				state.loading = false;
				const array: any = [];
				action.payload.data.forEach((each: any) =>
					array.push({
						...each,
						brandMeta: groupBy(each?.brandMeta[0]?.brandTranslation, 'languageCode'),
						// categoryMeta: groupBy(each?.categoryMeta[0]?.translation, 'languageCode'),
						translation: groupBy(each?.translation, 'languageCode'),
					})
				);
				state.allBanner = array;
				state.pagination = action?.payload?.meta?.pagination;
			})
			.addCase(searchBanner.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search banner

			// add banner

			.addCase(AddBanner.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(AddBanner.fulfilled, state => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(AddBanner.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = false;
				state.error = action.error.message;
			})

			//  update banner

			.addCase(UpdateBanner.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdateBanner.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdateBanner.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;
				state.error = action.error.message;
			})

			// get banner
			.addCase(getBannerById.pending, state => {
				state.loading = true;
			})
			.addCase(getBannerById.fulfilled, (state, action) => {
				state.loading = false;

				const array = {
					...action?.payload?.data,
					brandMeta: {
						...action?.payload?.data?.brandMeta[0],
						brandTranslation: groupBy(
							action?.payload?.data?.brandMeta[0]?.brandTranslation,
							'languageCode'
						),
					},
					// categoryMeta: {
					// 	...action?.payload?.data?.categoryMeta[0],
					// 	translation: groupBy(
					// 		action?.payload?.data?.categoryMeta[0]?.translation,
					// 		'languageCode'
					// 	),
					// },
					translation: groupBy(action?.payload?.data?.translation, 'languageCode'),
				};

				state.singleBanner = array;
			})
			.addCase(getBannerById.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// change status

			.addCase(DeleteBanner.pending, state => {
				state.loading = false;
			})
			.addCase(DeleteBanner.fulfilled, state => {
				state.loading = false;
			})
			.addCase(DeleteBanner.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { emptySingleBanner, toggleLoading, toggleStatus, clearData } =
	bannerSlice.actions;

export default bannerSlice.reducer;
