import * as yup from 'yup';

// const FILE_SIZE = 5000000;
// const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const validationSchema = yup.object({
	title: yup.string().required('Title is required.'),
	titleAra: yup.string().required('العنوان مطلوب.'),
	description: yup.string().required('Description is required'),
	descriptionAra: yup.string().required('الوصف مطلوب'),
	keywords: yup.string().required('Keywords required.'),
	keywordsAra: yup.string().required('الكلمات الرئيسية المطلوبة.'),
	pageName: yup.string().required('Page name is required.'),
	imageSlug: yup.string().required('Image is required'),
});

export default validationSchema;
