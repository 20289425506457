import { SearchRounded } from '@mui/icons-material';
import {
	Box,
	debounce,
	FormLabel,
	Grid,
	InputAdornment,
	Stack,
	Tab,
	Tabs,
	Tooltip,
	Typography
} from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { getAllBrand, searchBrand } from 'api/brand';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { brandSlice } from 'redux/brand/brandSlice';
import { RootState } from 'redux/store';

import styles from './dashboard.module.scss';

interface TabPanelProps {
	children: any;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ paddingTop: '30px' }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

const Dashboard = () => {
	const dispatch = useDispatch();
	const [query, setQuery] = useState('');

	const { allbrand, pagination, loading } = useSelector(
		(state: RootState) => state.brand
	);
	const [values, setValue] = useState(0);

	const { dashboardData } = useSelector((state: RootState) => state.dashboard);

	const handleChange = (event: any, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		dispatch(
			getAllBrand({ page: pagination?.currentPage, perPage: pagination?.perPage })
		);
	}, [pagination?.currentPage, pagination?.perPage]);

	const debouncedFn = useCallback(
		debounce(q => {
			dispatch(searchBrand({ q }));
		}, 500),
		[]
	);

	useEffect(() => {
		debouncedFn(query);
	}, [query]);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Brand Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.brandTranslation?.en[0].name}>
					<span className='textWrap'>{params.row.brandTranslation?.en[0].name}</span>
				</Tooltip>
			)
		},
		{
			field: 'imageSlug',
			headerName: 'Brand Image',
			width: 300,
			renderCell: (params: GridValueGetterParams) => (
				<Box width='200px' height='100px'>
					<img
						src={
							params.row.brandTranslation
								? `${params?.row?.brandTranslation?.en[0]?.imageSlug?.path}`
								: 'logo.png'
						}
						alt=''
						width='100%'
						height='100%'
						style={{
							backgroundPosition: 'center center',
							objectFit: 'cover',
							padding: '0.5rem'
						}}
					/>
				</Box>
			)
		},
		{
			field: 'country',
			headerName: 'Country',
			width: 200,
			renderCell: (params: GridValueGetterParams) => {
				const countryArray = params?.row?.country;
				return (
					<Tooltip
						title={countryArray
							?.map(
								(e: any) =>
									e?.translation?.filter((i: any) => i.languageCode === 'en')[0]?.name
							)
							.join(', ')}
					>
						<div style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
							{countryArray
								?.map(
									(e: any) =>
										e?.translation?.filter((i: any) => i.languageCode === 'en')[0]?.name
								)
								.join(', ') || '-'}
						</div>
					</Tooltip>
				);
			}
		},
		{
			field: 'totalBanners',
			headerName: 'Total Active Banners',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.bannerCounts}>
					<span className='textWrap'>{params.row.bannerCounts}</span>
				</Tooltip>
			)
		},
		{
			field: 'homePageBanners',
			headerName: 'Active Home Page Banners',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.homePageBanners}>
					<span className='textWrap'>{params.row.homePageBanners}</span>
				</Tooltip>
			)
		},
		{
			field: 'categoryPageBanners',
			headerName: 'Active Category Page Banners',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.categoryPageBanners}>
					<span className='textWrap'>{params.row.categoryPageBanners}</span>
				</Tooltip>
			)
		},
		{
			field: 'brandPageBanners',
			headerName: 'Active Brand Page Banners',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.brandPageBanners}>
					<span className='textWrap'>{params.row.brandPageBanners}</span>
				</Tooltip>
			)
		},
		{
			field: 'totalCoupons',
			headerName: 'Total Active Coupons',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.totalBrandCoupons}>
					<span className='textWrap'>{params.row.totalBrandCoupons}</span>
				</Tooltip>
			)
		},
		{
			field: 'totalCashback',
			headerName: 'Total Active Cashback',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.totalCashback}>
					<span className='textWrap'>{params.row.totalCashback}</span>
				</Tooltip>
			)
		},
		{
			field: 'totalPromotion',
			headerName: 'Total Active Offer',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.totalPromotion}>
					<span className='textWrap'>{params.row.totalPromotion}</span>
				</Tooltip>
			)
		}
	];

	return (
		<div className={styles.container}>
			<TableLayout>
				<Header title='Dashboard'>
					{values === 1 && (
						<Box>
							<Stack direction='row' spacing={2} alignItems='center'>
								<Input
									onChange={(e: any) => setQuery(e.target.value)}
									sx={{ width: '18rem' }}
									placeholder='Search by brand name'
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<SearchRounded />
											</InputAdornment>
										)
									}}
								/>
							</Stack>
						</Box>
					)}
				</Header>
				<Tabs
					value={values}
					onChange={handleChange}
					aria-label='basic tabs example'
				>
					<Tab label='Dashboard' style={{ padding: '0px' }} />
					<Tab label='Analytics' style={{ marginLeft: '5px' }} />
				</Tabs>
				<TabPanel index={1} value={values}>
					<Table
						rows={allbrand}
						columns={columns}
						count={pagination?.totalPages}
						page={pagination?.currentPage}
						rowHeight={85}
						rowCount={pagination?.perPage}
						perPage={pagination?.perPage}
						total={pagination?.total}
						onPerPage={(value: number) => {
							dispatch(brandSlice.actions.setCurrentPage(1));
							dispatch(brandSlice.actions.setPerPage(value));
						}}
						onChange={(_e: ChangeEvent, value: number) =>
							dispatch(brandSlice.actions.setCurrentPage(value))
						}
						sx={{ height: '70vh' }}
						loading={loading}
					/>
				</TabPanel>
				{values === 0 && (
					<Grid container spacing={2}>
						<Grid item xs={10} md={4}>
							<div className={styles.userContainer}>
								<FormLabel className={styles.label}>Users</FormLabel>
								<span>Total Users : {dashboardData?.users?.totalUsers}</span>
								<span style={{ margin: '5px 0' }}>
									Active Users : {dashboardData?.users?.activeUsers}
								</span>
								<span>Inactive Users : {dashboardData?.users?.inActiveUsers}</span>
							</div>
						</Grid>
						<Grid item xs={10} md={4}>
							<div className={styles.couponContainer}>
								<FormLabel className={styles.label}>Coupons</FormLabel>
								<span>Total Coupons : {dashboardData?.coupons?.totalCoupons}</span>
								<span style={{ margin: '5px 0' }}>
									Active Coupons : {dashboardData?.coupons?.activeCoupons}
								</span>
								<span>
									Inactive Coupons : {dashboardData?.coupons?.inActiveCoupons}
								</span>
							</div>
						</Grid>
						<Grid item xs={10} md={4}>
							<div className={styles.cashbackContainer}>
								<FormLabel className={styles.label}>Cashback</FormLabel>
								<span>
									Total Cashback : {dashboardData?.coupons?.totalCashbackCoupons}
								</span>
								<span style={{ margin: '5px 0' }}>
									Active Cashback : {dashboardData?.coupons?.activeCashbackCoupons}
								</span>
								<span>
									Inactive Cashback : {dashboardData?.coupons?.inActiveCashbackCoupons}
								</span>
							</div>
						</Grid>
						<Grid item xs={10} md={4}>
							<div className={styles.promotionContainer}>
								<FormLabel className={styles.label}>Offer</FormLabel>
								<span>
									Total Offer : {dashboardData?.coupons?.totalPromotionCoupons}
								</span>
								<span style={{ margin: '5px 0' }}>
									Active Offer : {dashboardData?.coupons?.activePromotionCoupons}
								</span>
								<span>
									Inactive Offer : {dashboardData?.coupons?.inActivePromotionCoupons}
								</span>
							</div>
						</Grid>
						<Grid item xs={10} md={4}>
							<div className={styles.categoryContainer}>
								<FormLabel className={styles.label}>Categories</FormLabel>
								<span>
									Total Categories : {dashboardData?.categories?.totalCategories}
								</span>
								<span style={{ margin: '5px 0' }}>
									Active Categories : {dashboardData?.categories?.activeCategories}
								</span>
								<span>
									Inactive Categories : {dashboardData?.categories?.inActiveCategories}
								</span>
							</div>
						</Grid>
						<Grid item xs={10} md={4}>
							<div className={styles.brandContainer}>
								<FormLabel className={styles.label}>Brands</FormLabel>
								<span>Total Brands : {dashboardData?.brands?.totalBrands}</span>
								<span style={{ margin: '5px 0' }}>
									Active Brands : {dashboardData?.brands?.activeBrands}
								</span>
								<span>Inactive Brands : {dashboardData?.brands?.inActiveBrands}</span>
							</div>
						</Grid>
						<Grid item xs={10} md={4}>
							<div className={styles.pageBannerContainer}>
								<FormLabel className={styles.label}>Page Wise Banners</FormLabel>
								<span>
									Home Page Banners : {dashboardData?.banners?.homePageBanners}
								</span>
								<span style={{ margin: '5px 0' }}>
									Category Page Banners : {dashboardData?.banners?.categoryPageBanners}
								</span>
								<span>
									Brand Page Banners : {dashboardData?.banners?.brandPageBanners}
								</span>
							</div>
						</Grid>
						<Grid item xs={10} md={4}>
							<div className={styles.bannerContainer}>
								<FormLabel className={styles.label}>Banners</FormLabel>
								<span>Total Banners : {dashboardData?.banners?.totalBanners}</span>
								<span style={{ margin: '5px 0' }}>
									Active Banners : {dashboardData?.banners?.activeBanners}
								</span>
								<span>
									Inactive Banners : {dashboardData?.banners?.inActiveBanners}
								</span>
							</div>
						</Grid>
					</Grid>
				)}
			</TableLayout>
		</div>
	);
};
export default Dashboard;
