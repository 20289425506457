/* eslint-disable no-nested-ternary */
import { Grid, MenuItem, OutlinedInput, Select } from '@mui/material';
import { getAllBrandsWithoutPage } from 'api/brand';
import { getallCategorieswithoutPage } from 'api/categories';
import FormLable from 'components/common/FormLable';
import Input from 'components/common/Input';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { webpageRoutes } from 'utils/constant';

type Props = {
	selectedType: 1 | 2;
	link: string;
	onChange: (value: string) => void;
	formikInstance: any;
	selectedCountry: string[];
};

const NotificationLinkInput = ({
	selectedType,
	link,
	onChange,
	formikInstance,
	selectedCountry,
}: Props) => {
	const dispatch = useDispatch();
	const [page, setPage] = useState<string>('');
	const [slug, setSlug] = useState<string>('');
	useEffect(() => {
		if (link.includes('/discount-codes/')) {
			setPage('/discount-codes/');
			setSlug(link.split('/')[2]);
		} else if (
			link.includes('-discount-codes') ||
			link.includes('/all-category/')
		) {
			setSlug(link.split('/')[2]);
			setPage('/all-category/');
		} else {
			setPage(link);
		}
	}, [link]);

	const handleRootPageChange = (value: string) => {
		setPage(value);
		setSlug('');
		onChange(value);
	};
	const handleSlugChange = (slugPath: string) => {
		setSlug(slugPath);
		if (page === '/discount-codes/') {
			onChange(`${page}${slugPath}/`);
		} else if (page === '/all-category/') {
			onChange(`/all-category/${slugPath}/`);
		} else onChange(`${slugPath}/`);
	};
	useEffect(() => {
		if (page === '/discount-codes/') {
			dispatch(
				getAllBrandsWithoutPage({
					page: 1,
					addCoupon: selectedCountry.length,
					countries: selectedCountry ?? [],
				})
			);
		}
		if (page === '/all-category/') {
			dispatch(
				getallCategorieswithoutPage({
					page: 1,
				})
			);
		}
	}, [selectedCountry, page]);

	const allBrands = useSelector(
		(state: RootState) => state.brand.allbrandWithoutPage
	);
	const allCategories = useSelector(
		(state: RootState) => state.category.allCategorieswithoutPage
	);

	return (
		<>
			<FormLable
				label={selectedType === 1 ? 'External Link' : 'Internal Link (Page)'}
			/>
			<Grid container rowSpacing={3} columnSpacing={1}>
				{selectedType === 1 ? (
					// external link
					<Grid item xs={12}>
						<Input
							sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
							fullWidth
							id='link'
							name='link'
							placeholder='Enter link'
							value={link}
							onChange={e => onChange(e.target.value)}
							error={
								formikInstance.touched.link && Boolean(formikInstance.errors.link)
							}
							helperText={formikInstance.touched.link && formikInstance.errors.link}
						/>
					</Grid>
				) : (
					<>
						<Grid item xs={6}>
							<Select
								sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
								size='small'
								fullWidth
								displayEmpty
								value={page}
								input={<OutlinedInput />}
								onChange={e => handleRootPageChange(e.target.value)}
								error={
									formikInstance.touched.link && Boolean(formikInstance.errors.link)
								}
							>
								{webpageRoutes.map(each => (
									<MenuItem key={each.value} value={each.value}>
										{each.label}
									</MenuItem>
								))}
							</Select>
						</Grid>
						{(page === '/discount-codes/' || page === '/all-category/') && (
							<Grid item xs={6}>
								<Select
									sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
									size='small'
									id='slug'
									name='slug'
									fullWidth
									displayEmpty
									value={slug}
									input={<OutlinedInput />}
									onChange={e => handleSlugChange(e.target.value)}
									error={
										formikInstance.touched.link && Boolean(formikInstance.errors.link)
									}
								>
									{selectedCountry.length === 0 ? (
										<p style={{ textAlign: 'center' }}>Select a country first</p>
									) : page === '/discount-codes/' && allBrands ? (
										allBrands?.map((each: any) => (
											<MenuItem key={each?.id} value={each?.slug}>
												{each?.brandTranslation?.en?.[0]?.name}
											</MenuItem>
										))
									) : (
										allCategories &&
										allCategories?.map((category: any) => (
											<MenuItem key={category?.id} value={category?.slug}>
												{category?.translation?.en[0]?.name}
											</MenuItem>
										))
									)}
								</Select>
							</Grid>
						)}
					</>
				)}
			</Grid>
		</>
	);
};
export default NotificationLinkInput;
