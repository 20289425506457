import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

import styles from './index.module.scss';

const AuthLayout = () => (
	<Container maxWidth='xl'>
		<Box className={styles.LoginBox}>
			<Outlet />
		</Box>
	</Container>
);

export default AuthLayout;
