import { createSlice } from '@reduxjs/toolkit';

import { getAllAccounts, searchBankAccount } from '../../api/bankAccount';

const initialState: any = {
	allAccounts: [],
	error: '',
	filterByStatus: -1,
	bankLoading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 15,
		total: 0,
	},
};

export const bankAccountSlice = createSlice({
	name: 'bankAccountSlice',
	initialState,
	reducers: {
		setFilterByStatus: (state, action) => {
			state.filterByStatus = action.payload.status;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getAllAccounts.pending, state => {
				state.bankLoading = true;
			})
			.addCase(getAllAccounts.fulfilled, (state, action) => {
				state.bankLoading = false;
				state.allAccounts = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllAccounts.rejected, (state, action) => {
				state.bankLoading = false;
				state.error = action.error.message;
			})

			// search user
			.addCase(searchBankAccount.pending, state => {
				state.bankLoading = true;
			})
			.addCase(searchBankAccount.fulfilled, (state, action) => {
				state.bankLoading = false;
				state.allAccounts = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchBankAccount.rejected, (state, action) => {
				state.bankLoading = false;
				state.error = action.error.message;
			});
	},
});

export const { setFilterByStatus } = bankAccountSlice.actions;

export default bankAccountSlice.reducer;
