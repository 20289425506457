import { createSlice } from '@reduxjs/toolkit';
import { verifyEmail } from 'api/verifyEmail';

const initialState: any = {
	isVerified: [],
	loading: false,
};

export const verifySlice = createSlice({
	name: 'verify',
	initialState,
	reducers: {
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
	},
	extraReducers(builder) {
		builder

			// brands
			.addCase(verifyEmail.pending, state => {
				state.loading = true;
				state.isVerified = false;
			})
			.addCase(verifyEmail.fulfilled, (state, action) => {
				state.loading = false;
				state.isVerified = true;
			})
			.addCase(verifyEmail.rejected, (state, action) => {
				state.loading = false;
				state.isVerified = false;
				state.error = action.error.message;
			});
	},
});

export const { clearData } = verifySlice.actions;

export default verifySlice.reducer;
