import { createSlice } from '@reduxjs/toolkit';

import {
	AddConsumerPartner,
	getAllConsumerPartners,
	getConsumerPartnerById,
	getConsumerPartnerCouponsById,
	searchConsumerPartners,
	UpdateConsumerPartner,
	UpdateConsumerPartnerSecret,
} from '../../api/consumerPartner';

const initialState: any = {
	allConsumerPartners: [],
	error: '',
	singleConsumerPartner: {},
	partnerCoupons: [],
	partnerCouponsLoading: false,
	partnerCouponsPagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 1555,
		total: 0,
	},
	isAdded: false,
	isUpdated: false,
	loading: false,
	loadingBtn: false,
	pagination: {
		currentPage: 0,
		totalPages: 0,
		perPage: 155,
		total: 0,
	},
};
export const consumerPartnersSlice = createSlice({
	name: 'consumerPartners',
	initialState,
	reducers: {
		emptySingleConsumerPartner: state => {
			state.singleConsumerPartner = initialState.singleConsumerPartner;
		},
		toggleStatus: (state, action) => {
			state.allConsumerPartners = state.allConsumerPartners.map((d: any) =>
				d.id === action.payload ? { ...d, status: !d.status } : { ...d }
			);
		},
		clearData: state => {
			state.isUpdated = initialState.isUpdated;
			state.isAdded = initialState.isAdded;
		},
		setCurrentPage: (state, { payload }) => {
			if (state.pagination) state.pagination.currentPage = payload;
		},
		setPerPage: (state, { payload }) => {
			state.pagination.perPage = payload;
		},
		setPartnerCouponCurrentPage: (state, { payload }) => {
			if (state.partnerCouponsPagination)
				state.partnerCouponsPagination.currentPage = payload;
		},
		setPartnerCouponPerPage: (state, { payload }) => {
			state.partnerCouponsPagination.perPage = payload;
		},
	},
	extraReducers(builder) {
		builder
			// get all
			.addCase(getAllConsumerPartners.pending, (state, action) => {
				state.loading = !action.meta.arg.isRefresh;
			})
			.addCase(getAllConsumerPartners.fulfilled, (state, action) => {
				state.loading = false;
				state.allConsumerPartners = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(getAllConsumerPartners.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// search
			.addCase(searchConsumerPartners.pending, state => {
				state.loading = true;
			})
			.addCase(searchConsumerPartners.fulfilled, (state, action) => {
				state.loading = false;
				state.allConsumerPartners = action.payload.data;
				state.pagination = action.payload.meta.pagination;
			})
			.addCase(searchConsumerPartners.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			})

			// add
			.addCase(AddConsumerPartner.pending, state => {
				state.loadingBtn = true;
				state.isAdded = false;
			})
			.addCase(AddConsumerPartner.fulfilled, state => {
				state.loadingBtn = false;
				state.isAdded = true;
			})
			.addCase(AddConsumerPartner.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isAdded = false;
				state.error = action.error.message;
			})

			// update
			.addCase(UpdateConsumerPartner.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdateConsumerPartner.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdateConsumerPartner.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;
				state.error = action.error.message;
			})

			// Update secret
			.addCase(UpdateConsumerPartnerSecret.pending, state => {
				state.loadingBtn = true;
				state.isUpdated = false;
			})
			.addCase(UpdateConsumerPartnerSecret.fulfilled, state => {
				state.loadingBtn = false;
				state.isUpdated = true;
			})
			.addCase(UpdateConsumerPartnerSecret.rejected, (state, action) => {
				state.loadingBtn = false;
				state.isUpdated = false;
				state.error = action.error.message;
			})

			.addCase(getConsumerPartnerById.pending, state => {
				state.loading = true;
				state.loadingBtn = false;
			})
			.addCase(getConsumerPartnerById.fulfilled, (state, action) => {
				state.loading = false;
				state.loadingBtn = false;
				state.singleConsumerPartner = action?.payload?.data;
			})
			.addCase(getConsumerPartnerById.rejected, (state, action) => {
				state.loading = false;
				state.loadingBtn = false;
				state.error = action.error.message;
			})
			.addCase(getConsumerPartnerCouponsById.pending, state => {
				state.partnerCouponsLoading = true;
			})
			.addCase(getConsumerPartnerCouponsById.fulfilled, (state, action) => {
				state.partnerCouponsLoading = false;
				state.partnerCoupons = action.payload.data;
				state.partnerCouponsPagination = action.payload.meta.pagination;
			})
			.addCase(getConsumerPartnerCouponsById.rejected, (state, action) => {
				state.partnerCouponsLoading = false;
				state.error = action.error.message;
			});
	},
});

export const { emptySingleConsumerPartner, toggleStatus, clearData } =
	consumerPartnersSlice.actions;

export default consumerPartnersSlice.reducer;
