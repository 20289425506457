/* eslint-disable import/no-extraneous-dependencies */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import { combineReducers } from 'redux';
// import logger from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import advertisementSlice from './advertise/advertiseSlice';
import authSlice from './auth/authSlice';
import bankAccountSlice from './bankAccount/bankSlice';
import bankTransferSlice from './bankTransfer/bankTransfer';
import bannerSlice from './banner/bannerSlice';
import brandSlice from './brand/brandSlice';
import breadCrumbSlice from './breadCrumbs/breadCrumbSlice';
import cashbackSlice from './cashback/cashbackSlice';
import categorySlice from './category/categorySice';
import chatThreadsSlice from './chat/chatThreadsSlice';
import clientSlice from './client/clientSlice';
import consumerPartnersSlice from './consumerPartner/consumerPartnerSlice';
import contactSlice from './contact/contactSlice';
import countrySlice from './country/countrySlice';
import couponSlice from './coupon/couponSlice';
import dashboardSlice from './dashboard/dashboardSlice';
import newsSlice from './newsLetter/newsSlice';
import notificationSlice from './notification/notificationSlice';
import seoSlice from './pageSeo/seoSlice';
import partnerSlice from './partner/partnerSlice';
import roleSlice from './roles/rolesSlice';
import settingSlice from './settings/settingSlice';
import tagSlice from './tags/tagSlice';
import trendingSlice from './trending/trendingSlice';
import usersSlice from './users/usersSlice';
import verifySlice from './verifyEmail/verifySlice';

export const history: any = createBrowserHistory();

const persistConfig = {
	key: 'root',
	storage,
};

const reducers = combineReducers({
	auth: authSlice,
	brand: brandSlice,
	category: categorySlice,
	users: usersSlice,
	banner: bannerSlice,
	coupon: couponSlice,
	country: countrySlice,
	partner: partnerSlice,
	client: clientSlice,
	trending: trendingSlice,
	cashback: cashbackSlice,
	bankTransfer: bankTransferSlice,
	advertise: advertisementSlice,
	contact: contactSlice,
	role: roleSlice,
	settings: settingSlice,
	dashboard: dashboardSlice,
	bankAccount: bankAccountSlice,
	news: newsSlice,
	verify: verifySlice,
	pageSeo: seoSlice,
	tags: tagSlice,
	notification: notificationSlice,
	chatThreads: chatThreadsSlice,
	consumerPartners: consumerPartnersSlice,
	breadCrumbs: breadCrumbSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const middleware = [
	...getDefaultMiddleware({ thunk: true, serializableCheck: false }).concat(
		routerMiddleware(history)
	),
];

// if (process.env.NODE_ENV !== 'production') {
// 	middleware.push(logger);
// }

export const store = configureStore({
	reducer: persistedReducer,
	middleware,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
