import { createSlice } from '@reduxjs/toolkit';

import getDashboardData from '../../api/dashboard';

const initialState: any = {
	dashboardData: {},
	error: '',
	loading: false,
};

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		setFilterByStatus: (state, action) => {
			state.filterByStatus = action.payload.status;
		},
	},
	extraReducers(builder) {
		builder
			// get all brand
			.addCase(getDashboardData.pending, state => {
				state.loading = true;
			})
			.addCase(getDashboardData.fulfilled, (state, action) => {
				state.loading = false;
				state.dashboardData = action.payload?.data;
			})
			.addCase(getDashboardData.rejected, (state, action) => {
				state.loading = false;
				state.error = action.error.message;
			});
	},
});

export const { setFilterByStatus } = dashboardSlice.actions;

export default dashboardSlice.reducer;
