/* eslint-disable import/no-extraneous-dependencies */
import { AddRounded, EditRounded, SearchRounded } from '@mui/icons-material';
import {
	Chip,
	IconButton,
	InputAdornment,
	Stack,
	Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
	getAllConsumerPartners,
	searchConsumerPartners
} from 'api/consumerPartner';
import Button from 'components/common/Button';
import Header from 'components/common/Header';
import Input from 'components/common/Input';
import Table from 'components/common/Table';
import TableLayout from 'components/common/TableLayout';
import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { consumerPartnersSlice } from 'redux/consumerPartner/consumerPartnerSlice';
import { RootState } from 'redux/store';
import { disablefilter } from 'utils/constant';
import getArabicTime from 'utils/dateConverter';

const ConsumerPartner = () => {
	const dispatch = useDispatch();

	const { allConsumerPartners, pagination } = useSelector(
		(state: RootState) => state.consumerPartners
	);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Name',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={params.row.name || ''}>
					<span className='textWrap'>{params.row.name || ''}</span>
				</Tooltip>
			)
		},
		{
			field: 'entryDate',
			headerName: 'Entry Date',
			width: 200,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'date')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'date')}
					</span>
				</Tooltip>
			)
		},
		{
			field: 'entryTime',
			headerName: 'Entry Time',
			width: 150,
			renderCell: (params: GridValueGetterParams) => (
				<Tooltip title={getArabicTime(params?.row?.createdAt, 'time')}>
					<span className='textWrap'>
						{getArabicTime(params?.row?.createdAt, 'time')}
					</span>
				</Tooltip>
			)
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 120,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Chip
					label={params.row.status ? 'Active' : 'Inactive'}
					color={`${params.row.status ? 'success' : 'error'}`}
				/>
			)
		},
		{
			field: 'action',
			headerName: 'Action',
			width: 160,
			...disablefilter,
			renderCell: (params: GridValueGetterParams) => (
				<Stack direction='row' spacing={2}>
					<Link to={`/qyubic-affiliate-management/edit/${params.row.id}`}>
						<IconButton>
							<EditRounded />
						</IconButton>
					</Link>
				</Stack>
			)
		}
	];

	useEffect(() => {
		dispatch(
			getAllConsumerPartners({
				page: pagination?.currentPage,
				perPage: pagination?.perPage
			})
		);
	}, [pagination?.currentPage, pagination?.perPage]);

	const { loading } = useSelector((state: RootState) => state.consumerPartners);

	const debouncedFn = useCallback(
		debounce(q => {
			dispatch(searchConsumerPartners({ q }));
		}, 500),
		[]
	);
	return (
		<TableLayout>
			<Header title='Qyubic Affiliate management'>
				<Box>
					<Stack direction='row' spacing={2} alignItems='center'>
						<Input
							placeholder='Search by name'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchRounded />
									</InputAdornment>
								)
							}}
							sx={{ width: '18rem' }}
							onChange={(e: any) => {
								const q = e.target.value;
								debouncedFn(q);
							}}
						/>
						<Link to='/qyubic-affiliate-management/add'>
							<Button startIcon={<AddRounded />} variant='contained'>
								Add
							</Button>
						</Link>
						{/* <Button startIcon={<GetAppRounded />} variant='contained'>
							Export
						</Button> */}
					</Stack>
				</Box>
			</Header>

			<Table
				rows={allConsumerPartners}
				columns={columns}
				rowHeight={100}
				count={pagination?.totalPages}
				page={pagination?.currentPage}
				loading={loading}
				total={pagination?.total}
				perPage={pagination?.perPage}
				onPerPage={(value: number) => {
					dispatch(consumerPartnersSlice.actions.setCurrentPage(1));
					dispatch(consumerPartnersSlice.actions.setPerPage(value));
				}}
				onChange={(_e: ChangeEvent, value: number) =>
					dispatch(consumerPartnersSlice.actions.setCurrentPage(value))
				}
				sx={{ height: '70vh' }}
			/>
		</TableLayout>
	);
};
export default ConsumerPartner;
