import { Typography } from '@mui/material';

function index({
	label,
	required = true,
	textAlign = 'left',
}: {
	label: string;
	required?: boolean;
	textAlign?: string;
}) {
	return (
		<Typography
			sx={{
				marginTop: '.5rem',
				marginBottom: '.5rem',
				textAlign,
			}}
			variant='subtitle2'
		>
			{label}
			{required && <span style={{ color: 'red' }}>*</span>}
		</Typography>
	);
}

export default index;
